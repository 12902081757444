/*@import "../../node_modules/bootstrap/scss/bootstrap";*/
:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #c21b17;
  --secondary: #aa1b17;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
  --property: #D06E3F;
  --casualty: #779196;
  --overview: #aa1b17;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: "LatoWebLight", sans-serif;
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: "LatoWebLight", sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff; }

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #c21b17;
  text-decoration: none;
  background-color: transparent; }
  a:hover {
    color: #7e110f;
    text-decoration: underline; }

a:not([href]):not([class]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):not([class]):hover {
    color: inherit;
    text-decoration: none; }

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg {
  overflow: hidden;
  vertical-align: middle; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit;
  text-align: -webkit-match-parent; }

label {
  display: inline-block;
  margin-bottom: 0.5rem; }

button {
  border-radius: 0; }

button:focus:not(:focus-visible) {
  outline: 0; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

[role="button"] {
  cursor: pointer; }

select {
  word-wrap: normal; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2; }

h1, .h1 {
  font-size: 2.5rem; }

h2, .h2 {
  font-size: 2rem; }

h3, .h3 {
  font-size: 1.75rem; }

h4, .h4 {
  font-size: 1.5rem; }

h5, .h5 {
  font-size: 1.25rem; }

h6, .h6 {
  font-size: 1rem; }

.lead {
  font-size: 1.25rem;
  font-weight: 300; }

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2; }

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2; }

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1); }

small,
.small {
  font-size: 0.875em;
  font-weight: 400; }

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3; }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.list-inline {
  padding-left: 0;
  list-style: none; }

.list-inline-item {
  display: inline-block; }
  .list-inline-item:not(:last-child) {
    margin-right: 0.5rem; }

.initialism {
  font-size: 90%;
  text-transform: uppercase; }

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem; }

.blockquote-footer {
  display: block;
  font-size: 0.875em;
  color: #6c757d; }
  .blockquote-footer::before {
    content: "\2014\00A0"; }

.img-fluid {
  max-width: 100%;
  height: auto; }

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto; }

.figure {
  display: inline-block; }

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1; }

.figure-caption {
  font-size: 90%;
  color: #6c757d; }

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-wrap: break-word; }
  a > code {
    color: inherit; }

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem; }
  kbd kbd {
    padding: 0;
    font-size: 100%;
    font-weight: 700; }

pre {
  display: block;
  font-size: 87.5%;
  color: #212529; }
  pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal; }

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll; }

.container,
.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

@media (min-width: 576px) {
  .container, .container-sm {
    max-width: 540px; } }

@media (min-width: 768px) {
  .container, .container-sm, .container-md {
    max-width: 720px; } }

@media (min-width: 992px) {
  .container, .container-sm, .container-md, .container-lg {
    max-width: 960px; } }

@media (min-width: 1200px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1140px; } }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%; }

.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%; }

.row-cols-3 > * {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%; }

.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%; }

.row-cols-6 > * {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%; }

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  order: -1; }

.order-last {
  order: 13; }

.order-0 {
  order: 0; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.order-11 {
  order: 11; }

.order-12 {
  order: 12; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-sm-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-sm-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    order: -1; }
  .order-sm-last {
    order: 13; }
  .order-sm-0 {
    order: 0; }
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; }
  .order-sm-11 {
    order: 11; }
  .order-sm-12 {
    order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; } }

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-md-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-md-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    order: -1; }
  .order-md-last {
    order: 13; }
  .order-md-0 {
    order: 0; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; } }

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-lg-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-lg-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    order: -1; }
  .order-lg-last {
    order: 13; }
  .order-lg-0 {
    order: 0; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-xl-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-xl-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    order: -1; }
  .order-xl-last {
    order: 13; }
  .order-xl-0 {
    order: 0; }
  .order-xl-1 {
    order: 1; }
  .order-xl-2 {
    order: 2; }
  .order-xl-3 {
    order: 3; }
  .order-xl-4 {
    order: 4; }
  .order-xl-5 {
    order: 5; }
  .order-xl-6 {
    order: 6; }
  .order-xl-7 {
    order: 7; }
  .order-xl-8 {
    order: 8; }
  .order-xl-9 {
    order: 9; }
  .order-xl-10 {
    order: 10; }
  .order-xl-11 {
    order: 11; }
  .order-xl-12 {
    order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; } }

.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529; }
  .table th,
  .table td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6; }
  .table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #dee2e6; }
  .table tbody + tbody {
    border-top: 2px solid #dee2e6; }

.table-sm th,
.table-sm td {
  padding: 0.3rem; }

.table-bordered {
  border: 1px solid #dee2e6; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6; }
  .table-bordered thead th,
  .table-bordered thead td {
    border-bottom-width: 2px; }

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0; }

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05); }

.table-hover tbody tr:hover {
  color: #212529;
  background-color: rgba(0, 0, 0, 0.075); }

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #eebfbe; }

.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #df8886; }

.table-hover .table-primary:hover {
  background-color: #e9abaa; }
  .table-hover .table-primary:hover > td,
  .table-hover .table-primary:hover > th {
    background-color: #e9abaa; }

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #e7bfbe; }

.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #d38886; }

.table-hover .table-secondary:hover {
  background-color: #e0adab; }
  .table-hover .table-secondary:hover > td,
  .table-hover .table-secondary:hover > th {
    background-color: #e0adab; }

.table-success,
.table-success > th,
.table-success > td {
  background-color: #c3e6cb; }

.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #8fd19e; }

.table-hover .table-success:hover {
  background-color: #b1dfbb; }
  .table-hover .table-success:hover > td,
  .table-hover .table-success:hover > th {
    background-color: #b1dfbb; }

.table-info,
.table-info > th,
.table-info > td {
  background-color: #bee5eb; }

.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #86cfda; }

.table-hover .table-info:hover {
  background-color: #abdde5; }
  .table-hover .table-info:hover > td,
  .table-hover .table-info:hover > th {
    background-color: #abdde5; }

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #ffeeba; }

.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #ffdf7e; }

.table-hover .table-warning:hover {
  background-color: #ffe8a1; }
  .table-hover .table-warning:hover > td,
  .table-hover .table-warning:hover > th {
    background-color: #ffe8a1; }

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f5c6cb; }

.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #ed969e; }

.table-hover .table-danger:hover {
  background-color: #f1b0b7; }
  .table-hover .table-danger:hover > td,
  .table-hover .table-danger:hover > th {
    background-color: #f1b0b7; }

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fdfdfe; }

.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #fbfcfc; }

.table-hover .table-light:hover {
  background-color: #ececf6; }
  .table-hover .table-light:hover > td,
  .table-hover .table-light:hover > th {
    background-color: #ececf6; }

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c6c8ca; }

.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #95999c; }

.table-hover .table-dark:hover {
  background-color: #b9bbbe; }
  .table-hover .table-dark:hover > td,
  .table-hover .table-dark:hover > th {
    background-color: #b9bbbe; }

.table-property,
.table-property > th,
.table-property > td {
  background-color: #f2d6c9; }

.table-property th,
.table-property td,
.table-property thead th,
.table-property tbody + tbody {
  border-color: #e7b49b; }

.table-hover .table-property:hover {
  background-color: #edc6b4; }
  .table-hover .table-property:hover > td,
  .table-hover .table-property:hover > th {
    background-color: #edc6b4; }

.table-casualty,
.table-casualty > th,
.table-casualty > td {
  background-color: #d9e0e2; }

.table-casualty th,
.table-casualty td,
.table-casualty thead th,
.table-casualty tbody + tbody {
  border-color: #b8c6c8; }

.table-hover .table-casualty:hover {
  background-color: #cbd4d7; }
  .table-hover .table-casualty:hover > td,
  .table-hover .table-casualty:hover > th {
    background-color: #cbd4d7; }

.table-overview,
.table-overview > th,
.table-overview > td {
  background-color: #e7bfbe; }

.table-overview th,
.table-overview td,
.table-overview thead th,
.table-overview tbody + tbody {
  border-color: #d38886; }

.table-hover .table-overview:hover {
  background-color: #e0adab; }
  .table-hover .table-overview:hover > td,
  .table-hover .table-overview:hover > th {
    background-color: #e0adab; }

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075); }

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075); }
  .table-hover .table-active:hover > td,
  .table-hover .table-active:hover > th {
    background-color: rgba(0, 0, 0, 0.075); }

.table .thead-dark th {
  color: #fff;
  background-color: #343a40;
  border-color: #454d55; }

.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6; }

.table-dark {
  color: #fff;
  background-color: #343a40; }
  .table-dark th,
  .table-dark td,
  .table-dark thead th {
    border-color: #454d55; }
  .table-dark.table-bordered {
    border: 0; }
  .table-dark.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(255, 255, 255, 0.05); }
  .table-dark.table-hover tbody tr:hover {
    color: #fff;
    background-color: rgba(255, 255, 255, 0.075); }

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-sm > .table-bordered {
      border: 0; } }

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-md > .table-bordered {
      border: 0; } }

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-lg > .table-bordered {
      border: 0; } }

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-xl > .table-bordered {
      border: 0; } }

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch; }
  .table-responsive > .table-bordered {
    border: 0; }

.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .form-control {
      transition: none; } }
  .form-control::-ms-expand {
    background-color: transparent;
    border: 0; }
  .form-control:focus {
    color: #495057;
    background-color: #fff;
    border-color: #ed6e6b;
    outline: 0;
    box-shadow: none; }
  .form-control::placeholder {
    color: #6c757d;
    opacity: 1; }
  .form-control:disabled, .form-control[readonly] {
    background-color: #e9ecef;
    opacity: 1; }

input[type="date"].form-control,
input[type="time"].form-control,
input[type="datetime-local"].form-control,
input[type="month"].form-control {
  appearance: none; }

select.form-control:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #495057; }

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff; }

.form-control-file,
.form-control-range {
  display: block;
  width: 100%; }

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5; }

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5; }

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5; }

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  font-size: 1rem;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0; }
  .form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
    padding-right: 0;
    padding-left: 0; }

.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

select.form-control[size], select.form-control[multiple] {
  height: auto; }

textarea.form-control {
  height: auto; }

.form-group {
  margin-bottom: 1rem; }

.form-text {
  display: block;
  margin-top: 0.25rem; }

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px; }
  .form-row > .col,
  .form-row > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px; }

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem; }

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem; }
  .form-check-input[disabled] ~ .form-check-label,
  .form-check-input:disabled ~ .form-check-label {
    color: #6c757d; }

.form-check-label {
  margin-bottom: 0; }

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem; }
  .form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: 0.3125rem;
    margin-left: 0; }

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #28a745; }

.valid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(40, 167, 69, 0.9);
  border-radius: 0.25rem; }
  .form-row > .col > .valid-tooltip,
  .form-row > [class*="col-"] > .valid-tooltip {
    left: 5px; }

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #28a745;
  padding-right: calc(1.5em + 0.75rem) !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-control:valid:focus, .form-control.is-valid:focus {
    border-color: #28a745;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.was-validated select.form-control:valid, select.form-control.is-valid {
  padding-right: 3rem !important;
  background-position: right 1.5rem center; }

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #28a745;
  padding-right: calc(0.75em + 2.3125rem) !important;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat, #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) no-repeat; }
  .was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
    border-color: #28a745;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #28a745; }

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #28a745; }
  .was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
    border-color: #28a745; }

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #34ce57;
  background-color: #34ce57; }

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #28a745; }

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #28a745; }

.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #dc3545; }

.invalid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.9);
  border-radius: 0.25rem; }
  .form-row > .col > .invalid-tooltip,
  .form-row > [class*="col-"] > .invalid-tooltip {
    left: 5px; }

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #dc3545;
  padding-right: calc(1.5em + 0.75rem) !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.was-validated select.form-control:invalid, select.form-control.is-invalid {
  padding-right: 3rem !important;
  background-position: right 1.5rem center; }

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #dc3545;
  padding-right: calc(0.75em + 2.3125rem) !important;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat, #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e") center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) no-repeat; }
  .was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545; }

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #dc3545; }
  .was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
    border-color: #dc3545; }

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #e4606d;
  background-color: #e4606d; }

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #dc3545; }

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #dc3545; }

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center; }
  .form-inline .form-check {
    width: 100%; }
  @media (min-width: 576px) {
    .form-inline label {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0; }
    .form-inline .form-group {
      display: flex;
      flex: 0 0 auto;
      flex-flow: row wrap;
      align-items: center;
      margin-bottom: 0; }
    .form-inline .form-control {
      display: inline-block;
      width: auto;
      vertical-align: middle; }
    .form-inline .form-control-plaintext {
      display: inline-block; }
    .form-inline .input-group,
    .form-inline .custom-select {
      width: auto; }
    .form-inline .form-check {
      display: flex;
      align-items: center;
      justify-content: center;
      width: auto;
      padding-left: 0; }
    .form-inline .form-check-input {
      position: relative;
      flex-shrink: 0;
      margin-top: 0;
      margin-right: 0.25rem;
      margin-left: 0; }
    .form-inline .custom-control {
      align-items: center;
      justify-content: center; }
    .form-inline .custom-control-label {
      margin-bottom: 0; } }

.btn {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .btn {
      transition: none; } }
  .btn:hover {
    color: #212529;
    text-decoration: none; }
  .btn:focus, .btn.focus {
    outline: 0;
    box-shadow: none; }
  .btn.disabled, .btn:disabled {
    opacity: 0.65; }
  .btn:not(:disabled):not(.disabled) {
    cursor: pointer; }

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none; }

.btn-primary {
  color: #fff;
  background-color: #c21b17;
  border-color: #c21b17; }
  .btn-primary:hover {
    color: #fff;
    background-color: #a01613;
    border-color: #941512; }
  .btn-primary:focus, .btn-primary.focus {
    color: #fff;
    background-color: #a01613;
    border-color: #941512;
    box-shadow: 0 0 0 0.2rem rgba(203, 61, 58, 0.5); }
  .btn-primary.disabled, .btn-primary:disabled {
    color: #fff;
    background-color: #c21b17;
    border-color: #c21b17; }
  .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
  .show > .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #941512;
    border-color: #891310; }
    .btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(203, 61, 58, 0.5); }

.btn-secondary {
  color: #fff;
  background-color: #aa1b17;
  border-color: #aa1b17; }
  .btn-secondary:hover {
    color: #fff;
    background-color: #881612;
    border-color: #7d1411; }
  .btn-secondary:focus, .btn-secondary.focus {
    color: #fff;
    background-color: #881612;
    border-color: #7d1411;
    box-shadow: 0 0 0 0.2rem rgba(183, 61, 58, 0.5); }
  .btn-secondary.disabled, .btn-secondary:disabled {
    color: #fff;
    background-color: #aa1b17;
    border-color: #aa1b17; }
  .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: #7d1411;
    border-color: #72120f; }
    .btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(183, 61, 58, 0.5); }

.btn-success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745; }
  .btn-success:hover {
    color: #fff;
    background-color: #218838;
    border-color: #1e7e34; }
  .btn-success:focus, .btn-success.focus {
    color: #fff;
    background-color: #218838;
    border-color: #1e7e34;
    box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5); }
  .btn-success.disabled, .btn-success:disabled {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
  .btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
  .show > .btn-success.dropdown-toggle {
    color: #fff;
    background-color: #1e7e34;
    border-color: #1c7430; }
    .btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5); }

.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8; }
  .btn-info:hover {
    color: #fff;
    background-color: #138496;
    border-color: #117a8b; }
  .btn-info:focus, .btn-info.focus {
    color: #fff;
    background-color: #138496;
    border-color: #117a8b;
    box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5); }
  .btn-info.disabled, .btn-info:disabled {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
  .show > .btn-info.dropdown-toggle {
    color: #fff;
    background-color: #117a8b;
    border-color: #10707f; }
    .btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5); }

.btn-warning {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107; }
  .btn-warning:hover {
    color: #212529;
    background-color: #e0a800;
    border-color: #d39e00; }
  .btn-warning:focus, .btn-warning.focus {
    color: #212529;
    background-color: #e0a800;
    border-color: #d39e00;
    box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5); }
  .btn-warning.disabled, .btn-warning:disabled {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
  .btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active,
  .show > .btn-warning.dropdown-toggle {
    color: #212529;
    background-color: #d39e00;
    border-color: #c69500; }
    .btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5); }

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545; }
  .btn-danger:hover {
    color: #fff;
    background-color: #c82333;
    border-color: #bd2130; }
  .btn-danger:focus, .btn-danger.focus {
    color: #fff;
    background-color: #c82333;
    border-color: #bd2130;
    box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5); }
  .btn-danger.disabled, .btn-danger:disabled {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
  .btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
  .show > .btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #bd2130;
    border-color: #b21f2d; }
    .btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5); }

.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa; }
  .btn-light:hover {
    color: #212529;
    background-color: #e2e6ea;
    border-color: #dae0e5; }
  .btn-light:focus, .btn-light.focus {
    color: #212529;
    background-color: #e2e6ea;
    border-color: #dae0e5;
    box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5); }
  .btn-light.disabled, .btn-light:disabled {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active,
  .show > .btn-light.dropdown-toggle {
    color: #212529;
    background-color: #dae0e5;
    border-color: #d3d9df; }
    .btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5); }

.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40; }
  .btn-dark:hover {
    color: #fff;
    background-color: #23272b;
    border-color: #1d2124; }
  .btn-dark:focus, .btn-dark.focus {
    color: #fff;
    background-color: #23272b;
    border-color: #1d2124;
    box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5); }
  .btn-dark.disabled, .btn-dark:disabled {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active,
  .show > .btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #1d2124;
    border-color: #171a1d; }
    .btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5); }

.btn-property {
  color: #fff;
  background-color: #D06E3F;
  border-color: #D06E3F; }
  .btn-property:hover {
    color: #fff;
    background-color: #bb5c2e;
    border-color: #b1572b; }
  .btn-property:focus, .btn-property.focus {
    color: #fff;
    background-color: #bb5c2e;
    border-color: #b1572b;
    box-shadow: 0 0 0 0.2rem rgba(215, 132, 92, 0.5); }
  .btn-property.disabled, .btn-property:disabled {
    color: #fff;
    background-color: #D06E3F;
    border-color: #D06E3F; }
  .btn-property:not(:disabled):not(.disabled):active, .btn-property:not(:disabled):not(.disabled).active,
  .show > .btn-property.dropdown-toggle {
    color: #fff;
    background-color: #b1572b;
    border-color: #a65229; }
    .btn-property:not(:disabled):not(.disabled):active:focus, .btn-property:not(:disabled):not(.disabled).active:focus,
    .show > .btn-property.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(215, 132, 92, 0.5); }

.btn-casualty {
  color: #fff;
  background-color: #779196;
  border-color: #779196; }
  .btn-casualty:hover {
    color: #fff;
    background-color: #657d82;
    border-color: #5f767b; }
  .btn-casualty:focus, .btn-casualty.focus {
    color: #fff;
    background-color: #657d82;
    border-color: #5f767b;
    box-shadow: 0 0 0 0.2rem rgba(139, 162, 166, 0.5); }
  .btn-casualty.disabled, .btn-casualty:disabled {
    color: #fff;
    background-color: #779196;
    border-color: #779196; }
  .btn-casualty:not(:disabled):not(.disabled):active, .btn-casualty:not(:disabled):not(.disabled).active,
  .show > .btn-casualty.dropdown-toggle {
    color: #fff;
    background-color: #5f767b;
    border-color: #597074; }
    .btn-casualty:not(:disabled):not(.disabled):active:focus, .btn-casualty:not(:disabled):not(.disabled).active:focus,
    .show > .btn-casualty.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(139, 162, 166, 0.5); }

.btn-overview {
  color: #fff;
  background-color: #aa1b17;
  border-color: #aa1b17; }
  .btn-overview:hover {
    color: #fff;
    background-color: #881612;
    border-color: #7d1411; }
  .btn-overview:focus, .btn-overview.focus {
    color: #fff;
    background-color: #881612;
    border-color: #7d1411;
    box-shadow: 0 0 0 0.2rem rgba(183, 61, 58, 0.5); }
  .btn-overview.disabled, .btn-overview:disabled {
    color: #fff;
    background-color: #aa1b17;
    border-color: #aa1b17; }
  .btn-overview:not(:disabled):not(.disabled):active, .btn-overview:not(:disabled):not(.disabled).active,
  .show > .btn-overview.dropdown-toggle {
    color: #fff;
    background-color: #7d1411;
    border-color: #72120f; }
    .btn-overview:not(:disabled):not(.disabled):active:focus, .btn-overview:not(:disabled):not(.disabled).active:focus,
    .show > .btn-overview.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(183, 61, 58, 0.5); }

.btn-outline-primary {
  color: #c21b17;
  border-color: #c21b17; }
  .btn-outline-primary:hover {
    color: #fff;
    background-color: #c21b17;
    border-color: #c21b17; }
  .btn-outline-primary:focus, .btn-outline-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(194, 27, 23, 0.5); }
  .btn-outline-primary.disabled, .btn-outline-primary:disabled {
    color: #c21b17;
    background-color: transparent; }
  .btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: #c21b17;
    border-color: #c21b17; }
    .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(194, 27, 23, 0.5); }

.btn-outline-secondary {
  color: #aa1b17;
  border-color: #aa1b17; }
  .btn-outline-secondary:hover {
    color: #fff;
    background-color: #aa1b17;
    border-color: #aa1b17; }
  .btn-outline-secondary:focus, .btn-outline-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(170, 27, 23, 0.5); }
  .btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
    color: #aa1b17;
    background-color: transparent; }
  .btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-secondary.dropdown-toggle {
    color: #fff;
    background-color: #aa1b17;
    border-color: #aa1b17; }
    .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(170, 27, 23, 0.5); }

.btn-outline-success {
  color: #28a745;
  border-color: #28a745; }
  .btn-outline-success:hover {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
  .btn-outline-success:focus, .btn-outline-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }
  .btn-outline-success.disabled, .btn-outline-success:disabled {
    color: #28a745;
    background-color: transparent; }
  .btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active,
  .show > .btn-outline-success.dropdown-toggle {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
    .btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }

.btn-outline-info {
  color: #17a2b8;
  border-color: #17a2b8; }
  .btn-outline-info:hover {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .btn-outline-info:focus, .btn-outline-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }
  .btn-outline-info.disabled, .btn-outline-info:disabled {
    color: #17a2b8;
    background-color: transparent; }
  .btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active,
  .show > .btn-outline-info.dropdown-toggle {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
    .btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }

.btn-outline-warning {
  color: #ffc107;
  border-color: #ffc107; }
  .btn-outline-warning:hover {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
  .btn-outline-warning:focus, .btn-outline-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }
  .btn-outline-warning.disabled, .btn-outline-warning:disabled {
    color: #ffc107;
    background-color: transparent; }
  .btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active,
  .show > .btn-outline-warning.dropdown-toggle {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
    .btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }

.btn-outline-danger {
  color: #dc3545;
  border-color: #dc3545; }
  .btn-outline-danger:hover {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
  .btn-outline-danger:focus, .btn-outline-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }
  .btn-outline-danger.disabled, .btn-outline-danger:disabled {
    color: #dc3545;
    background-color: transparent; }
  .btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active,
  .show > .btn-outline-danger.dropdown-toggle {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
    .btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }

.btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa; }
  .btn-outline-light:hover {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .btn-outline-light:focus, .btn-outline-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }
  .btn-outline-light.disabled, .btn-outline-light:disabled {
    color: #f8f9fa;
    background-color: transparent; }
  .btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active,
  .show > .btn-outline-light.dropdown-toggle {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
    .btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }

.btn-outline-dark {
  color: #343a40;
  border-color: #343a40; }
  .btn-outline-dark:hover {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .btn-outline-dark:focus, .btn-outline-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }
  .btn-outline-dark.disabled, .btn-outline-dark:disabled {
    color: #343a40;
    background-color: transparent; }
  .btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active,
  .show > .btn-outline-dark.dropdown-toggle {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
    .btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.btn-outline-property {
  color: #D06E3F;
  border-color: #D06E3F; }
  .btn-outline-property:hover {
    color: #fff;
    background-color: #D06E3F;
    border-color: #D06E3F; }
  .btn-outline-property:focus, .btn-outline-property.focus {
    box-shadow: 0 0 0 0.2rem rgba(208, 110, 63, 0.5); }
  .btn-outline-property.disabled, .btn-outline-property:disabled {
    color: #D06E3F;
    background-color: transparent; }
  .btn-outline-property:not(:disabled):not(.disabled):active, .btn-outline-property:not(:disabled):not(.disabled).active,
  .show > .btn-outline-property.dropdown-toggle {
    color: #fff;
    background-color: #D06E3F;
    border-color: #D06E3F; }
    .btn-outline-property:not(:disabled):not(.disabled):active:focus, .btn-outline-property:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-property.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(208, 110, 63, 0.5); }

.btn-outline-casualty {
  color: #779196;
  border-color: #779196; }
  .btn-outline-casualty:hover {
    color: #fff;
    background-color: #779196;
    border-color: #779196; }
  .btn-outline-casualty:focus, .btn-outline-casualty.focus {
    box-shadow: 0 0 0 0.2rem rgba(119, 145, 150, 0.5); }
  .btn-outline-casualty.disabled, .btn-outline-casualty:disabled {
    color: #779196;
    background-color: transparent; }
  .btn-outline-casualty:not(:disabled):not(.disabled):active, .btn-outline-casualty:not(:disabled):not(.disabled).active,
  .show > .btn-outline-casualty.dropdown-toggle {
    color: #fff;
    background-color: #779196;
    border-color: #779196; }
    .btn-outline-casualty:not(:disabled):not(.disabled):active:focus, .btn-outline-casualty:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-casualty.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(119, 145, 150, 0.5); }

.btn-outline-overview {
  color: #aa1b17;
  border-color: #aa1b17; }
  .btn-outline-overview:hover {
    color: #fff;
    background-color: #aa1b17;
    border-color: #aa1b17; }
  .btn-outline-overview:focus, .btn-outline-overview.focus {
    box-shadow: 0 0 0 0.2rem rgba(170, 27, 23, 0.5); }
  .btn-outline-overview.disabled, .btn-outline-overview:disabled {
    color: #aa1b17;
    background-color: transparent; }
  .btn-outline-overview:not(:disabled):not(.disabled):active, .btn-outline-overview:not(:disabled):not(.disabled).active,
  .show > .btn-outline-overview.dropdown-toggle {
    color: #fff;
    background-color: #aa1b17;
    border-color: #aa1b17; }
    .btn-outline-overview:not(:disabled):not(.disabled):active:focus, .btn-outline-overview:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-overview.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(170, 27, 23, 0.5); }

.btn-link {
  font-weight: 400;
  color: #c21b17;
  text-decoration: none; }
  .btn-link:hover {
    color: #7e110f;
    text-decoration: underline; }
  .btn-link:focus, .btn-link.focus {
    text-decoration: underline; }
  .btn-link:disabled, .btn-link.disabled {
    color: #6c757d;
    pointer-events: none; }

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.btn-block {
  display: block;
  width: 100%; }
  .btn-block + .btn-block {
    margin-top: 0.5rem; }

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%; }

.fade {
  transition: opacity 0.15s linear; }
  @media (prefers-reduced-motion: reduce) {
    .fade {
      transition: none; } }
  .fade:not(.show) {
    opacity: 0; }

.collapse:not(.show) {
  display: none; }

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease; }
  @media (prefers-reduced-motion: reduce) {
    .collapsing {
      transition: none; } }
  .collapsing.width {
    width: 0;
    height: auto;
    transition: width 0.35s ease; }
    @media (prefers-reduced-motion: reduce) {
      .collapsing.width {
        transition: none; } }

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative; }

.dropdown-toggle {
  white-space: nowrap; }

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem; }

.dropdown-menu-left {
  right: auto;
  left: 0; }

.dropdown-menu-right {
  right: 0;
  left: auto; }

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0; }
  .dropdown-menu-sm-right {
    right: 0;
    left: auto; } }

@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0; }
  .dropdown-menu-md-right {
    right: 0;
    left: auto; } }

@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0; }
  .dropdown-menu-lg-right {
    right: 0;
    left: auto; } }

@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0; }
  .dropdown-menu-xl-right {
    right: 0;
    left: auto; } }

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem; }

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem; }

.dropright .dropdown-toggle::after {
  vertical-align: 0; }

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem; }

.dropleft .dropdown-toggle::before {
  vertical-align: 0; }

.dropdown-menu[x-placement^="top"], .dropdown-menu[x-placement^="right"], .dropdown-menu[x-placement^="bottom"], .dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto; }

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef; }

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0; }
  .dropdown-item:hover, .dropdown-item:focus {
    color: #16181b;
    text-decoration: none;
    background-color: #e9ecef; }
  .dropdown-item.active, .dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: #c21b17; }
  .dropdown-item.disabled, .dropdown-item:disabled {
    color: #adb5bd;
    pointer-events: none;
    background-color: transparent; }

.dropdown-menu.show {
  display: block; }

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap; }

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212529; }

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle; }
  .btn-group > .btn,
  .btn-group-vertical > .btn {
    position: relative;
    flex: 1 1 auto; }
    .btn-group > .btn:hover,
    .btn-group-vertical > .btn:hover {
      z-index: 1; }
    .btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
    .btn-group-vertical > .btn:focus,
    .btn-group-vertical > .btn:active,
    .btn-group-vertical > .btn.active {
      z-index: 1; }

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; }
  .btn-toolbar .input-group {
    width: auto; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px; }

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem; }
  .dropdown-toggle-split::after,
  .dropup .dropdown-toggle-split::after,
  .dropright .dropdown-toggle-split::after {
    margin-left: 0; }
  .dropleft .dropdown-toggle-split::before {
    margin-right: 0; }

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem; }

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem; }

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center; }
  .btn-group-vertical > .btn,
  .btn-group-vertical > .btn-group {
    width: 100%; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) {
    margin-top: -1px; }
  .btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
  .btn-group-vertical > .btn-group:not(:last-child) > .btn {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) > .btn {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0; }
  .btn-group-toggle > .btn input[type="radio"],
  .btn-group-toggle > .btn input[type="checkbox"],
  .btn-group-toggle > .btn-group > .btn input[type="radio"],
  .btn-group-toggle > .btn-group > .btn input[type="checkbox"] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none; }

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%; }
  .input-group > .form-control,
  .input-group > .form-control-plaintext,
  .input-group > .custom-select,
  .input-group > .custom-file {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    min-width: 0;
    margin-bottom: 0; }
    .input-group > .form-control + .form-control,
    .input-group > .form-control + .custom-select,
    .input-group > .form-control + .custom-file,
    .input-group > .form-control-plaintext + .form-control,
    .input-group > .form-control-plaintext + .custom-select,
    .input-group > .form-control-plaintext + .custom-file,
    .input-group > .custom-select + .form-control,
    .input-group > .custom-select + .custom-select,
    .input-group > .custom-select + .custom-file,
    .input-group > .custom-file + .form-control,
    .input-group > .custom-file + .custom-select,
    .input-group > .custom-file + .custom-file {
      margin-left: -1px; }
  .input-group > .form-control:focus,
  .input-group > .custom-select:focus,
  .input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
    z-index: 3; }
  .input-group > .custom-file .custom-file-input:focus {
    z-index: 4; }
  .input-group > .form-control:not(:first-child),
  .input-group > .custom-select:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
  .input-group > .custom-file {
    display: flex;
    align-items: center; }
    .input-group > .custom-file:not(:last-child) .custom-file-label,
    .input-group > .custom-file:not(:last-child) .custom-file-label::after {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0; }
    .input-group > .custom-file:not(:first-child) .custom-file-label {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0; }
  .input-group:not(.has-validation) > .form-control:not(:last-child),
  .input-group:not(.has-validation) > .custom-select:not(:last-child),
  .input-group:not(.has-validation) > .custom-file:not(:last-child) .custom-file-label,
  .input-group:not(.has-validation) > .custom-file:not(:last-child) .custom-file-label::after {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .input-group.has-validation > .form-control:nth-last-child(n + 3),
  .input-group.has-validation > .custom-select:nth-last-child(n + 3),
  .input-group.has-validation > .custom-file:nth-last-child(n + 3) .custom-file-label,
  .input-group.has-validation > .custom-file:nth-last-child(n + 3) .custom-file-label::after {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }

.input-group-prepend,
.input-group-append {
  display: flex; }
  .input-group-prepend .btn,
  .input-group-append .btn {
    position: relative;
    z-index: 2; }
    .input-group-prepend .btn:focus,
    .input-group-append .btn:focus {
      z-index: 3; }
  .input-group-prepend .btn + .btn,
  .input-group-prepend .btn + .input-group-text,
  .input-group-prepend .input-group-text + .input-group-text,
  .input-group-prepend .input-group-text + .btn,
  .input-group-append .btn + .btn,
  .input-group-append .btn + .input-group-text,
  .input-group-append .input-group-text + .input-group-text,
  .input-group-append .input-group-text + .btn {
    margin-left: -1px; }

.input-group-prepend {
  margin-right: -1px; }

.input-group-append {
  margin-left: -1px; }

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.25rem; }
  .input-group-text input[type="radio"],
  .input-group-text input[type="checkbox"] {
    margin-top: 0; }

.input-group-lg > .form-control:not(textarea),
.input-group-lg > .custom-select {
  height: calc(1.5em + 1rem + 2px); }

.input-group-lg > .form-control,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

.input-group-sm > .form-control:not(textarea),
.input-group-sm > .custom-select {
  height: calc(1.5em + 0.5rem + 2px); }

.input-group-sm > .form-control,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 1.75rem; }

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group:not(.has-validation) > .input-group-append:not(:last-child) > .btn,
.input-group:not(.has-validation) > .input-group-append:not(:last-child) > .input-group-text,
.input-group.has-validation > .input-group-append:nth-last-child(n + 3) > .btn,
.input-group.has-validation > .input-group-append:nth-last-child(n + 3) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.custom-control {
  position: relative;
  z-index: 1;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem;
  print-color-adjust: exact; }

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem; }

.custom-control-input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 1rem;
  height: 1.25rem;
  opacity: 0; }
  .custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    border-color: #c21b17;
    background-color: #c21b17; }
  .custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: none; }
  .custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
    border-color: #ed6e6b; }
  .custom-control-input:not(:disabled):active ~ .custom-control-label::before {
    color: #fff;
    background-color: #f39b99;
    border-color: #f39b99; }
  .custom-control-input[disabled] ~ .custom-control-label, .custom-control-input:disabled ~ .custom-control-label {
    color: #6c757d; }
    .custom-control-input[disabled] ~ .custom-control-label::before, .custom-control-input:disabled ~ .custom-control-label::before {
      background-color: #e9ecef; }

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top; }
  .custom-control-label::before {
    position: absolute;
    top: 0.25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    pointer-events: none;
    content: "";
    background-color: #fff;
    border: 1px solid #adb5bd; }
  .custom-control-label::after {
    position: absolute;
    top: 0.25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    content: "";
    background: 50% / 50% 50% no-repeat; }

.custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #c21b17;
  background-color: #c21b17; }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(194, 27, 23, 0.5); }

.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(194, 27, 23, 0.5); }

.custom-radio .custom-control-label::before {
  border-radius: 50%; }

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e"); }

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(194, 27, 23, 0.5); }

.custom-switch {
  padding-left: 2.25rem; }
  .custom-switch .custom-control-label::before {
    left: -2.25rem;
    width: 1.75rem;
    pointer-events: all;
    border-radius: 0.5rem; }
  .custom-switch .custom-control-label::after {
    top: calc(0.25rem + 2px);
    left: calc(-2.25rem + 2px);
    width: calc(1rem - 4px);
    height: calc(1rem - 4px);
    background-color: #adb5bd;
    border-radius: 0.5rem;
    transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .custom-switch .custom-control-label::after {
        transition: none; } }
  .custom-switch .custom-control-input:checked ~ .custom-control-label::after {
    background-color: #fff;
    transform: translateX(0.75rem); }
  .custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
    background-color: rgba(194, 27, 23, 0.5); }

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  appearance: none; }
  .custom-select:focus {
    border-color: #ed6e6b;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(194, 27, 23, 0.25); }
    .custom-select:focus::-ms-value {
      color: #495057;
      background-color: #fff; }
  .custom-select[multiple], .custom-select[size]:not([size="1"]) {
    height: auto;
    padding-right: 0.75rem;
    background-image: none; }
  .custom-select:disabled {
    color: #6c757d;
    background-color: #e9ecef; }
  .custom-select::-ms-expand {
    display: none; }
  .custom-select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #495057; }

.custom-select-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem; }

.custom-select-lg {
  height: calc(1.5em + 1rem + 2px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem; }

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin-bottom: 0; }

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin: 0;
  overflow: hidden;
  opacity: 0; }
  .custom-file-input:focus ~ .custom-file-label {
    border-color: #ed6e6b;
    box-shadow: none; }
  .custom-file-input[disabled] ~ .custom-file-label,
  .custom-file-input:disabled ~ .custom-file-label {
    background-color: #e9ecef; }
  .custom-file-input:lang(en) ~ .custom-file-label::after {
    content: "Browse"; }
  .custom-file-input ~ .custom-file-label[data-browse]::after {
    content: attr(data-browse); }

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  overflow: hidden;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem; }
  .custom-file-label::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    display: block;
    height: calc(1.5em + 0.75rem);
    padding: 0.375rem 0.75rem;
    line-height: 1.5;
    color: #495057;
    content: "Browse";
    background-color: #e9ecef;
    border-left: inherit;
    border-radius: 0 0.25rem 0.25rem 0; }

.custom-range {
  width: 100%;
  height: 1.4rem;
  padding: 0;
  background-color: transparent;
  appearance: none; }
  .custom-range:focus {
    outline: 0; }
    .custom-range:focus::-webkit-slider-thumb {
      box-shadow: 0 0 0 1px #fff, none; }
    .custom-range:focus::-moz-range-thumb {
      box-shadow: 0 0 0 1px #fff, none; }
    .custom-range:focus::-ms-thumb {
      box-shadow: 0 0 0 1px #fff, none; }
  .custom-range::-moz-focus-outer {
    border: 0; }
  .custom-range::-webkit-slider-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: -0.25rem;
    background-color: #c21b17;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-webkit-slider-thumb {
        transition: none; } }
    .custom-range::-webkit-slider-thumb:active {
      background-color: #f39b99; }
  .custom-range::-webkit-slider-runnable-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .custom-range::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    background-color: #c21b17;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-moz-range-thumb {
        transition: none; } }
    .custom-range::-moz-range-thumb:active {
      background-color: #f39b99; }
  .custom-range::-moz-range-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .custom-range::-ms-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: 0;
    margin-right: 0.2rem;
    margin-left: 0.2rem;
    background-color: #c21b17;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-ms-thumb {
        transition: none; } }
    .custom-range::-ms-thumb:active {
      background-color: #f39b99; }
  .custom-range::-ms-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: transparent;
    border-color: transparent;
    border-width: 0.5rem; }
  .custom-range::-ms-fill-lower {
    background-color: #dee2e6;
    border-radius: 1rem; }
  .custom-range::-ms-fill-upper {
    margin-right: 15px;
    background-color: #dee2e6;
    border-radius: 1rem; }
  .custom-range:disabled::-webkit-slider-thumb {
    background-color: #adb5bd; }
  .custom-range:disabled::-webkit-slider-runnable-track {
    cursor: default; }
  .custom-range:disabled::-moz-range-thumb {
    background-color: #adb5bd; }
  .custom-range:disabled::-moz-range-track {
    cursor: default; }
  .custom-range:disabled::-ms-thumb {
    background-color: #adb5bd; }

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .custom-control-label::before,
    .custom-file-label,
    .custom-select {
      transition: none; } }

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

.nav-link {
  display: block;
  padding: 0.5rem 1rem; }
  .nav-link:hover, .nav-link:focus {
    text-decoration: none; }
  .nav-link.disabled {
    color: #6c757d;
    pointer-events: none;
    cursor: default; }

.nav-tabs {
  border-bottom: 1px solid #dee2e6; }
  .nav-tabs .nav-link {
    margin-bottom: -1px;
    background-color: transparent;
    border: 1px solid transparent;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
    .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
      isolation: isolate;
      border-color: #e9ecef #e9ecef #dee2e6; }
    .nav-tabs .nav-link.disabled {
      color: #6c757d;
      background-color: transparent;
      border-color: transparent; }
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: #495057;
    background-color: #fff;
    border-color: #dee2e6 #dee2e6 #fff; }
  .nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.nav-pills .nav-link {
  background: none;
  border: 0;
  border-radius: 0.25rem; }

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #c21b17; }

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center; }

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center; }

.tab-content > .tab-pane {
  display: none; }

.tab-content > .active {
  display: block; }

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem; }
  .navbar .container,
  .navbar .container-fluid, .navbar .container-sm, .navbar .container-md, .navbar .container-lg, .navbar .container-xl {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between; }

.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap; }
  .navbar-brand:hover, .navbar-brand:focus {
    text-decoration: none; }

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }
  .navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0; }
  .navbar-nav .dropdown-menu {
    position: static;
    float: none; }

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; }

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center; }

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem; }
  .navbar-toggler:hover, .navbar-toggler:focus {
    text-decoration: none; }

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: 50% / 100% 100% no-repeat; }

.navbar-nav-scroll {
  max-height: 75vh;
  overflow-y: auto; }

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid, .navbar-expand-sm > .container-sm, .navbar-expand-sm > .container-md, .navbar-expand-sm > .container-lg, .navbar-expand-sm > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-sm .navbar-nav {
      flex-direction: row; }
      .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-sm > .container,
    .navbar-expand-sm > .container-fluid, .navbar-expand-sm > .container-sm, .navbar-expand-sm > .container-md, .navbar-expand-sm > .container-lg, .navbar-expand-sm > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-sm .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-sm .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-sm .navbar-toggler {
      display: none; } }

@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid, .navbar-expand-md > .container-sm, .navbar-expand-md > .container-md, .navbar-expand-md > .container-lg, .navbar-expand-md > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-md .navbar-nav {
      flex-direction: row; }
      .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-md .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-md > .container,
    .navbar-expand-md > .container-fluid, .navbar-expand-md > .container-sm, .navbar-expand-md > .container-md, .navbar-expand-md > .container-lg, .navbar-expand-md > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-md .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-md .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-md .navbar-toggler {
      display: none; } }

@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid, .navbar-expand-lg > .container-sm, .navbar-expand-lg > .container-md, .navbar-expand-lg > .container-lg, .navbar-expand-lg > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-lg .navbar-nav {
      flex-direction: row; }
      .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-lg > .container,
    .navbar-expand-lg > .container-fluid, .navbar-expand-lg > .container-sm, .navbar-expand-lg > .container-md, .navbar-expand-lg > .container-lg, .navbar-expand-lg > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-lg .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-lg .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-lg .navbar-toggler {
      display: none; } }

@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid, .navbar-expand-xl > .container-sm, .navbar-expand-xl > .container-md, .navbar-expand-xl > .container-lg, .navbar-expand-xl > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-xl .navbar-nav {
      flex-direction: row; }
      .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-xl > .container,
    .navbar-expand-xl > .container-fluid, .navbar-expand-xl > .container-sm, .navbar-expand-xl > .container-md, .navbar-expand-xl > .container-lg, .navbar-expand-xl > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-xl .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-xl .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-xl .navbar-toggler {
      display: none; } }

.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid, .navbar-expand > .container-sm, .navbar-expand > .container-md, .navbar-expand > .container-lg, .navbar-expand > .container-xl {
    padding-right: 0;
    padding-left: 0; }
  .navbar-expand .navbar-nav {
    flex-direction: row; }
    .navbar-expand .navbar-nav .dropdown-menu {
      position: absolute; }
    .navbar-expand .navbar-nav .nav-link {
      padding-right: 0.5rem;
      padding-left: 0.5rem; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid, .navbar-expand > .container-sm, .navbar-expand > .container-md, .navbar-expand > .container-lg, .navbar-expand > .container-xl {
    flex-wrap: nowrap; }
  .navbar-expand .navbar-nav-scroll {
    overflow: visible; }
  .navbar-expand .navbar-collapse {
    display: flex !important;
    flex-basis: auto; }
  .navbar-expand .navbar-toggler {
    display: none; }

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9); }
  .navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
    color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
    color: rgba(0, 0, 0, 0.7); }
  .navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(0, 0, 0, 0.3); }

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1); }

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-text a {
    color: rgba(0, 0, 0, 0.9); }
    .navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
      color: rgba(0, 0, 0, 0.9); }

.navbar-dark .navbar-brand {
  color: #fff; }
  .navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
    color: #fff; }

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
    color: rgba(255, 255, 255, 0.75); }
  .navbar-dark .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, 0.25); }

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff; }

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1); }

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-text a {
    color: #fff; }
    .navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
      color: #fff; }

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem; }
  .card > hr {
    margin-right: 0;
    margin-left: 0; }
  .card > .list-group {
    border-top: inherit;
    border-bottom: inherit; }
    .card > .list-group:first-child {
      border-top-width: 0;
      border-top-left-radius: calc(0.25rem - 1px);
      border-top-right-radius: calc(0.25rem - 1px); }
    .card > .list-group:last-child {
      border-bottom-width: 0;
      border-bottom-right-radius: calc(0.25rem - 1px);
      border-bottom-left-radius: calc(0.25rem - 1px); }
  .card > .card-header + .list-group,
  .card > .list-group + .card-footer {
    border-top: 0; }

.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem; }

.card-title {
  margin-bottom: 0.75rem; }

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0; }

.card-text:last-child {
  margin-bottom: 0; }

.card-link:hover {
  text-decoration: none; }

.card-link + .card-link {
  margin-left: 1.25rem; }

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125); }
  .card-header:first-child {
    border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0; }

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125); }
  .card-footer:last-child {
    border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px); }

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0; }

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem; }

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
  border-radius: calc(0.25rem - 1px); }

.card-img,
.card-img-top,
.card-img-bottom {
  flex-shrink: 0;
  width: 100%; }

.card-img,
.card-img-top {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px); }

.card-img,
.card-img-bottom {
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px); }

.card-deck .card {
  margin-bottom: 15px; }

@media (min-width: 576px) {
  .card-deck {
    display: flex;
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px; }
    .card-deck .card {
      flex: 1 0 0%;
      margin-right: 15px;
      margin-bottom: 0;
      margin-left: 15px; } }

.card-group > .card {
  margin-bottom: 15px; }

@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap; }
    .card-group > .card {
      flex: 1 0 0%;
      margin-bottom: 0; }
      .card-group > .card + .card {
        margin-left: 0;
        border-left: 0; }
      .card-group > .card:not(:last-child) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0; }
        .card-group > .card:not(:last-child) .card-img-top,
        .card-group > .card:not(:last-child) .card-header {
          border-top-right-radius: 0; }
        .card-group > .card:not(:last-child) .card-img-bottom,
        .card-group > .card:not(:last-child) .card-footer {
          border-bottom-right-radius: 0; }
      .card-group > .card:not(:first-child) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0; }
        .card-group > .card:not(:first-child) .card-img-top,
        .card-group > .card:not(:first-child) .card-header {
          border-top-left-radius: 0; }
        .card-group > .card:not(:first-child) .card-img-bottom,
        .card-group > .card:not(:first-child) .card-footer {
          border-bottom-left-radius: 0; } }

.card-columns .card {
  margin-bottom: 0.75rem; }

@media (min-width: 576px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1; }
    .card-columns .card {
      display: inline-block;
      width: 100%; } }

.accordion {
  overflow-anchor: none; }
  .accordion > .card {
    overflow: hidden; }
    .accordion > .card:not(:last-of-type) {
      border-bottom: 0;
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0; }
    .accordion > .card:not(:first-of-type) {
      border-top-left-radius: 0;
      border-top-right-radius: 0; }
    .accordion > .card > .card-header {
      border-radius: 0;
      margin-bottom: -1px; }

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef;
  border-radius: 0.25rem; }

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem; }
  .breadcrumb-item + .breadcrumb-item::before {
    float: left;
    padding-right: 0.5rem;
    color: #6c757d;
    content: "/"; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none; }

.breadcrumb-item.active {
  color: #6c757d; }

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem; }

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #c21b17;
  background-color: #fff;
  border: 1px solid #dee2e6; }
  .page-link:hover {
    z-index: 2;
    color: #7e110f;
    text-decoration: none;
    background-color: #e9ecef;
    border-color: #dee2e6; }
  .page-link:focus {
    z-index: 3;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(194, 27, 23, 0.25); }

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem; }

.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem; }

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #c21b17;
  border-color: #c21b17; }

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6; }

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5; }

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem; }

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem; }

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5; }

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem; }

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem; }

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .badge {
      transition: none; } }
  a.badge:hover, a.badge:focus {
    text-decoration: none; }
  .badge:empty {
    display: none; }

.btn .badge {
  position: relative;
  top: -1px; }

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem; }

.badge-primary {
  color: #fff;
  background-color: #c21b17; }
  a.badge-primary:hover, a.badge-primary:focus {
    color: #fff;
    background-color: #941512; }
  a.badge-primary:focus, a.badge-primary.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(194, 27, 23, 0.5); }

.badge-secondary {
  color: #fff;
  background-color: #aa1b17; }
  a.badge-secondary:hover, a.badge-secondary:focus {
    color: #fff;
    background-color: #7d1411; }
  a.badge-secondary:focus, a.badge-secondary.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(170, 27, 23, 0.5); }

.badge-success {
  color: #fff;
  background-color: #28a745; }
  a.badge-success:hover, a.badge-success:focus {
    color: #fff;
    background-color: #1e7e34; }
  a.badge-success:focus, a.badge-success.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }

.badge-info {
  color: #fff;
  background-color: #17a2b8; }
  a.badge-info:hover, a.badge-info:focus {
    color: #fff;
    background-color: #117a8b; }
  a.badge-info:focus, a.badge-info.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }

.badge-warning {
  color: #212529;
  background-color: #ffc107; }
  a.badge-warning:hover, a.badge-warning:focus {
    color: #212529;
    background-color: #d39e00; }
  a.badge-warning:focus, a.badge-warning.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }

.badge-danger {
  color: #fff;
  background-color: #dc3545; }
  a.badge-danger:hover, a.badge-danger:focus {
    color: #fff;
    background-color: #bd2130; }
  a.badge-danger:focus, a.badge-danger.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }

.badge-light {
  color: #212529;
  background-color: #f8f9fa; }
  a.badge-light:hover, a.badge-light:focus {
    color: #212529;
    background-color: #dae0e5; }
  a.badge-light:focus, a.badge-light.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }

.badge-dark {
  color: #fff;
  background-color: #343a40; }
  a.badge-dark:hover, a.badge-dark:focus {
    color: #fff;
    background-color: #1d2124; }
  a.badge-dark:focus, a.badge-dark.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.badge-property {
  color: #fff;
  background-color: #D06E3F; }
  a.badge-property:hover, a.badge-property:focus {
    color: #fff;
    background-color: #b1572b; }
  a.badge-property:focus, a.badge-property.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(208, 110, 63, 0.5); }

.badge-casualty {
  color: #fff;
  background-color: #779196; }
  a.badge-casualty:hover, a.badge-casualty:focus {
    color: #fff;
    background-color: #5f767b; }
  a.badge-casualty:focus, a.badge-casualty.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(119, 145, 150, 0.5); }

.badge-overview {
  color: #fff;
  background-color: #aa1b17; }
  a.badge-overview:hover, a.badge-overview:focus {
    color: #fff;
    background-color: #7d1411; }
  a.badge-overview:focus, a.badge-overview.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(170, 27, 23, 0.5); }

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 0.3rem; }
  @media (min-width: 576px) {
    .jumbotron {
      padding: 4rem 2rem; } }

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0; }

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem; }

.alert-heading {
  color: inherit; }

.alert-link {
  font-weight: 700; }

.alert-dismissible {
  padding-right: 4rem; }
  .alert-dismissible .close {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
    padding: 0.75rem 1.25rem;
    color: inherit; }

.alert-primary {
  color: #650e0c;
  background-color: #f3d1d1;
  border-color: #eebfbe; }
  .alert-primary hr {
    border-top-color: #e9abaa; }
  .alert-primary .alert-link {
    color: #370807; }

.alert-secondary {
  color: #580e0c;
  background-color: #eed1d1;
  border-color: #e7bfbe; }
  .alert-secondary hr {
    border-top-color: #e0adab; }
  .alert-secondary .alert-link {
    color: #2b0706; }

.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb; }
  .alert-success hr {
    border-top-color: #b1dfbb; }
  .alert-success .alert-link {
    color: #0b2e13; }

.alert-info {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb; }
  .alert-info hr {
    border-top-color: #abdde5; }
  .alert-info .alert-link {
    color: #062c33; }

.alert-warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba; }
  .alert-warning hr {
    border-top-color: #ffe8a1; }
  .alert-warning .alert-link {
    color: #533f03; }

.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb; }
  .alert-danger hr {
    border-top-color: #f1b0b7; }
  .alert-danger .alert-link {
    color: #491217; }

.alert-light {
  color: #818182;
  background-color: #fefefe;
  border-color: #fdfdfe; }
  .alert-light hr {
    border-top-color: #ececf6; }
  .alert-light .alert-link {
    color: #686868; }

.alert-dark {
  color: #1b1e21;
  background-color: #d6d8d9;
  border-color: #c6c8ca; }
  .alert-dark hr {
    border-top-color: #b9bbbe; }
  .alert-dark .alert-link {
    color: #040505; }

.alert-property {
  color: #6c3921;
  background-color: #f6e2d9;
  border-color: #f2d6c9; }
  .alert-property hr {
    border-top-color: #edc6b4; }
  .alert-property .alert-link {
    color: #452415; }

.alert-casualty {
  color: #3e4b4e;
  background-color: #e4e9ea;
  border-color: #d9e0e2; }
  .alert-casualty hr {
    border-top-color: #cbd4d7; }
  .alert-casualty .alert-link {
    color: #273032; }

.alert-overview {
  color: #580e0c;
  background-color: #eed1d1;
  border-color: #e7bfbe; }
  .alert-overview hr {
    border-top-color: #e0adab; }
  .alert-overview .alert-link {
    color: #2b0706; }

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0; }
  to {
    background-position: 0 0; } }

.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  line-height: 0;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.25rem; }

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #c21b17;
  transition: width 0.6s ease; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar {
      transition: none; } }

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem; }

.progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar-animated {
      animation: none; } }

.media {
  display: flex;
  align-items: flex-start; }

.media-body {
  flex: 1; }

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0.25rem; }

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit; }
  .list-group-item-action:hover, .list-group-item-action:focus {
    z-index: 1;
    color: #495057;
    text-decoration: none;
    background-color: #f8f9fa; }
  .list-group-item-action:active {
    color: #212529;
    background-color: #e9ecef; }

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125); }
  .list-group-item:first-child {
    border-top-left-radius: inherit;
    border-top-right-radius: inherit; }
  .list-group-item:last-child {
    border-bottom-right-radius: inherit;
    border-bottom-left-radius: inherit; }
  .list-group-item.disabled, .list-group-item:disabled {
    color: #6c757d;
    pointer-events: none;
    background-color: #fff; }
  .list-group-item.active {
    z-index: 2;
    color: #fff;
    background-color: #c21b17;
    border-color: #c21b17; }
  .list-group-item + .list-group-item {
    border-top-width: 0; }
    .list-group-item + .list-group-item.active {
      margin-top: -1px;
      border-top-width: 1px; }

.list-group-horizontal {
  flex-direction: row; }
  .list-group-horizontal > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0; }
  .list-group-horizontal > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0; }
  .list-group-horizontal > .list-group-item.active {
    margin-top: 0; }
  .list-group-horizontal > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0; }
    .list-group-horizontal > .list-group-item + .list-group-item.active {
      margin-left: -1px;
      border-left-width: 1px; }

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row; }
    .list-group-horizontal-sm > .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-sm > .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-sm > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-sm > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row; }
    .list-group-horizontal-md > .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-md > .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-md > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-md > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-md > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row; }
    .list-group-horizontal-lg > .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-lg > .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-lg > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-lg > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row; }
    .list-group-horizontal-xl > .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-xl > .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-xl > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-xl > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

.list-group-flush {
  border-radius: 0; }
  .list-group-flush > .list-group-item {
    border-width: 0 0 1px; }
    .list-group-flush > .list-group-item:last-child {
      border-bottom-width: 0; }

.list-group-item-primary {
  color: #650e0c;
  background-color: #eebfbe; }
  .list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
    color: #650e0c;
    background-color: #e9abaa; }
  .list-group-item-primary.list-group-item-action.active {
    color: #fff;
    background-color: #650e0c;
    border-color: #650e0c; }

.list-group-item-secondary {
  color: #580e0c;
  background-color: #e7bfbe; }
  .list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
    color: #580e0c;
    background-color: #e0adab; }
  .list-group-item-secondary.list-group-item-action.active {
    color: #fff;
    background-color: #580e0c;
    border-color: #580e0c; }

.list-group-item-success {
  color: #155724;
  background-color: #c3e6cb; }
  .list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
    color: #155724;
    background-color: #b1dfbb; }
  .list-group-item-success.list-group-item-action.active {
    color: #fff;
    background-color: #155724;
    border-color: #155724; }

.list-group-item-info {
  color: #0c5460;
  background-color: #bee5eb; }
  .list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
    color: #0c5460;
    background-color: #abdde5; }
  .list-group-item-info.list-group-item-action.active {
    color: #fff;
    background-color: #0c5460;
    border-color: #0c5460; }

.list-group-item-warning {
  color: #856404;
  background-color: #ffeeba; }
  .list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
    color: #856404;
    background-color: #ffe8a1; }
  .list-group-item-warning.list-group-item-action.active {
    color: #fff;
    background-color: #856404;
    border-color: #856404; }

.list-group-item-danger {
  color: #721c24;
  background-color: #f5c6cb; }
  .list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
    color: #721c24;
    background-color: #f1b0b7; }
  .list-group-item-danger.list-group-item-action.active {
    color: #fff;
    background-color: #721c24;
    border-color: #721c24; }

.list-group-item-light {
  color: #818182;
  background-color: #fdfdfe; }
  .list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
    color: #818182;
    background-color: #ececf6; }
  .list-group-item-light.list-group-item-action.active {
    color: #fff;
    background-color: #818182;
    border-color: #818182; }

.list-group-item-dark {
  color: #1b1e21;
  background-color: #c6c8ca; }
  .list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
    color: #1b1e21;
    background-color: #b9bbbe; }
  .list-group-item-dark.list-group-item-action.active {
    color: #fff;
    background-color: #1b1e21;
    border-color: #1b1e21; }

.list-group-item-property {
  color: #6c3921;
  background-color: #f2d6c9; }
  .list-group-item-property.list-group-item-action:hover, .list-group-item-property.list-group-item-action:focus {
    color: #6c3921;
    background-color: #edc6b4; }
  .list-group-item-property.list-group-item-action.active {
    color: #fff;
    background-color: #6c3921;
    border-color: #6c3921; }

.list-group-item-casualty {
  color: #3e4b4e;
  background-color: #d9e0e2; }
  .list-group-item-casualty.list-group-item-action:hover, .list-group-item-casualty.list-group-item-action:focus {
    color: #3e4b4e;
    background-color: #cbd4d7; }
  .list-group-item-casualty.list-group-item-action.active {
    color: #fff;
    background-color: #3e4b4e;
    border-color: #3e4b4e; }

.list-group-item-overview {
  color: #580e0c;
  background-color: #e7bfbe; }
  .list-group-item-overview.list-group-item-action:hover, .list-group-item-overview.list-group-item-action:focus {
    color: #580e0c;
    background-color: #e0adab; }
  .list-group-item-overview.list-group-item-action.active {
    color: #fff;
    background-color: #580e0c;
    border-color: #580e0c; }

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5; }
  .close:hover {
    color: #000;
    text-decoration: none; }
  .close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
    opacity: .75; }

button.close {
  padding: 0;
  background-color: transparent;
  border: 0; }

a.close.disabled {
  pointer-events: none; }

.modal-open {
  overflow: hidden; }
  .modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto; }

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0; }

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none; }
  .modal.fade .modal-dialog {
    transition: transform 0.3s ease-out;
    transform: translate(0, -50px); }
    @media (prefers-reduced-motion: reduce) {
      .modal.fade .modal-dialog {
        transition: none; } }
  .modal.show .modal-dialog {
    transform: none; }
  .modal.modal-static .modal-dialog {
    transform: scale(1.02); }

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem); }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 1rem);
    overflow: hidden; }
  .modal-dialog-scrollable .modal-header,
  .modal-dialog-scrollable .modal-footer {
    flex-shrink: 0; }
  .modal-dialog-scrollable .modal-body {
    overflow-y: auto; }

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem); }
  .modal-dialog-centered::before {
    display: block;
    height: calc(100vh - 1rem);
    height: min-content;
    content: ""; }
  .modal-dialog-centered.modal-dialog-scrollable {
    flex-direction: column;
    justify-content: center;
    height: 100%; }
    .modal-dialog-centered.modal-dialog-scrollable .modal-content {
      max-height: none; }
    .modal-dialog-centered.modal-dialog-scrollable::before {
      content: none; }

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0; }

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000; }
  .modal-backdrop.fade {
    opacity: 0; }
  .modal-backdrop.show {
    opacity: 0.5; }

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px); }
  .modal-header .close {
    padding: 1rem 1rem;
    margin: -1rem -1rem -1rem auto; }

.modal-title {
  margin-bottom: 0;
  line-height: 1.5; }

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem; }

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px); }
  .modal-footer > * {
    margin: 0.25rem; }

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll; }

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto; }
  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem); }
    .modal-dialog-scrollable .modal-content {
      max-height: calc(100vh - 3.5rem); }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem); }
    .modal-dialog-centered::before {
      height: calc(100vh - 3.5rem);
      height: min-content; }
  .modal-sm {
    max-width: 300px; } }

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px; } }

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px; } }

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: "LatoWebLight", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0; }
  .tooltip.show {
    opacity: 0.9; }
  .tooltip .arrow {
    position: absolute;
    display: block;
    width: 0.8rem;
    height: 0.4rem; }
    .tooltip .arrow::before {
      position: absolute;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-tooltip-top, .bs-tooltip-auto[x-placement^="top"] {
  padding: 0.4rem 0; }
  .bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^="top"] .arrow {
    bottom: 0; }
    .bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
      top: 0;
      border-width: 0.4rem 0.4rem 0;
      border-top-color: #000; }

.bs-tooltip-right, .bs-tooltip-auto[x-placement^="right"] {
  padding: 0 0.4rem; }
  .bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^="right"] .arrow {
    left: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before {
      right: 0;
      border-width: 0.4rem 0.4rem 0.4rem 0;
      border-right-color: #000; }

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^="bottom"] {
  padding: 0.4rem 0; }
  .bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^="bottom"] .arrow {
    top: 0; }
    .bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
      bottom: 0;
      border-width: 0 0.4rem 0.4rem;
      border-bottom-color: #000; }

.bs-tooltip-left, .bs-tooltip-auto[x-placement^="left"] {
  padding: 0 0.4rem; }
  .bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^="left"] .arrow {
    right: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before {
      left: 0;
      border-width: 0.4rem 0 0.4rem 0.4rem;
      border-left-color: #000; }

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem; }

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: "LatoWebLight", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem; }
  .popover .arrow {
    position: absolute;
    display: block;
    width: 1rem;
    height: 0.5rem;
    margin: 0 0.3rem; }
    .popover .arrow::before, .popover .arrow::after {
      position: absolute;
      display: block;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-popover-top, .bs-popover-auto[x-placement^="top"] {
  margin-bottom: 0.5rem; }
  .bs-popover-top > .arrow, .bs-popover-auto[x-placement^="top"] > .arrow {
    bottom: calc(-0.5rem - 1px); }
    .bs-popover-top > .arrow::before, .bs-popover-auto[x-placement^="top"] > .arrow::before {
      bottom: 0;
      border-width: 0.5rem 0.5rem 0;
      border-top-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-top > .arrow::after, .bs-popover-auto[x-placement^="top"] > .arrow::after {
      bottom: 1px;
      border-width: 0.5rem 0.5rem 0;
      border-top-color: #fff; }

.bs-popover-right, .bs-popover-auto[x-placement^="right"] {
  margin-left: 0.5rem; }
  .bs-popover-right > .arrow, .bs-popover-auto[x-placement^="right"] > .arrow {
    left: calc(-0.5rem - 1px);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
    .bs-popover-right > .arrow::before, .bs-popover-auto[x-placement^="right"] > .arrow::before {
      left: 0;
      border-width: 0.5rem 0.5rem 0.5rem 0;
      border-right-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-right > .arrow::after, .bs-popover-auto[x-placement^="right"] > .arrow::after {
      left: 1px;
      border-width: 0.5rem 0.5rem 0.5rem 0;
      border-right-color: #fff; }

.bs-popover-bottom, .bs-popover-auto[x-placement^="bottom"] {
  margin-top: 0.5rem; }
  .bs-popover-bottom > .arrow, .bs-popover-auto[x-placement^="bottom"] > .arrow {
    top: calc(-0.5rem - 1px); }
    .bs-popover-bottom > .arrow::before, .bs-popover-auto[x-placement^="bottom"] > .arrow::before {
      top: 0;
      border-width: 0 0.5rem 0.5rem 0.5rem;
      border-bottom-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^="bottom"] > .arrow::after {
      top: 1px;
      border-width: 0 0.5rem 0.5rem 0.5rem;
      border-bottom-color: #fff; }
  .bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^="bottom"] .popover-header::before {
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: 1rem;
    margin-left: -0.5rem;
    content: "";
    border-bottom: 1px solid #f7f7f7; }

.bs-popover-left, .bs-popover-auto[x-placement^="left"] {
  margin-right: 0.5rem; }
  .bs-popover-left > .arrow, .bs-popover-auto[x-placement^="left"] > .arrow {
    right: calc(-0.5rem - 1px);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
    .bs-popover-left > .arrow::before, .bs-popover-auto[x-placement^="left"] > .arrow::before {
      right: 0;
      border-width: 0.5rem 0 0.5rem 0.5rem;
      border-left-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-left > .arrow::after, .bs-popover-auto[x-placement^="left"] > .arrow::after {
      right: 1px;
      border-width: 0.5rem 0 0.5rem 0.5rem;
      border-left-color: #fff; }

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px); }
  .popover-header:empty {
    display: none; }

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #212529; }

.carousel {
  position: relative; }

.carousel.pointer-event {
  touch-action: pan-y; }

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden; }
  .carousel-inner::after {
    display: block;
    clear: both;
    content: ""; }

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-item {
      transition: none; } }

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block; }

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  transform: translateX(100%); }

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  transform: translateX(-100%); }

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none; }

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1; }

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-fade .active.carousel-item-left,
    .carousel-fade .active.carousel-item-right {
      transition: none; } }

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  padding: 0;
  color: #fff;
  text-align: center;
  background: none;
  border: 0;
  opacity: 0.5;
  transition: opacity 0.15s ease; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-control-prev,
    .carousel-control-next {
      transition: none; } }
  .carousel-control-prev:hover, .carousel-control-prev:focus,
  .carousel-control-next:hover,
  .carousel-control-next:focus {
    color: #fff;
    text-decoration: none;
    outline: 0;
    opacity: 0.9; }

.carousel-control-prev {
  left: 0; }

.carousel-control-next {
  right: 0; }

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: 50% / 100% 100% no-repeat; }

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e"); }

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e"); }

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none; }
  .carousel-indicators li {
    box-sizing: content-box;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #fff;
    background-clip: padding-box;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: .5;
    transition: opacity 0.6s ease; }
    @media (prefers-reduced-motion: reduce) {
      .carousel-indicators li {
        transition: none; } }
  .carousel-indicators .active {
    opacity: 1; }

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center; }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.bg-primary {
  background-color: #c21b17 !important; }

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #941512 !important; }

.bg-secondary {
  background-color: #aa1b17 !important; }

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #7d1411 !important; }

.bg-success {
  background-color: #28a745 !important; }

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #1e7e34 !important; }

.bg-info {
  background-color: #17a2b8 !important; }

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #117a8b !important; }

.bg-warning {
  background-color: #ffc107 !important; }

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #d39e00 !important; }

.bg-danger {
  background-color: #dc3545 !important; }

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #bd2130 !important; }

.bg-light {
  background-color: #f8f9fa !important; }

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important; }

.bg-dark {
  background-color: #343a40 !important; }

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important; }

.bg-property {
  background-color: #D06E3F !important; }

a.bg-property:hover, a.bg-property:focus,
button.bg-property:hover,
button.bg-property:focus {
  background-color: #b1572b !important; }

.bg-casualty {
  background-color: #779196 !important; }

a.bg-casualty:hover, a.bg-casualty:focus,
button.bg-casualty:hover,
button.bg-casualty:focus {
  background-color: #5f767b !important; }

.bg-overview {
  background-color: #aa1b17 !important; }

a.bg-overview:hover, a.bg-overview:focus,
button.bg-overview:hover,
button.bg-overview:focus {
  background-color: #7d1411 !important; }

.bg-white {
  background-color: #fff !important; }

.bg-transparent {
  background-color: transparent !important; }

.border {
  border: 1px solid #dee2e6 !important; }

.border-top {
  border-top: 1px solid #dee2e6 !important; }

.border-right {
  border-right: 1px solid #dee2e6 !important; }

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important; }

.border-left {
  border-left: 1px solid #dee2e6 !important; }

.border-0 {
  border: 0 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-right-0 {
  border-right: 0 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-left-0 {
  border-left: 0 !important; }

.border-primary {
  border-color: #c21b17 !important; }

.border-secondary {
  border-color: #aa1b17 !important; }

.border-success {
  border-color: #28a745 !important; }

.border-info {
  border-color: #17a2b8 !important; }

.border-warning {
  border-color: #ffc107 !important; }

.border-danger {
  border-color: #dc3545 !important; }

.border-light {
  border-color: #f8f9fa !important; }

.border-dark {
  border-color: #343a40 !important; }

.border-property {
  border-color: #D06E3F !important; }

.border-casualty {
  border-color: #779196 !important; }

.border-overview {
  border-color: #aa1b17 !important; }

.border-white {
  border-color: #fff !important; }

.rounded-sm {
  border-radius: 0.2rem !important; }

.rounded {
  border-radius: 0.25rem !important; }

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important; }

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important; }

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-lg {
  border-radius: 0.3rem !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-pill {
  border-radius: 50rem !important; }

.rounded-0 {
  border-radius: 0 !important; }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; } }

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; }
  .embed-responsive::before {
    display: block;
    content: ""; }
  .embed-responsive .embed-responsive-item,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

.embed-responsive-21by9::before {
  padding-top: 42.85714%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-4by3::before {
  padding-top: 75%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.flex-fill {
  flex: 1 1 auto !important; }

.flex-grow-0 {
  flex-grow: 0 !important; }

.flex-grow-1 {
  flex-grow: 1 !important; }

.flex-shrink-0 {
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  flex-shrink: 1 !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-sm-fill {
    flex: 1 1 auto !important; }
  .flex-sm-grow-0 {
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-md-fill {
    flex: 1 1 auto !important; }
  .flex-md-grow-0 {
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-lg-fill {
    flex: 1 1 auto !important; }
  .flex-lg-grow-0 {
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-xl-fill {
    flex: 1 1 auto !important; }
  .flex-xl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; } }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-none {
  float: none !important; }

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important; }
  .float-sm-right {
    float: right !important; }
  .float-sm-none {
    float: none !important; } }

@media (min-width: 768px) {
  .float-md-left {
    float: left !important; }
  .float-md-right {
    float: right !important; }
  .float-md-none {
    float: none !important; } }

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important; }
  .float-lg-right {
    float: right !important; }
  .float-lg-none {
    float: none !important; } }

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important; }
  .float-xl-right {
    float: right !important; }
  .float-xl-none {
    float: none !important; } }

.user-select-all {
  user-select: all !important; }

.user-select-auto {
  user-select: auto !important; }

.user-select-none {
  user-select: none !important; }

.overflow-auto {
  overflow: auto !important; }

.overflow-hidden {
  overflow: hidden !important; }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: sticky !important; }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal; }

.shadow-sm {
  box-shadow: none !important; }

.shadow {
  box-shadow: none !important; }

.shadow-lg {
  box-shadow: none !important; }

.shadow-none {
  box-shadow: none !important; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.w-auto {
  width: auto !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.h-auto {
  height: auto !important; }

.mw-100 {
  max-width: 100% !important; }

.mh-100 {
  max-height: 100% !important; }

.min-vw-100 {
  min-width: 100vw !important; }

.min-vh-100 {
  min-height: 100vh !important; }

.vw-100 {
  width: 100vw !important; }

.vh-100 {
  height: 100vh !important; }

.m-0 {
  margin: 0 !important; }

.mt-0,
.my-0 {
  margin-top: 0 !important; }

.mr-0,
.mx-0 {
  margin-right: 0 !important; }

.mb-0,
.my-0 {
  margin-bottom: 0 !important; }

.ml-0,
.mx-0 {
  margin-left: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.mt-1,
.my-1 {
  margin-top: 0.25rem !important; }

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important; }

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important; }

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.mt-2,
.my-2 {
  margin-top: 0.5rem !important; }

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important; }

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important; }

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.mt-3,
.my-3 {
  margin-top: 1rem !important; }

.mr-3,
.mx-3 {
  margin-right: 1rem !important; }

.mb-3,
.my-3 {
  margin-bottom: 1rem !important; }

.ml-3,
.mx-3 {
  margin-left: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.mt-4,
.my-4 {
  margin-top: 1.5rem !important; }

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important; }

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important; }

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important; }

.m-5 {
  margin: 3rem !important; }

.mt-5,
.my-5 {
  margin-top: 3rem !important; }

.mr-5,
.mx-5 {
  margin-right: 3rem !important; }

.mb-5,
.my-5 {
  margin-bottom: 3rem !important; }

.ml-5,
.mx-5 {
  margin-left: 3rem !important; }

.p-0 {
  padding: 0 !important; }

.pt-0,
.py-0 {
  padding-top: 0 !important; }

.pr-0,
.px-0 {
  padding-right: 0 !important; }

.pb-0,
.py-0 {
  padding-bottom: 0 !important; }

.pl-0,
.px-0 {
  padding-left: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.pt-1,
.py-1 {
  padding-top: 0.25rem !important; }

.pr-1,
.px-1 {
  padding-right: 0.25rem !important; }

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important; }

.pl-1,
.px-1 {
  padding-left: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.pt-2,
.py-2 {
  padding-top: 0.5rem !important; }

.pr-2,
.px-2 {
  padding-right: 0.5rem !important; }

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important; }

.pl-2,
.px-2 {
  padding-left: 0.5rem !important; }

.p-3 {
  padding: 1rem !important; }

.pt-3,
.py-3 {
  padding-top: 1rem !important; }

.pr-3,
.px-3 {
  padding-right: 1rem !important; }

.pb-3,
.py-3 {
  padding-bottom: 1rem !important; }

.pl-3,
.px-3 {
  padding-left: 1rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.pt-4,
.py-4 {
  padding-top: 1.5rem !important; }

.pr-4,
.px-4 {
  padding-right: 1.5rem !important; }

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important; }

.pl-4,
.px-4 {
  padding-left: 1.5rem !important; }

.p-5 {
  padding: 3rem !important; }

.pt-5,
.py-5 {
  padding-top: 3rem !important; }

.pr-5,
.px-5 {
  padding-right: 3rem !important; }

.pb-5,
.py-5 {
  padding-bottom: 3rem !important; }

.pl-5,
.px-5 {
  padding-left: 3rem !important; }

.m-n1 {
  margin: -0.25rem !important; }

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important; }

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important; }

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important; }

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important; }

.m-n2 {
  margin: -0.5rem !important; }

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important; }

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important; }

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important; }

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important; }

.m-n3 {
  margin: -1rem !important; }

.mt-n3,
.my-n3 {
  margin-top: -1rem !important; }

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important; }

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important; }

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important; }

.m-n4 {
  margin: -1.5rem !important; }

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important; }

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important; }

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important; }

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important; }

.m-n5 {
  margin: -3rem !important; }

.mt-n5,
.my-n5 {
  margin-top: -3rem !important; }

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important; }

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important; }

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important; }

.m-auto {
  margin: auto !important; }

.mt-auto,
.my-auto {
  margin-top: auto !important; }

.mr-auto,
.mx-auto {
  margin-right: auto !important; }

.mb-auto,
.my-auto {
  margin-bottom: auto !important; }

.ml-auto,
.mx-auto {
  margin-left: auto !important; }

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important; }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important; }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important; }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important; }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.5rem !important; }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important; }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important; }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important; }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important; }
  .m-sm-3 {
    margin: 1rem !important; }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important; }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important; }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important; }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important; }
  .m-sm-4 {
    margin: 1.5rem !important; }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important; }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important; }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important; }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important; }
  .m-sm-5 {
    margin: 3rem !important; }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important; }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important; }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important; }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important; }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important; }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.5rem !important; }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important; }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important; }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important; }
  .p-sm-3 {
    padding: 1rem !important; }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important; }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important; }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important; }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important; }
  .p-sm-4 {
    padding: 1.5rem !important; }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important; }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important; }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important; }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important; }
  .p-sm-5 {
    padding: 3rem !important; }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important; }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important; }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important; }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important; }
  .m-sm-n1 {
    margin: -0.25rem !important; }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important; }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important; }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important; }
  .m-sm-n2 {
    margin: -0.5rem !important; }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important; }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important; }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important; }
  .m-sm-n3 {
    margin: -1rem !important; }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important; }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important; }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important; }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important; }
  .m-sm-n4 {
    margin: -1.5rem !important; }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important; }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important; }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important; }
  .m-sm-n5 {
    margin: -3rem !important; }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important; }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important; }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important; }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important; }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important; }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important; }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important; }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important; }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important; }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important; }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important; }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important; }
  .m-md-3 {
    margin: 1rem !important; }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important; }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important; }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important; }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important; }
  .m-md-4 {
    margin: 1.5rem !important; }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important; }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important; }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important; }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important; }
  .m-md-5 {
    margin: 3rem !important; }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important; }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important; }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important; }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important; }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important; }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important; }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important; }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important; }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important; }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important; }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important; }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important; }
  .p-md-3 {
    padding: 1rem !important; }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important; }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important; }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important; }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important; }
  .p-md-4 {
    padding: 1.5rem !important; }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important; }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important; }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important; }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important; }
  .p-md-5 {
    padding: 3rem !important; }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important; }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important; }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important; }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important; }
  .m-md-n1 {
    margin: -0.25rem !important; }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important; }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important; }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important; }
  .m-md-n2 {
    margin: -0.5rem !important; }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important; }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important; }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important; }
  .m-md-n3 {
    margin: -1rem !important; }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important; }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important; }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important; }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important; }
  .m-md-n4 {
    margin: -1.5rem !important; }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important; }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important; }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important; }
  .m-md-n5 {
    margin: -3rem !important; }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important; }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important; }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important; }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important; }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important; }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important; }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important; }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important; }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important; }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important; }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important; }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important; }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important; }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important; }
  .m-lg-4 {
    margin: 1.5rem !important; }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important; }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important; }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important; }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important; }
  .m-lg-5 {
    margin: 3rem !important; }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important; }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important; }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important; }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important; }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important; }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important; }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important; }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important; }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important; }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important; }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important; }
  .p-lg-4 {
    padding: 1.5rem !important; }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important; }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important; }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important; }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important; }
  .p-lg-5 {
    padding: 3rem !important; }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important; }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important; }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important; }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important; }
  .m-lg-n1 {
    margin: -0.25rem !important; }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important; }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important; }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important; }
  .m-lg-n2 {
    margin: -0.5rem !important; }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important; }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important; }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important; }
  .m-lg-n3 {
    margin: -1rem !important; }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important; }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important; }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important; }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important; }
  .m-lg-n4 {
    margin: -1.5rem !important; }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important; }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important; }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important; }
  .m-lg-n5 {
    margin: -3rem !important; }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important; }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important; }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important; }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important; }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important; }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important; }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important; }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important; }
  .m-xl-1 {
    margin: 0.25rem !important; }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important; }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important; }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important; }
  .m-xl-2 {
    margin: 0.5rem !important; }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important; }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important; }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important; }
  .m-xl-3 {
    margin: 1rem !important; }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important; }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important; }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important; }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important; }
  .m-xl-4 {
    margin: 1.5rem !important; }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important; }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important; }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important; }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important; }
  .m-xl-5 {
    margin: 3rem !important; }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important; }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important; }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important; }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important; }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important; }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important; }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important; }
  .p-xl-1 {
    padding: 0.25rem !important; }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important; }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important; }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important; }
  .p-xl-2 {
    padding: 0.5rem !important; }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important; }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important; }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important; }
  .p-xl-3 {
    padding: 1rem !important; }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important; }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important; }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important; }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important; }
  .p-xl-4 {
    padding: 1.5rem !important; }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important; }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important; }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important; }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important; }
  .p-xl-5 {
    padding: 3rem !important; }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important; }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important; }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important; }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important; }
  .m-xl-n1 {
    margin: -0.25rem !important; }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important; }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important; }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important; }
  .m-xl-n2 {
    margin: -0.5rem !important; }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important; }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important; }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important; }
  .m-xl-n3 {
    margin: -1rem !important; }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important; }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important; }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important; }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important; }
  .m-xl-n4 {
    margin: -1.5rem !important; }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important; }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important; }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important; }
  .m-xl-n5 {
    margin: -3rem !important; }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important; }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important; }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important; }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important; } }

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0); }

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important; }

.text-justify {
  text-align: justify !important; }

.text-wrap {
  white-space: normal !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important; }
  .text-sm-right {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important; }
  .text-md-right {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important; }
  .text-lg-right {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important; }
  .text-xl-right {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.font-weight-light {
  font-weight: 300 !important; }

.font-weight-lighter {
  font-weight: lighter !important; }

.font-weight-normal {
  font-weight: 400 !important; }

.font-weight-bold {
  font-weight: 700 !important; }

.font-weight-bolder {
  font-weight: bolder !important; }

.font-italic {
  font-style: italic !important; }

.text-white {
  color: #fff !important; }

.text-primary {
  color: #c21b17 !important; }

a.text-primary:hover, a.text-primary:focus {
  color: #7e110f !important; }

.text-secondary {
  color: #aa1b17 !important; }

a.text-secondary:hover, a.text-secondary:focus {
  color: #67100e !important; }

.text-success {
  color: #28a745 !important; }

a.text-success:hover, a.text-success:focus {
  color: #19692c !important; }

.text-info {
  color: #17a2b8 !important; }

a.text-info:hover, a.text-info:focus {
  color: #0f6674 !important; }

.text-warning {
  color: #ffc107 !important; }

a.text-warning:hover, a.text-warning:focus {
  color: #ba8b00 !important; }

.text-danger {
  color: #dc3545 !important; }

a.text-danger:hover, a.text-danger:focus {
  color: #a71d2a !important; }

.text-light {
  color: #f8f9fa !important; }

a.text-light:hover, a.text-light:focus {
  color: #cbd3da !important; }

.text-dark {
  color: #343a40 !important; }

a.text-dark:hover, a.text-dark:focus {
  color: #121416 !important; }

.text-property {
  color: #D06E3F !important; }

a.text-property:hover, a.text-property:focus {
  color: #9c4c26 !important; }

.text-casualty {
  color: #779196 !important; }

a.text-casualty:hover, a.text-casualty:focus {
  color: #54696d !important; }

.text-overview {
  color: #aa1b17 !important; }

a.text-overview:hover, a.text-overview:focus {
  color: #67100e !important; }

.text-body {
  color: #212529 !important; }

.text-muted {
  color: #6c757d !important; }

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important; }

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.text-decoration-none {
  text-decoration: none !important; }

.text-break {
  word-break: break-word !important;
  word-wrap: break-word !important; }

.text-reset {
  color: inherit !important; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

/*
** THEME COLOR VARS
*/
/*
** new brand guidelines colors
*/
html,
body {
  width: 100vw;
  max-width: 100vw;
  overflow-x: hidden;
  font-family: 'LatoWebLight', sans-serif;
  background: #FFF;
  color: #6E6F71;
  font-size: 14px; }

a {
  text-decoration: none;
  color: inherit; }
  a:hover {
    text-decoration: none; }

.no-scroll {
  overflow: hidden; }

.scrollbar-interest {
  height: 12rem;
  overflow: auto;
  margin-bottom: 0.5rem; }

.scrollbar-polices {
  height: 35rem;
  overflow: auto; }

.scrollbar-pending {
  height: 129px;
  overflow: auto;
  margin-bottom: 40px; }

p.h4 {
  font-family: "LatoWeb", sans-serif;
  font-size: 1.5rem;
  letter-spacing: 0.02rem;
  color: #4a4a4a; }
  p.h4 u {
    font-family: "LatoWeb", sans-serif;
    color: #8e1230;
    font-size: 1.6rem; }

p.h6 {
  font-size: 1rem;
  color: #707070; }
  p.h6 img {
    width: 1.5rem; }
  p.h6 u {
    font-size: 0.9rem;
    color: #6f7072; }

span.welcome {
  text-transform: uppercase;
  font-size: 0.8rem;
  color: #6f7072;
  font-family: "LatoWebBold", sans-serif; }

span.program {
  text-transform: uppercase;
  font-size: 0.8rem;
  color: #4a4a4a;
  margin: 0.5rem;
  font-family: "LatoWebBold", sans-serif; }

span.country {
  text-transform: uppercase;
  font-size: 0.8rem;
  color: #6f7072;
  font-family: "LatoWeb", sans-serif; }

p.date {
  font-size: 0.78rem; }

p.add-info {
  font-family: "LatoWeb", sans-serif;
  font-size: 0.8rem;
  letter-spacing: 0.03rem;
  color: #6f7072;
  margin-bottom: 0;
  border-bottom: 1px solid #AEAFB0; }

p.status {
  font-family: "LatoWeb", sans-serif;
  font-size: 1.5rem;
  line-height: 1.14;
  text-align: center;
  color: #9b9b9b; }

.average {
  border-bottom: 0.1rem solid #AEAFB0;
  padding-bottom: 0.3rem;
  padding-top: 0.5rem; }

h2.status {
  color: #AC2009;
  font-family: "LatoWebLight", sans-serif; }

table.pendingStatus thead tr {
  background: #dadada; }
  table.pendingStatus thead tr th {
    font-family: "LatoWebBold", sans-serif;
    font-size: 0.78rem;
    letter-spacing: 0.1rem;
    color: #6f7072; }

table.pendingStatus tbody tr td {
  font-size: 0.78rem;
  font-family: "LatoWebLight", sans-serif;
  border-bottom: 0.1rem solid #9b9b9b !important; }

table.pendingStatus tbody tr th {
  border-bottom: 0.1rem solid #9b9b9b !important; }

table.premiumAllocationsTable {
  width: 100%; }
  @media (max-width: 767px) {
    table.premiumAllocationsTable tr {
      padding: 0 !important;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      margin: 0.5em 0; }
    table.premiumAllocationsTable td, table.premiumAllocationsTable th {
      flex: 1 1 150px;
      text-align: center !important;
      padding-left: 0 !important;
      padding-right: 0 !important; }
      table.premiumAllocationsTable td.sm-full, table.premiumAllocationsTable th.sm-full {
        min-width: 90% !important;
        width: 90% !important;
        flex: 1 0 90%;
        text-align: center; }
      table.premiumAllocationsTable td.sm-none, table.premiumAllocationsTable th.sm-none {
        max-width: 0 !important;
        overflow: hidden; } }
  table.premiumAllocationsTable thead tr {
    background: #dadada; }
    table.premiumAllocationsTable thead tr th {
      font-size: 0.78rem;
      letter-spacing: 0.1rem;
      color: #6f7072; }
  table.premiumAllocationsTable tbody tr {
    padding: 2rem;
    border-bottom: 1px solid #6E6F71; }
    table.premiumAllocationsTable tbody tr td {
      font-size: 1rem;
      padding-top: 1rem; }

.premiumAllocations {
  position: relative;
  overflow: auto;
  max-height: 340px;
  width: 100%; }

.pending {
  display: none;
  position: fixed;
  z-index: 1;
  padding-top: 8rem;
  left: 0rem;
  top: 0rem;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: black;
  background-color: rgba(0, 0, 0, 0.4); }

.pending-content {
  background-color: #fefefe;
  margin: auto;
  padding: 2rem;
  border: 0.1rem solid #888;
  width: 100%; }

.closeCas {
  margin-left: 98%; }

.closeCas:hover,
.closeCas:focus {
  cursor: pointer; }

.closePrp {
  margin-left: 98%; }

.closePrp:hover,
.closePrp:focus {
  cursor: pointer; }

.no-pending-policies {
  text-align: center;
  height: 100%;
  padding-bottom: 1rem;
  border-top: 2px solid #e0ddd6; }
  .no-pending-policies img {
    width: 5rem;
    height: 5rem;
    margin-bottom: 1rem;
    margin-top: 3rem; }

.doc-filter {
  margin-bottom: 2rem; }
  @media (max-width: 991px) {
    .doc-filter {
      margin-right: 0; } }
  .doc-filter label {
    font-size: 0.9rem;
    width: 100%; }

i {
  font-size: 0.87rem; }

.modal-header {
  border-bottom: none !important; }

.contactCountry-container {
  display: none;
  position: fixed;
  z-index: 1;
  padding-top: 8rem;
  left: 0rem;
  top: 0rem;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: black;
  background-color: rgba(0, 0, 0, 0.4); }
  @media (max-width: 767px) {
    .contactCountry-container {
      padding-right: 15px;
      padding-left: 15px;
      padding-top: 15px !important;
      padding-bottom: 8rem; } }

.contactCountry-content {
  background-color: #fefefe;
  margin: auto;
  padding: 2rem;
  border: 0.1rem solid #888;
  width: 60%; }
  @media (max-width: 767px) {
    .contactCountry-content {
      width: 100%; } }

.closecc {
  margin-left: 98%; }

.closecc:hover,
.closecc:focus {
  cursor: pointer; }

@media (max-width: 991px) {
  .contact-label {
    font-size: 10px;
    min-height: 25px; } }

@media (max-width: 575px) {
  .contact-label {
    min-height: 0; } }

.pagination .page-item .page-link {
  font-family: "LatoWebBold", sans-serif;
  font-size: 14px;
  text-align: center;
  color: #6f7072; }

.pagination .page-item.active .page-link {
  font-family: "LatoWebBold", sans-serif;
  font-size: 14px;
  text-align: center;
  color: #8e1230; }

div.dataTables_wrapper table.dataTable {
  width: 100% !important; }

div.dataTables_wrapper div.dataTables_processing {
  top: calc(50% + 35px) !important;
  color: #BB2D21 !important;
  border-bottom: 0 !important; }

@media (max-width: 767px) {
  table.dataTable thead .sorting::before,
  table.dataTable thead .sorting::before,
  table.dataTable thead .sorting::after,
  table.dataTable thead .sorting::after {
    display: none; } }

.program-status-label {
  color: #c21b17;
  font-weight: bold;
  float: right;
  padding: 0.375rem 0.5rem;
  border-radius: 0.2rem;
  margin-left: 15px;
  margin-top: 8px; }
  @media (max-width: 767px) {
    .program-status-label {
      margin-top: 0; } }

.program-status-span {
  margin-top: 12px;
  float: right; }
  @media (max-width: 767px) {
    .program-status-span {
      margin-top: 5px; } }

.min-height {
  min-height: 69vh; }

.archive-panel {
  padding: 10rem;
  background-color: #f6f4f0; }

.scrollbar-exposure {
  overflow-y: scroll;
  height: 11rem;
  margin-bottom: 0.5rem; }

.info-box span.value-exposure {
  font-size: 1.2rem;
  font-weight: 300;
  color: #313131;
  display: block;
  border-bottom: 1px solid #AEAFB0;
  padding-bottom: 5px;
  padding-top: 10px; }
  .info-box span.value-exposure .amount {
    font-size: 1.8rem; }

.searchLocalPolices {
  position: relative;
  display: flex; }

.searchText {
  display: none;
  width: 88%;
  border: 1px solid #9b9b9b;
  font-style: italic;
  padding-left: 0.5rem;
  position: absolute;
  border-radius: 5px;
  left: 0; }
  .searchText:hover, .searchText:focus {
    outline: none; }

.searchPolices {
  position: absolute;
  right: 0; }

.topMargin {
  margin-top: 7rem; }

.tooltip {
  left: 5rem !important; }
  .tooltip .tooltip-inner {
    width: auto;
    height: auto;
    background-color: #ffffff;
    border: 2px solid #aa1b17;
    font-size: 0.8rem;
    color: #aa1b17;
    font-family: "LatoWeb", sans-serif;
    text-align: left;
    text-transform: uppercase;
    border-radius: 0rem !important;
    padding: 0.8rem;
    max-width: 193px !important; }
  .tooltip span {
    font-family: "LatoWeb", sans-serif;
    font-size: 0.8rem;
    color: #4a4a4a;
    text-transform: none; }

.bs-tooltip-top, .bs-tooltip-auto[x-placement^="top"] {
  left: 13px !important;
  top: -9px !important; }
  .bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
    top: 0 !important;
    border-width: 1rem 1rem 0rem 0rem !important;
    border-top-color: #aa1b17 !important;
    right: 0rem !important; }

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^="bottom"] {
  top: 8px !important; }
  .bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before, .bs-tooltip-bottom .bs-tooltip-auto[x-placement^="bottom"] .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
    border-width: 0rem 1rem 1rem 0rem !important;
    border-bottom-color: #aa1b17 !important;
    right: 4rem !important; }

.exposureTable thead th {
  font-size: 0.85rem;
  font-weight: 300;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: #6f7072;
  padding: 0.7rem 0rem;
  border-bottom: none;
  border-top: none;
  width: 50%; }

.exposureTable thead th.info-box.empty {
  width: 0 !important; }

.exposureTable tbody td {
  padding-bottom: 7px !important;
  font-size: 1.5rem;
  font-weight: 300;
  color: #313131;
  border-bottom: 1px solid #AEAFB0;
  border-top: none;
  padding-left: 0px !important;
  padding-top: 13px !important;
  vertical-align: bottom; }
  .exposureTable tbody td .amount {
    font-size: 1.8rem; }

.archived-informative-message {
  font-size: 1.5rem; }

.archived-section-link:hover {
  color: #8e1230; }

.legend {
  list-style: none;
  margin-top: 3rem; }
  .legend li {
    float: left;
    margin-right: 10px; }
  .legend span {
    float: left;
    width: 20px;
    height: 20px;
    margin-right: 6px; }
  .legend .policy-status {
    background-color: #FC5854; }
  .legend .premium-status {
    background-color: #BC1814; }

#country-widget {
  position: relative; }

.programWidget {
  top: -80px;
  position: relative; }

.policyIssued-scroll {
  height: 300px;
  overflow: auto; }

.policyToBeIssued-scroll {
  height: 300px;
  overflow: auto; }

.policy-scroll {
  height: 300px;
  overflow: auto; }

.premium-scroll {
  height: 300px;
  overflow: auto; }

.info-view-more {
  font-family: "LatoWebBold", sans-serif;
  font-size: 1rem;
  letter-spacing: 0.6px;
  color: #6f7072;
  text-transform: uppercase;
  margin-top: -6rem; }
  .info-view-more label {
    cursor: pointer; }
  .info-view-more .view-more {
    text-transform: uppercase;
    font-family: "LatoWebBold", sans-serif;
    font-size: 0.8rem;
    color: #c21b17; }
    .info-view-more .view-more:hover {
      color: #6f7072; }

.documents-search input,
.all-contacts-search input {
  width: calc(100% - 194px);
  margin: 0;
  display: inline-block;
  height: 40px;
  border: 2px solid #707070;
  outline-style: none;
  padding-left: 15px;
  border-radius: 5px 0 0 5px; }

.documents-search .documents-button,
.documents-search .all-contacts-button,
.all-contacts-search .documents-button,
.all-contacts-search .all-contacts-button {
  height: 40px; }

.view {
  font-family: "LatoWebBold", sans-serif;
  font-size: 0.7rem;
  letter-spacing: 0.5px;
  text-align: left;
  color: #6f7072; }

.help-tip {
  position: absolute;
  text-align: center;
  width: 11px;
  height: 11px;
  line-height: 18px;
  cursor: default;
  margin-top: -2px; }

.info-country {
  position: absolute;
  text-align: center;
  width: 11px;
  height: 11px;
  line-height: 18px;
  cursor: pointer;
  margin-left: 4px; }

.help-tip-1 {
  position: absolute;
  text-align: center;
  width: 11px;
  height: 11px;
  line-height: 18px;
  cursor: default;
  margin-top: -23px;
  margin-left: 0.75rem !important; }
  @media (max-width: 767px) {
    .help-tip-1 {
      margin-left: 0.25rem !important;
      margin-top: auto; } }

.help-img {
  padding-left: 1rem;
  padding-right: 1rem; }

.help-button {
  display: inline-flex;
  background-color: #aa1b17;
  border-radius: 5px;
  margin-top: 4px;
  padding: 0.4rem 1.5rem 0.4rem 2rem;
  position: fixed;
  bottom: 15px;
  right: -20px;
  min-height: 34px;
  z-index: 2; }
  .help-button button {
    width: auto;
    height: auto;
    border-radius: 5px;
    background-color: #aa1b17;
    border: none;
    cursor: pointer; }
    .help-button button span.help-text {
      font-family: "LatoWebBold", sans-serif;
      font-size: 1rem;
      font-weight: bold;
      letter-spacing: 0.5px;
      text-align: center;
      color: #ffffff; }
  .help-button ul {
    padding-left: 0px;
    margin-bottom: 0 !important; }

button.help-text-button {
  font-family: "LatoWeb", sans-serif;
  text-align: center;
  color: #ffffff;
  font-size: 0.75rem; }
  button.help-text-button a {
    border-radius: 5px;
    border: 1px solid #ffffff;
    padding: 0.4rem 2rem 0.4rem 2rem; }
    button.help-text-button a:hover {
      color: #aa1b17;
      background-color: #ffffff; }

.help-link {
  font-family: "LatoWeb", sans-serif;
  text-align: center;
  color: #ffffff;
  font-size: 0.75rem;
  border-radius: 5px;
  border: 1px solid #ffffff;
  padding: 0.4rem 2rem 0.4rem 2rem; }
  .help-link:hover {
    color: #aa1b17;
    background-color: #ffffff; }

path[data-info] {
  cursor: pointer; }

@media screen and (max-width: 990px) {
  a.publish-multinational {
    margin-bottom: 1rem; } }

@media screen and (max-width: 768px) {
  a.publish-multinational {
    float: left; } }

@media screen and (max-width: 600px) {
  a.publish-multinational {
    width: 100%; } }

.scrollProgram .dropdown-menu div:nth-last-child(1) {
  overflow-y: scroll !important; }

.show-for-print {
  display: none; }

.documents-not-available {
  color: #c21; }

@media (max-width: 576px) {
  .container {
    max-width: 576px; } }

@media (max-width: 768px) {
  .container {
    max-width: 768px; } }

@media (max-width: 1590px) {
  .footer-generali {
    margin-bottom: 50px; } }

/*#program-widget-broker-employee  {
  & > div {
    height: auto !important;
    width: 100% !important;
    max-height: 100vw;

    & > div {
      height: auto !important;
      width: 100% !important;
      canvas {
        position: relative !important;
        width: 100% !important;
        height: auto !important;
      }
    }
  }
}*/
#scroll-button {
  display: inline-block;
  background-color: #aa1b17;
  width: 50px;
  height: 35px;
  text-align: center;
  border-radius: 4px;
  position: fixed;
  bottom: 15px;
  right: 135px;
  transition: background-color .3s, opacity .5s, visibility .5s;
  opacity: 0;
  visibility: hidden;
  z-index: 1; }
  #scroll-button.show {
    opacity: 1;
    visibility: visible; }
  #scroll-button:hover {
    cursor: pointer;
    background-color: #aa1b17; }
  #scroll-button:active {
    background-color: #aa1b17; }
  #scroll-button .scroll-img {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem; }

.close {
  opacity: 1;
  cursor: pointer; }

.deductibles {
  padding: 0;
  margin: 0;
  list-style-type: none;
  position: relative; }
  .deductibles li {
    list-style-type: none;
    border-left: 2px solid #979797 !important;
    margin-left: 1em;
    background-color: #f9f8f5 !important;
    border-top: 0 !important;
    border-right: 0 !important;
    margin-bottom: 0 !important;
    width: 100% !important;
    padding-bottom: 5px !important;
    text-align: left !important; }
    @media (max-width: 767px) {
      .deductibles li {
        padding-bottom: 13px; } }
    .deductibles li div {
      padding-left: 2rem;
      position: relative; }
      .deductibles li div::before {
        content: '';
        position: absolute;
        top: 0;
        left: -2px;
        bottom: 50%;
        padding-right: 10px;
        border: 2px solid #979797;
        border-top: 0 none transparent;
        border-right: 0 none transparent; }
    .deductibles li:last-child {
      border-left: 2px solid transparent !important; }

.visible-md,
.visible-lg {
  display: block !important; }
  @media (max-width: 767px) {
    .visible-md,
    .visible-lg {
      display: none !important; } }

.visible-sm,
.visible-xs {
  display: none !important; }
  @media (max-width: 767px) {
    .visible-sm,
    .visible-xs {
      display: block !important; } }

@media (max-width: 767px) {
  #searchMps {
    font-size: 10px; }
    #searchMps td:nth-of-type(1):before {
      content: "INSURED"; }
    #searchMps td:nth-of-type(2):before {
      content: "YEAR"; }
    #searchMps td:nth-of-type(3):before {
      content: "TYPE"; }
    #searchMps td:nth-of-type(4):before {
      content: "PROGRAM"; }
    #searchMps td:nth-of-type(5):before {
      content: "CLIENT NAME"; }
    #searchMps td:nth-of-type(6):before {
      content: "COUNTRY"; }
  #employeeProgramsTable {
    font-size: 10px; }
    #employeeProgramsTable td:nth-of-type(2):before {
      content: "CLIENT NAME" !important; }
    #employeeProgramsTable td:nth-of-type(3):before {
      content: "PROGRAM" !important; }
    #employeeProgramsTable td:nth-of-type(4):before {
      content: "STATUS" !important; }
    #employeeProgramsTable td:nth-of-type(5):before {
      content: "COUNTRY" !important; }
    #employeeProgramsTable td:nth-of-type(6):before {
      content: "STATUS" !important; }
    #employeeProgramsTable td:nth-of-type(7):before {
      width: auto;
      content: "ACTIONS" !important; }
    #employeeProgramsTable td.btn {
      padding-left: 5px !important;
      margin-top: 5px;
      text-align: center; } }

@media (max-width: 767px) {
  #contactsTable {
    font-size: 10px; }
    #contactsTable td:nth-of-type(1):before {
      content: "CLIENT"; }
    #contactsTable td:nth-of-type(2):before {
      content: "PROGRAM"; }
    #contactsTable td:nth-of-type(3):before {
      content: "ROLE"; }
    #contactsTable td:nth-of-type(4):before {
      content: "COUNTRY"; }
    #contactsTable td:nth-of-type(5):before {
      content: "NAME"; }
    #contactsTable td:nth-of-type(6):before {
      content: "EMAIL"; }
  .pl-m-38 {
    padding-left: 38% !important; } }

.country-of-risk-details {
  font-size: 14px;
  font-weight: bold;
  color: #8e1230; }

.country-of-risk {
  font-size: 15px;
  font-weight: bold;
  color: #8e1230; }

/* Webfont: Lato-Black */
@font-face {
  font-family: 'LatoWebBlack';
  src: url("../fonts/Lato-Black.eot");
  /* IE9 Compat Modes */
  src: url("../fonts/Lato-Black.eot?#iefix") format("embedded-opentype"), url("../fonts/Lato-Black.woff2") format("woff2"), url("../fonts/Lato-Black.woff") format("woff"), url("../fonts/Lato-Black.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility; }

/* Webfont: Lato-Bold */
@font-face {
  font-family: 'LatoWebBold';
  src: url("../fonts/Lato-Bold.eot");
  /* IE9 Compat Modes */
  src: url("../fonts/Lato-Bold.eot?#iefix") format("embedded-opentype"), url("../fonts/Lato-Bold.woff2") format("woff2"), url("../fonts/Lato-Bold.woff") format("woff"), url("../fonts/Lato-Bold.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility; }

/* Webfont: Lato-Hairline */
@font-face {
  font-family: 'LatoWebHairline';
  src: url("../fonts/Lato-Hairline.eot");
  /* IE9 Compat Modes */
  src: url("../fonts/Lato-Hairline.eot?#iefix") format("embedded-opentype"), url("../fonts/Lato-Hairline.woff2") format("woff2"), url("../fonts/Lato-Hairline.woff") format("woff"), url("../fonts/Lato-Hairline.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility; }

/* Webfont: Lato-Heavy */
@font-face {
  font-family: 'LatoWebHeavy';
  src: url("../fonts/Lato-Heavy.eot");
  /* IE9 Compat Modes */
  src: url("../fonts/Lato-Heavy.eot?#iefix") format("embedded-opentype"), url("../fonts/Lato-Heavy.woff2") format("woff2"), url("../fonts/Lato-Heavy.woff") format("woff"), url("../fonts/Lato-Heavy.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility; }

/* Webfont: Lato-Light */
@font-face {
  font-family: 'LatoWebLight';
  src: url("../fonts/Lato-Light.eot");
  /* IE9 Compat Modes */
  src: url("../fonts/Lato-Light.eot?#iefix") format("embedded-opentype"), url("../fonts/Lato-Light.woff2") format("woff2"), url("../fonts/Lato-Light.woff") format("woff"), url("../fonts/Lato-Light.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility; }

/* Webfont: Lato-Medium */
@font-face {
  font-family: 'LatoWebMedium';
  src: url("../fonts/Lato-Medium.eot");
  /* IE9 Compat Modes */
  src: url("../fonts/Lato-Medium.eot?#iefix") format("embedded-opentype"), url("../fonts/Lato-Medium.woff2") format("woff2"), url("../fonts/Lato-Medium.woff") format("woff"), url("../fonts/Lato-Medium.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility; }

/* Webfont: Lato-Regular */
@font-face {
  font-family: 'LatoWeb';
  src: url("../fonts/Lato-Regular.eot");
  /* IE9 Compat Modes */
  src: url("../fonts/Lato-Regular.eot?#iefix") format("embedded-opentype"), url("../fonts/Lato-Regular.woff2") format("woff2"), url("../fonts/Lato-Regular.woff") format("woff"), url("../fonts/Lato-Regular.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility; }

/* Webfont: Lato-Semibold */
@font-face {
  font-family: 'LatoWebSemibold';
  src: url("../fonts/Lato-Semibold.eot");
  /* IE9 Compat Modes */
  src: url("../fonts/Lato-Semibold.eot?#iefix") format("embedded-opentype"), url("../fonts/Lato-Semibold.woff2") format("woff2"), url("../fonts/Lato-Semibold.woff") format("woff"), url("../fonts/Lato-Semibold.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility; }

/* Webfont: Lato-Thin */
@font-face {
  font-family: 'LatoWebThin';
  src: url("../fonts/Lato-Thin.eot");
  /* IE9 Compat Modes */
  src: url("../fonts/Lato-Thin.eot?#iefix") format("embedded-opentype"), url("../fonts/Lato-Thin.woff2") format("woff2"), url("../fonts/Lato-Thin.woff") format("woff"), url("../fonts/Lato-Thin.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility; }

/* Webfont: fontello */
@font-face {
  font-family: 'fontello';
  src: url("../fonts/fontello/fontello.eot?56278818");
  src: url("../fonts/fontello/fontello.eot?56278818#iefix") format("embedded-opentype"), url("../fonts/fontello/fontello.woff2?56278818") format("woff2"), url("../fonts/fontello/fontello.woff?56278818") format("woff"), url("../fonts/fontello/fontello.ttf?56278818") format("truetype"), url("../fonts/fontello/fontello.svg?56278818#fontello") format("svg");
  font-weight: normal;
  font-style: normal; }

.navbar {
  padding: 0; }
  @media (max-width: 767px) {
    .navbar.service-menu .nav {
      margin-top: 50px; } }
  .navbar.service-menu .nav-item {
    margin-left: 5px; }
    @media (max-width: 575px) {
      .navbar.service-menu .nav-item {
        margin: 0;
        width: 100%; } }
    .navbar.service-menu .nav-item a.dropdown-item {
      border: none;
      border-bottom: 3px solid #c21b17;
      color: #6f7072;
      border-bottom: 4px solid #c21b17;
      text-transform: uppercase;
      font-size: 1rem;
      letter-spacing: 0.5px;
      font-family: "LatoWebBold", sans-serif;
      height: 40px;
      line-height: 40px;
      display: table-cell;
      padding-top: 0;
      padding-bottom: 0; }
      .navbar.service-menu .nav-item a.dropdown-item.active {
        color: #FFF;
        background-color: #c21b17; }
      .navbar.service-menu .nav-item a.dropdown-item:hover, .navbar.service-menu .nav-item a.dropdown-item:active, .navbar.service-menu .nav-item a.dropdown-item:focus {
        color: #FFF;
        background-color: #c21b17; }

.logo {
  max-width: 115px;
  position: absolute;
  z-index: 1;
  padding: 10px 10px 10px 0; }

.main-menu .btn {
  background: transparent none;
  border-radius: 0;
  border-width: 2px;
  font-family: "LatoWebBold", sans-serif;
  min-width: 200px; }
  .main-menu .btn:active, .main-menu .btn:focus {
    box-shadow: none; }
  .main-menu .btn.active {
    color: #FFF; }
  @media (max-width: 767px) {
    .main-menu .btn {
      width: 100%; } }
  @media (min-width: 768px) {
    .main-menu .btn {
      border-bottom-width: 0; } }

.main-menu .btn-pro {
  border-color: #C2200C;
  color: #C2200C; }
  .main-menu .btn-pro.active {
    background-color: #C2200C; }
  .main-menu .btn-pro:hover {
    background-color: #C2200C;
    color: #FFF; }

.main-menu .btn-cas {
  border-color: #7C7B7B;
  color: #7C7B7B; }
  .main-menu .btn-cas.active {
    background-color: #7C7B7B; }
  .main-menu .btn-cas:hover {
    background-color: #7C7B7B;
    color: #FFF; }

.main-menu .btn-ove {
  border-color: #aa1b17;
  color: #aa1b17; }
  .main-menu .btn-ove.active {
    background-color: #aa1b17; }
  .main-menu .btn-ove:hover {
    background-color: #aa1b17;
    color: #FFF; }

.separator-line {
  border-bottom: 4px solid #aa1b17; }
  .separator-line.prp {
    border-color: #C2200C; }
  .separator-line.cas {
    border-color: #7C7B7B; }
  @media (max-width: 767px) {
    .separator-line {
      border-bottom-width: 0; } }

@media (max-width: 575px) {
  .offcanvas-collapse {
    position: fixed;
    top: 0;
    /* Height of navbar */
    padding-top: 0;
    bottom: 0;
    left: 100%;
    width: 100%;
    padding-right: 1rem;
    padding-left: 1rem;
    overflow-y: auto;
    visibility: hidden;
    background-color: #892034;
    transition-timing-function: ease-in-out;
    transition-duration: .3s;
    transition-property: left, visibility;
    z-index: 10; }
    .offcanvas-collapse.open {
      left: 0;
      visibility: visible; }
    .offcanvas-collapse .nav .nav-item a.dropdown-item {
      color: #FFF;
      width: 100%;
      display: inline-block; } }

.hamburger {
  padding: 5px 5px;
  display: inline-block;
  cursor: pointer;
  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible; }
  .hamburger:hover {
    opacity: 1; }
  .hamburger.is-active:hover {
    opacity: 1; }
  .hamburger.is-active .hamburger-inner,
  .hamburger.is-active .hamburger-inner::before,
  .hamburger.is-active .hamburger-inner::after {
    background-color: #FFF; }
  @media (min-width: 576px) {
    .hamburger {
      display: none; } }

.hamburger-box {
  margin-top: 5px;
  width: 30px;
  height: 24px;
  display: inline-block;
  position: relative; }

.hamburger-inner {
  display: block;
  top: 50%;
  margin-top: -2px; }
  .hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
    width: 30px;
    height: 4px;
    background-color: #892034;
    border-radius: 0px;
    position: absolute;
    transition-property: transform;
    transition-duration: 0.15s;
    transition-timing-function: ease; }
  .hamburger-inner::before, .hamburger-inner::after {
    content: "";
    display: block; }
  .hamburger-inner::before {
    top: -10px; }
  .hamburger-inner::after {
    bottom: -10px; }

.hamburger--collapse .hamburger-inner {
  top: auto;
  bottom: 0;
  transition-duration: 0.13s;
  transition-delay: 0.13s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  .hamburger--collapse .hamburger-inner::after {
    top: -20px;
    transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), opacity 0.1s linear; }
  .hamburger--collapse .hamburger-inner::before {
    transition: top 0.12s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19); }

.hamburger--collapse.is-active .hamburger-inner {
  transform: translate3d(0, -10px, 0) rotate(-45deg);
  transition-delay: 0.22s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  .hamburger--collapse.is-active .hamburger-inner::after {
    top: 0;
    opacity: 0;
    transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), opacity 0.1s 0.22s linear; }
  .hamburger--collapse.is-active .hamburger-inner::before {
    top: 0;
    transform: rotate(-90deg);
    transition: top 0.1s 0.16s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.25s cubic-bezier(0.215, 0.61, 0.355, 1); }

.info-box {
  margin-bottom: 4rem; }
  @media (min-width: 576px) {
    .info-box:last-child {
      margin-bottom: 0; } }
  .info-box.match-height {
    display: flex;
    flex-direction: column;
    justify-content: start; }
    .info-box.match-height span.value {
      margin-top: auto;
      line-height: 2.5rem;
      max-height: calc(5rem + 15px);
      overflow: hidden;
      position: relative; }
      .info-box.match-height span.value.ellipsis {
        padding-right: 1.2rem; }
        .info-box.match-height span.value.ellipsis::after {
          content: '...';
          position: absolute;
          right: 0;
          height: 2.5rem;
          background-color: #F4F2EE;
          width: 1.2rem;
          bottom: 0; }
    @media (max-width: 768px) {
      .info-box.match-height span.value {
        line-height: 1.5rem;
        max-height: calc(3rem + 15px);
        overflow: hidden;
        text-overflow: ellipsis; }
        .info-box.match-height span.value.ellipsis::after {
          height: 1.5rem;
          width: auto; } }
  .info-box label {
    font-size: 0.85rem;
    font-weight: 300;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    color: #6f7072; }
    .info-box label.tab {
      font-size: 0.9rem;
      color: #4a4a4a;
      text-transform: capitalize;
      font-family: "LatoWeb", sans-serif; }
  .info-box .local-policies {
    font-size: 1.55rem;
    font-family: "LatoWebBold", sans-serif;
    line-height: 1.18;
    text-align: left;
    color: #c21b17; }
  .info-box span.tab {
    border: none;
    font-size: 1rem;
    display: block;
    padding-top: 10px;
    color: #313131; }
    .info-box span.tab li {
      display: block;
      margin-bottom: 1rem;
      font-family: "LatoWebLight", sans-serif;
      color: #313131; }
  .info-box span.value {
    font-size: 1.7rem;
    font-weight: 300;
    color: #313131;
    display: block;
    border-bottom: 1px solid #AEAFB0;
    padding-bottom: 5px;
    padding-top: 10px; }
    .info-box span.value .list-group.list-group-flush {
      font-size: 1rem;
      line-height: 2; }
  .info-box span.amount {
    font-size: 1.8rem; }
    @media (max-width: 1024px) {
      .info-box span.amount {
        font-size: 1.4rem; } }
    @media (max-width: 767px) {
      .info-box span.amount.fixed-width {
        max-width: 110px;
        text-align: right;
        display: inline-block;
        min-width: 90px;
        margin-right: 10px; } }
  @media (max-width: 768px) {
    .info-box span.value {
      font-size: 1rem;
      font-weight: bold; }
      .info-box span.value span.amount {
        font-size: 1rem; } }

.jumbotron {
  background-color: #FFF; }
  .jumbotron h1 {
    color: #000; }

.panel {
  background-color: #F4F2EE;
  margin-top: 15px;
  padding: 30px 20px; }
  .panel a.show-hide {
    color: #BB2D21;
    text-transform: uppercase;
    font-size: 0.8rem;
    font-family: "LatoWebBold", sans-serif;
    cursor: pointer; }
    .panel a.show-hide:after {
      position: relative;
      content: '';
      width: 20px;
      height: 22px;
      background: url("../images/meno.svg") no-repeat 10px 0;
      display: inline-block;
      padding-left: 35px;
      vertical-align: middle; }
  .panel a.hide-show {
    color: #BB2D21;
    text-transform: uppercase;
    font-size: 0.8rem;
    font-family: "LatoWebBold", sans-serif;
    cursor: pointer; }
    .panel a.hide-show:after {
      position: relative;
      content: '';
      width: 20px;
      height: 22px;
      background: url("../images/show.svg") no-repeat 10px 0;
      display: inline-block;
      padding-left: 35px;
      vertical-align: middle; }
  .panel .border-right {
    border: 6px solid #ffffff !important; }
  .panel .border-left {
    border: 6px solid #ffffff !important; }

.doc-filter a.show-hide {
  color: #BB2D21;
  text-transform: uppercase;
  font-size: 0.8rem;
  font-family: "LatoWebBold", sans-serif;
  cursor: pointer; }
  .doc-filter a.show-hide:after {
    position: relative;
    content: '';
    width: 20px;
    height: 22px;
    background: url("../images/meno.svg") no-repeat 10px 0;
    display: inline-block;
    padding-left: 35px;
    vertical-align: middle; }

.doc-filter a.hide-show {
  color: #BB2D21;
  text-transform: uppercase;
  font-size: 0.8rem;
  font-family: "LatoWebBold", sans-serif;
  cursor: pointer; }
  .doc-filter a.hide-show:after {
    position: relative;
    content: '';
    width: 20px;
    height: 22px;
    background: url("../images/show.svg") no-repeat 10px 0;
    display: inline-block;
    padding-left: 35px;
    vertical-align: middle; }

footer {
  color: #FFF;
  background-color: #FFF;
  font-family: "LatoWeb", sans-serif;
  border-top: 2px solid #c12017; }
  footer .copyright {
    padding: 35px 0 10px 0; }
  footer .copyright-links ul {
    margin: 0; }
  footer .copyright-links li {
    margin-right: 30px !important;
    text-transform: uppercase; }
    @media (max-width: 575px) {
      footer .copyright-links li {
        display: block;
        text-align: center;
        margin-right: 0 !important; } }
  footer .footer__link {
    color: #000; }
    footer .footer__link:hover {
      text-decoration: underline !important; }
  footer .first-row {
    margin-top: 40px;
    padding-top: 0;
    border-top: 0;
    clear: both;
    width: 100%; }
    footer .first-row .logo-footer {
      float: right;
      position: relative;
      width: 143px;
      height: 20px; }
  footer .second-row {
    clear: both;
    display: inline-block;
    float: right;
    width: 100%;
    margin-top: 5px; }
    footer .second-row .copyright-text {
      float: right;
      border-top: 0;
      margin-top: 0;
      margin-bottom: 0;
      padding-top: 0;
      text-align: right;
      text-transform: uppercase;
      display: block;
      color: #333; }
      @media (max-width: 767px) {
        footer .second-row .copyright-text {
          border-top: 1px solid #c12017;
          float: none;
          padding-top: 20px;
          text-align: center; } }
      footer .second-row .copyright-text p {
        margin-bottom: 0; }
  footer .third-row {
    display: inline-block;
    width: 100%;
    margin-top: 5px;
    clear: both;
    float: right; }
    footer .third-row .comSiteLink {
      float: right;
      border-top: none;
      margin-top: 0;
      margin-bottom: 0;
      padding-top: 0;
      text-transform: uppercase; }
      @media (max-width: 767px) {
        footer .third-row .comSiteLink {
          float: none; } }
      footer .third-row .comSiteLink a {
        color: #c12017; }
  footer .first-row-mobile {
    clear: both;
    display: inline-block;
    width: 100%;
    margin-top: 5px;
    margin-bottom: 25px;
    padding-top: 10px; }
  footer .link-see-more {
    visibility: hidden;
    display: none !important; }
    @media (max-width: 767px) {
      footer .link-see-more {
        visibility: visible;
        display: inline-block !important; } }
    footer .link-see-more #accordion-link {
      padding: 8px;
      color: #fff !important;
      background-color: #c12017;
      border: 1px solid #fff; }
      @media (max-width: 767px) {
        footer .link-see-more #accordion-link.collapsed {
          color: #c12017 !important;
          background-color: #ffffff !important;
          border: 1px solid #c12017; } }
  footer .collapse {
    visibility: visible;
    display: block !important; }
    @media (max-width: 767px) {
      footer .collapse {
        display: none !important;
        visibility: hidden; }
        footer .collapse.show {
          display: block !important;
          visibility: visible; } }
    @media (max-width: 767px) {
      footer .collapse .first-row {
        border-top: 1px solid #c12017;
        margin-top: 25px;
        padding-top: 25px; } }

.dropdown {
  font-family: "LatoWeb", sans-serif; }
  .dropdown .mt-4 {
    margin-top: 1.5rem !important; }
  .dropdown p {
    font-size: 0.625rem;
    letter-spacing: 0.5px;
    text-align: left; }
  .dropdown .span {
    font-size: 0.8125rem; }
  .dropdown .btn-country {
    background: #aa1b17;
    color: #ffffff;
    font-size: large;
    border-radius: 5px;
    border: none; }
    .dropdown .btn-country:focus {
      outline: 0;
      box-shadow: none; }
  .dropdown .btn {
    background: #aa1b17;
    color: #ffffff;
    font-size: large;
    text-transform: uppercase;
    letter-spacing: 0.4px; }
    .dropdown .btn:focus {
      outline: 0;
      box-shadow: none; }
  .dropdown .dropdown-item {
    padding: 0.25rem;
    font-size: medium;
    text-transform: uppercase;
    letter-spacing: 0.4px; }
    .dropdown .dropdown-item.active, .dropdown .dropdown-item:active {
      color: #fff;
      text-decoration: none;
      background-color: #aa1b17; }
    .dropdown .dropdown-item:focus {
      outline: none !important; }
  .dropdown .dropdown-menu {
    margin: 0;
    padding: 0.2rem 0; }
    .dropdown .dropdown-menu.scrollable-menu {
      height: auto;
      max-height: 200px;
      overflow-x: hidden;
      padding: 4px; }
  .dropdown .meta-navigation-icon img {
    width: 28px;
    height: 28px; }
  .dropdown .dropdown-toggle.caret-right::after {
    position: absolute;
    right: 30px;
    top: 17px; }

.dropdown-menu a.download-item {
  color: #c21b17;
  font-size: 0.875rem; }

@media (max-width: 768px) {
  .dropdown .dropdown-item {
    font-size: small; }
  .dropdown .btn {
    font-size: small; } }

.download-panel {
  text-align: left;
  background-color: #efefef;
  border-radius: 4px;
  overflow: hidden;
  padding: 1.5rem 0.7rem 1rem 0; }
  .download-panel a {
    font-size: 0.856rem;
    line-height: 1.25;
    color: #6f7072; }

.info-download {
  text-align: left;
  margin: 1rem 0 1rem 1rem;
  font-size: 0.85rem;
  overflow: hidden;
  text-overflow: ellipsis; }
  .info-download label {
    font-size: 0.85rem;
    font-weight: 300;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    color: #6f7072; }
  .info-download span.value {
    font-size: 1.7rem;
    font-weight: 300;
    color: #313131;
    display: block;
    border-bottom: 1px solid #AEAFB0;
    padding-bottom: 5px;
    padding-top: 10px; }

.show img.select {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  transform: rotate(180deg); }

.show .dropdown-menu.download {
  border: solid 1px #c21b17 !important;
  border-top: none !important;
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
  margin: 0 !important;
  padding: 0.5rem 0.16rem 0.2rem !important;
  transform: none !important;
  width: calc(100% + 2px) !important;
  max-width: calc(100% + 2px) !important;
  left: -1px;
  display: block; }

.btn-outline-download {
  display: initial;
  position: relative;
  padding: 0.5rem 1rem 0.5rem 1rem !important;
  top: 2px !important; }
  .btn-outline-download.show {
    border-bottom: none !important;
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important; }

.dropdown-toggle::after {
  font-family: "fontello";
  content: "\f107"; }

.dropup .dropdown-toggle::after {
  font-family: "fontello";
  content: "\f106"; }

.bootstrap-select.dropdown.subtext small.text-muted {
  padding-left: 0;
  position: absolute;
  left: 0.7rem;
  line-height: 1rem;
  font-size: 0.7rem;
  color: #FFF !important;
  top: 2px; }

.bootstrap-select.dropdown.subtext .dropdown-menu li .filter-option-inner-inner {
  padding-top: 0.5rem; }

.bootstrap-select.dropdown.subtext .dropdown-menu li small.text-muted {
  font-size: 0.7rem;
  text-align: left;
  color: #6f7072 !important;
  position: absolute;
  left: 10px;
  top: 0.2rem;
  line-height: 1rem; }

.bootstrap-select.dropdown.subtext .dropdown-menu li a span.text {
  padding: 0.7rem 0.5rem 0.5rem 0.5rem; }

.bootstrap-select.dropdown.subtext .dropdown-menu li a.selected.active small.text-muted {
  color: #ffffff !important; }

.bootstrap-select.dropdown.subtext.producing .filter-option-inner-inner {
  padding-top: 0.5rem; }

.contact-us .bootstrap-select:not([class*="col-"]):not([class*="form-control"]):not(.input-group-btn) {
  max-width: 250px; }

.contact-us .filter-option-inner-inner {
  font-size: 0.625rem !important;
  margin-top: 7px; }

.contact-us .btn {
  padding: 0 0.375rem; }

.contact-us .dropdown-menu ul li span {
  font-size: 0.625rem !important; }

.btn-outline-primary, .btn-outline-property, .btn-outline-casualty {
  background-color: #FFF;
  font-family: "LatoWeb", sans-serif; }
  .btn-outline-primary:not(.disabled):not(:disabled):active, .btn-outline-primary:not(.disabled):not(:disabled):focus, .btn-outline-property:not(.disabled):not(:disabled):active, .btn-outline-property:not(.disabled):not(:disabled):focus, .btn-outline-casualty:not(.disabled):not(:disabled):active, .btn-outline-casualty:not(.disabled):not(:disabled):focus {
    box-shadow: none; }
  .btn-outline-primary:not(.disabled):not(:disabled).active:active, .btn-outline-primary:not(.disabled):not(:disabled).active:focus, .btn-outline-property:not(.disabled):not(:disabled).active:active, .btn-outline-property:not(.disabled):not(:disabled).active:focus, .btn-outline-casualty:not(.disabled):not(:disabled).active:active, .btn-outline-casualty:not(.disabled):not(:disabled).active:focus {
    box-shadow: none; }

.btn-outline-overview:not(:disabled):not(.disabled):active:focus, .btn-outline-overview:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-overview.dropdown-toggle:focus {
  box-shadow: none !important; }

.btn-link {
  font-family: "LatoWebBold", sans-serif;
  font-size: 0.9rem;
  font-weight: bold;
  color: #c21b17;
  text-transform: uppercase;
  text-decoration: none !important; }

#contactSearchInput {
  border-radius: 5px 0 0 5px !important;
  border: solid 2px #9b9b9b;
  width: 30rem;
  font-style: italic;
  color: #6f7072;
  padding-left: 0.5rem;
  margin-right: 2px; }

.reset-filter {
  border: solid 2px #707070;
  height: 40px;
  color: #707070;
  padding: 0 35px;
  background-color: #FFF; }
  .reset-filter:hover {
    color: #FFF;
    background-color: #707070; }
  .reset-filter:focus {
    box-shadow: none;
    border-color: #707070; }
  .reset-filter:active {
    box-shadow: none; }

.BackArrow {
  margin-right: 0.1rem;
  margin-bottom: 0.3rem; }

button:focus {
  outline: none; }

.video {
  margin-top: 15px; }
  .video iframe {
    border: 2px solid #6E6F71; }

.assistance p {
  font-size: 1.5rem;
  margin-top: 30px;
  margin-bottom: 40px; }

.tutorial-teaser h6 {
  font-size: 1.3rem; }

.tutorial-teaser p {
  font-size: 1.125rem;
  margin-bottom: 0;
  margin-top: 0;
  font-weight: 700; }

.accordion-country-contacts .collapse {
  border-bottom-left-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem; }

.accordion-country-contacts .card-contact {
  border: solid 0.1rem #b0b2b3;
  border-radius: 0.3rem; }
  .accordion-country-contacts .card-contact .card-header {
    cursor: pointer;
    border-bottom: none;
    border-top-left-radius: 0.3rem;
    border-top-right-radius: 0.3rem;
    border-bottom-left-radius: 0.3rem;
    border-bottom-right-radius: 0.3rem; }
    .accordion-country-contacts .card-contact .card-header img {
      margin-right: 1rem;
      transition: all 0.5s; }

.card {
  border-radius: 0;
  border-width: 0;
  border-bottom: 0.1rem solid #9b9b9b; }
  .card .card-body {
    flex: 1 1 auto;
    padding: 0.6rem; }
    .card .card-body p.card-text {
      font-size: 1rem;
      font-family: "LatoWeb", sans-serif;
      color: #4a4a4a; }
    .card .card-body label {
      color: #707070;
      font-family: "LatoWebBold", sans-serif; }
  .card.master {
    background-color: #F7C1BE;
    border-bottom-color: #AC2009; }
    .card.master .card-body label {
      color: #AC2009;
      cursor: pointer; }

.accordion .card-faq {
  border-radius: 0.3rem;
  border: solid 0.1rem #b0b2b3; }
  .accordion .card-faq .card-header {
    background-color: #e7e2d8;
    padding: 0.2rem;
    border-bottom: 0;
    border-bottom-right-radius: 0rem;
    border-bottom-left-radius: 0rem;
    border-top-right-radius: 0.3rem;
    border-top-left-radius: 0.3rem;
    cursor: pointer; }
    .accordion .card-faq .card-header a img {
      margin-right: 1.5rem;
      transition: all 0.5s; }
    .accordion .card-faq .card-header a.faq h6 {
      font-family: "LatoWeb", sans-serif;
      font-size: 1.1rem;
      letter-spacing: 0.02rem;
      text-align: left;
      color: #4a4a4a;
      margin-left: 1rem; }
  .accordion .card-faq .card-body {
    font-family: "LatoWebLight", sans-serif;
    line-height: 1.38;
    font-size: 1.1rem;
    letter-spacing: 0.02rem;
    color: #4a4a4a;
    padding: 2rem 3rem; }

.card-header.active a img,
a .card-header.active img {
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  transform: rotate(90deg);
  transition: all 0.5s; }

.tab-container {
  height: 100%;
  overflow: hidden; }
  @media (max-width: 767px) {
    .tab-container ul:not(.pagination) {
      width: 100% !important;
      padding: 0 !important; } }
  .tab-container ul:not(.pagination) li {
    width: 166px;
    text-transform: uppercase;
    background-color: #e8e6e1;
    border: solid 2px #9b9b9b;
    border-bottom: none;
    font-size: 0.9rem;
    text-align: center;
    margin-right: 0.3rem; }
    @media (max-width: 767px) {
      .tab-container ul:not(.pagination) li {
        margin-right: 0; } }
    .tab-container ul:not(.pagination) li.active {
      background-color: #f9f8f5; }
    .tab-container ul:not(.pagination) li a {
      font-weight: bold; }
      .tab-container ul:not(.pagination) li a.nav-link {
        padding: 0.5rem; }
        .tab-container ul:not(.pagination) li a.nav-link.active {
          background-color: #f9f8f5;
          margin-left: 1px;
          margin-top: 1px; }
      .tab-container ul:not(.pagination) li a:hover {
        color: #c21b17; }
  .tab-container .nav-tabs {
    padding: 0 5px; }
    .tab-container .nav-tabs .nav-link {
      border-radius: 0;
      border-width: 0;
      padding-left: 0;
      padding-right: 0; }

.tab-content {
  height: auto;
  background-color: #f9f8f5;
  border-top: solid 2px #9b9b9b; }

.tab-exposure > .tab-pane {
  display: none; }

.tab-exposure > .active {
  display: block; }

.exposure-col {
  border: 1px solid #a7495f;
  border-right: none;
  height: auto;
  max-width: 100%; }

.tab-exposure {
  border: 1px solid #aa1b17;
  margin-right: 1rem; }

.tab-exposures .nav-tabs {
  border-bottom: none; }
  .tab-exposures .nav-tabs .nav-link {
    border-radius: 0;
    border-width: 0;
    padding-left: 0;
    padding-right: 0;
    font-size: 1.7rem;
    font-weight: 300;
    color: #313131;
    display: block;
    border-bottom: 1px solid #AEAFB0;
    padding-top: 1rem; }

.tab-exposures ul li {
  width: 100%; }
  .tab-exposures ul li a {
    font-weight: bold; }
    .tab-exposures ul li a.nav-link {
      padding: 0.5rem;
      width: 93%; }
      .tab-exposures ul li a.nav-link.active {
        border-bottom: 1px solid #aa1b17;
        background-color: #F4F2EE;
        width: 100%; }
    .tab-exposures ul li a:hover {
      color: #bbbbbb; }

.accordion-country-contacts {
  background-color: #f6f4f0; }
  .accordion-country-contacts .card {
    background-color: #f6f4f0; }
  .accordion-country-contacts .card-header {
    background-color: #e7e2d8;
    border-radius: 0.3rem; }
    .accordion-country-contacts .card-header h5 a {
      font-size: 1.25rem;
      letter-spacing: 0.02rem;
      color: #4a4a4a; }
  .accordion-country-contacts .collapse {
    background-color: #f6f4f0; }

.broker-panel {
  background-color: #f6f4f0;
  padding: 2rem 2rem 0rem 2rem; }
  .broker-panel .btn.dropdown-toggle {
    /*padding: 5px 187px 5px 15px;*/ }
  .broker-panel table {
    width: 100%; }
  .broker-panel thead {
    background-color: #dadada;
    font-size: 0.9rem;
    height: 40px; }
    .broker-panel thead th {
      height: 40px; }
  .broker-panel tbody tr td {
    padding-top: 1rem;
    padding-right: 10px; }
  .broker-panel tbody tr td:first-child {
    width: 25px; }
  .broker-panel tbody tr td:last-child {
    width: 25px; }
  @media (min-width: 768px) {
    .broker-panel table#employeeProgramsTable tbody tr td:last-child {
      width: 280px !important; } }

.btn-download-csv {
  padding: 3px 20px 3px 15px !important;
  background-color: #FFF;
  color: #892034;
  border: 1px solid #892034;
  box-shadow: none !important;
  font-weight: bold;
  font-size: 0.9rem; }

.word-preline {
  white-space: pre-line; }

/**
  *    datatables style
 */
.no-sort::after,
.no-sort::before {
  display: none !important; }

#employeeProgramsTable_info,
#brokerProgramsTable_info {
  display: none !important; }

#employeeProgramsTable_filter,
#brokerProgramsTable_filter {
  display: none !important; }

.publishButtonGray {
  color: #6E6F71 !important;
  border-color: #6E6F71;
  width: 70px;
  margin-right: 32px; }
  .publishButtonGray:hover {
    color: #6E6F71 !important;
    border-color: #6E6F71;
    background-color: #ffffff; }

.publishButton {
  width: 70px;
  margin-right: 32px; }

@media (max-width: 767px) {
  thead {
    height: 0 !important;
    background-color: #f6f4f0; }
  .sm-none {
    visibility: hidden; }
  #brokerProgramsTable {
    font-size: 10px; }
  table, thead, tbody, th, td, tr {
    display: block; }
  /* Hide table headers (but not display: none;, for accessibility) */
  thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px; }
  tr {
    margin: 0 0 1rem 0; }
  tr:nth-child(odd) {
    background: #ccc; }
  td {
    /* Behave  like a "row" */
    border: none;
    border-bottom: 1px solid #eee;
    position: relative;
    padding-left: 50% !important;
    padding-top: 0 !important; }
  td:before {
    /* Now like a table header */
    position: absolute;
    /* Top/left values mimic padding */
    top: 0;
    left: 6px;
    width: 45%;
    padding-right: 10px;
    white-space: nowrap; }
  #brokerProgramsTable td:nth-of-type(2):before {
    content: "CLIENT NAME"; }
  #brokerProgramsTable td:nth-of-type(3):before {
    content: "PROGRAM"; }
  #brokerProgramsTable td:nth-of-type(4):before {
    content: "INCEPTION DATE"; }
  #brokerProgramsTable td:nth-of-type(5):before {
    content: "POLICIES ISSUED"; }
  #brokerProgramsTable td:nth-of-type(6):before {
    content: "PREMIUM COLLECTED"; } }

.search-input {
  border-radius: 5px 0 0 5px;
  border: solid 2px #9b9b9b;
  height: calc(2.25rem + 2px);
  display: block;
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  margin-bottom: 0;
  margin-right: 2px;
  font-style: italic;
  font-size: 1rem;
  padding: 1rem; }
  .search-input:focus {
    outline-color: #FFF; }

.search-button {
  width: auto;
  height: calc(2.25rem + 2px);
  border-radius: 0 5px 5px 0 !important;
  border: solid 2px #aa1b17;
  background-color: #FFF;
  color: #aa1b17; }
  .search-button:focus {
    box-shadow: none; }
  @media (min-width: 768px) {
    .search-button {
      width: 165px; } }

.btn-outline-Admitted {
  border: solid 1px #c21b17;
  color: #c21b17;
  background-color: #ffffff;
  font-family: "LatoWeb", sans-serif; }
  .btn-outline-Admitted:hover {
    color: #ffffff;
    background-color: #c21b17;
    border-color: #c21b17; }
  .btn-outline-Admitted:focus, .btn-outline-Admitted.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 100, 100, 0.65); }
  .btn-outline-Admitted.disabled, .btn-outline-Admitted:disabled {
    color: #c21b17;
    background-color: transparent; }
  .btn-outline-Admitted:not(:disabled):not(.disabled):active, .btn-outline-Admitted:not(:disabled):not(.disabled).active,
  .show > .btn-outline-Admitted.dropdown-toggle {
    color: #fff;
    background-color: #c21b17;
    border-color: #c21b17; }
  .btn-outline-Admitted:not(:disabled):not(.disabled):active:focus, .btn-outline-Admitted:not(:disabled):not(.disabled).active:focus,
  .show > .btn-outline-Admitted.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 100, 100, 0.65); }

.btn-outline-All {
  border: solid 1px #d0b09f;
  color: #d0b09f;
  background-color: #ffffff;
  font-family: "LatoWeb", sans-serif; }
  .btn-outline-All:hover {
    color: #ffffff;
    background-color: #d0b09f;
    border-color: #d0b09f; }
  .btn-outline-All:focus, .btn-outline-All.focus {
    box-shadow: 0 0 0 0.2rem rgba(109, 78, 67, 0.67); }
  .btn-outline-All.disabled, .btn-outline-All:disabled {
    color: #d0b09f;
    background-color: transparent; }
  .btn-outline-All:not(:disabled):not(.disabled):active, .btn-outline-All:not(:disabled):not(.disabled).active,
  .show > .btn-outline-All.dropdown-toggle {
    color: #fff;
    background-color: #d0b09f;
    border-color: #d0b09f; }
  .btn-outline-All:not(:disabled):not(.disabled):active:focus, .btn-outline-All:not(:disabled):not(.disabled).active:focus,
  .show > .btn-outline-All.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(109, 78, 67, 0.67); }

.btn-outline-FreedomOfService {
  border: solid 1px #80b04c;
  color: #80b04c;
  background-color: #ffffff;
  font-family: "LatoWeb", sans-serif; }
  .btn-outline-FreedomOfService:hover {
    color: #ffffff;
    background-color: #80b04c;
    border-color: #80b04c; }
  .btn-outline-FreedomOfService:focus, .btn-outline-FreedomOfService.focus {
    box-shadow: 0 0 0 0.2rem rgba(128, 176, 76, 0.65); }
  .btn-outline-FreedomOfService.disabled, .btn-outline-FreedomOfService:disabled {
    color: #80b04c;
    background-color: transparent; }
  .btn-outline-FreedomOfService:not(:disabled):not(.disabled):active, .btn-outline-FreedomOfService:not(:disabled):not(.disabled).active,
  .show > .btn-outline-FreedomOfService.dropdown-toggle {
    color: #fff;
    background-color: #80b04c;
    border-color: #80b04c; }
  .btn-outline-FreedomOfService:not(:disabled):not(.disabled):active:focus, .btn-outline-FreedomOfService:not(:disabled):not(.disabled).active:focus,
  .show > .btn-outline-FreedomOfService.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(128, 176, 76, 0.65); }

.btn-outline-NonAdmitted {
  border: solid 1px #7e43e3;
  color: #7e43e3;
  background-color: #ffffff;
  font-family: "LatoWeb", sans-serif; }
  .btn-outline-NonAdmitted:hover {
    color: #ffffff;
    background-color: #7e43e3;
    border-color: #7e43e3; }
  .btn-outline-NonAdmitted:focus, .btn-outline-NonAdmitted.focus {
    box-shadow: 0 0 0 0.2rem rgba(40, 40, 99, 0.65); }
  .btn-outline-NonAdmitted.disabled, .btn-outline-NonAdmitted:disabled {
    color: #7e43e3;
    background-color: transparent; }
  .btn-outline-NonAdmitted:not(:disabled):not(.disabled):active, .btn-outline-NonAdmitted:not(:disabled):not(.disabled).active,
  .show > .btn-outline-NonAdmitted.dropdown-toggle {
    color: #fff;
    background-color: #7e43e3;
    border-color: #7e43e3; }
  .btn-outline-NonAdmitted:not(:disabled):not(.disabled):active:focus, .btn-outline-NonAdmitted:not(:disabled):not(.disabled).active:focus,
  .show > .btn-outline-NonAdmitted.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(40, 40, 99, 0.65); }

.btn-outline-downloadMap {
  border: solid 1px #c21b17;
  color: #c21b17;
  background-color: #ffffff;
  font-family: "LatoWeb", sans-serif; }
  .btn-outline-downloadMap:hover {
    color: #ffffff;
    background-color: #c21b17;
    border-color: #c21b17; }

.btn-outline-download {
  border: solid 1px #c21b17;
  color: #c21b17;
  background-color: #ffffff;
  font-family: "LatoWeb", sans-serif;
  margin-right: 1rem;
  padding: 0.3rem 1rem 0.3rem 1rem; }

.map-content {
  background-color: #F4F2EE;
  position: relative; }
  .map-content > .tab-pane {
    display: none; }
  .map-content > .active {
    display: block; }
  .map-content .embed-responsive-item .map {
    position: relative;
    height: 100%;
    width: 100%;
    max-width: 700px;
    top: 0;
    bottom: 0;
    margin: 0 auto; }

.map-tabs {
  margin: 20px 0; }
  .map-tabs .map-link {
    display: flex;
    font-size: 1rem;
    font-family: "LatoWebBold", sans-serif; }
    .map-tabs .map-link .rcorner {
      border-radius: 50%;
      width: 24px;
      height: 24px;
      border: solid 2px #979797;
      padding-right: 5px; }
      .map-tabs .map-link .rcorner img {
        display: none;
        position: relative;
        top: -2px;
        left: -1px; }
    .map-tabs .map-link.active span {
      color: #aa1b17; }
    .map-tabs .map-link.active span.rec {
      color: #79a748; }
    .map-tabs .map-link.active .rcorner {
      border-color: transparent; }
      .map-tabs .map-link.active .rcorner img {
        display: inline-block; }
    .map-tabs .map-link span {
      margin-left: 10px;
      margin-right: 25px;
      margin-top: 2px;
      text-transform: uppercase;
      color: #6f7072; }

.modalMap {
  position: absolute;
  top: 0;
  right: 15px;
  bottom: 0;
  width: 30%;
  z-index: 1;
  display: none;
  overflow: hidden;
  outline: 0;
  padding: 0;
  overflow-y: auto; }
  @media (max-width: 991px) {
    .modalMap {
      width: 50%; } }
  @media (max-width: 576px) {
    .modalMap {
      width: 100%; } }

.modalMap-content {
  background-color: #faf9f6;
  margin: auto;
  padding: 20px;
  width: 100%;
  min-height: calc(100% - 24px);
  float: right; }

.overviewMapPopup {
  position: relative;
  display: flex;
  flex-direction: column;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
  padding: 10px; }
  .overviewMapPopup div:first-child {
    margin-bottom: 10px !important;
    font-size: 14px !important;
    font-weight: bold !important; }
  .overviewMapPopup .btn-outline-property {
    margin-bottom: 10px !important; }

#zoomInfo {
  align-self: center;
  padding-left: 6rem; }

span.mark-map {
  display: inline-block;
  background-image: url(../images/pin-white.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 1rem;
  border-radius: 100%;
  width: 30px;
  height: 30px;
  margin-right: 1rem; }

.coordinates {
  font-family: "LatoWeb", sans-serif;
  font-size: 1rem;
  color: #6f7072; }

.pin-FreedomOfService {
  background-color: #80b04c; }

.pin-NonAdmitted {
  background-color: #7e43e3; }

.pin-Admitted {
  background-color: #c21b17; }

#zoom-info {
  padding-top: 2px;
  margin-left: 10px;
  display: inline; }

.scroll-info {
  display: none;
  font-size: 0.9rem;
  color: #aa1b17;
  position: relative;
  top: 20%;
  font-family: "LatoWebBold", sans-serif; }

.zoom-overlay {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.05);
  display: none;
  text-align: center; }

.scroll-inactive .scroll-info {
  display: inline; }

.scroll-inactive .zoom-overlay {
  display: block;
  z-index: 1050; }

.zoom-button {
  margin-right: 5px; }

.jumbotron {
  padding: 2rem;
  margin-bottom: 0; }

.login-link {
  color: #aa1b17;
  font-weight: bold;
  padding-top: 12px; }
  @media (max-width: 767px) {
    .login-link {
      padding-right: 0;
      display: none; } }

.login-link-mobile {
  display: none; }
  @media (max-width: 767px) {
    .login-link-mobile {
      display: block; } }
  @media (max-width: 767px) {
    .login-link-mobile img {
      width: 35px;
      height: 25px; } }

.drop-content {
  top: 42px !important;
  right: 0 !important;
  left: auto !important;
  transform: none !important;
  width: 300px !important; }
  @media screen and (max-width: 355px) {
    .drop-content {
      right: -45px !important; } }

.user-dropdown {
  border: 2px solid #aa1b17;
  background-color: #f6f4f0; }
  .user-dropdown .user-text {
    color: #707070;
    padding-bottom: 0; }
  .user-dropdown .position-text {
    color: #6f7072;
    font-size: 0.75rem; }
  .user-dropdown .user-company,
  .user-dropdown .user-email,
  .user-dropdown .user-phone-number {
    color: #6f7072; }
  .user-dropdown .email-user-image {
    padding-left: 36px;
    height: 25px; }

.alerts-dropdown {
  margin-right: -104px !important; }

/*!
 * Bootstrap-select v1.13.3 (https://developer.snapappointments.com/bootstrap-select)
 *
 * Copyright 2012-2018 SnapAppointments, LLC
 * Licensed under MIT (https://github.com/snapappointments/bootstrap-select/blob/master/LICENSE)
 */
select.bs-select-hidden,
.bootstrap-select > select.bs-select-hidden,
select.selectpicker {
  display: none !important; }

.bootstrap-select {
  width: 220px \0;
  /*IE9 and below*/ }

.bootstrap-select > .dropdown-toggle {
  position: relative;
  width: 100%;
  z-index: 1;
  text-align: right;
  white-space: nowrap; }

.bootstrap-select > .dropdown-toggle.bs-placeholder,
.bootstrap-select > .dropdown-toggle.bs-placeholder:hover,
.bootstrap-select > .dropdown-toggle.bs-placeholder:focus,
.bootstrap-select > .dropdown-toggle.bs-placeholder:active {
  color: #FFF; }

.bootstrap-select > .dropdown-toggle.bs-placeholder.btn-primary,
.bootstrap-select > .dropdown-toggle.bs-placeholder.btn-secondary,
.bootstrap-select > .dropdown-toggle.bs-placeholder.btn-success,
.bootstrap-select > .dropdown-toggle.bs-placeholder.btn-danger,
.bootstrap-select > .dropdown-toggle.bs-placeholder.btn-info,
.bootstrap-select > .dropdown-toggle.bs-placeholder.btn-dark,
.bootstrap-select > .dropdown-toggle.bs-placeholder.btn-primary:hover,
.bootstrap-select > .dropdown-toggle.bs-placeholder.btn-secondary:hover,
.bootstrap-select > .dropdown-toggle.bs-placeholder.btn-success:hover,
.bootstrap-select > .dropdown-toggle.bs-placeholder.btn-danger:hover,
.bootstrap-select > .dropdown-toggle.bs-placeholder.btn-info:hover,
.bootstrap-select > .dropdown-toggle.bs-placeholder.btn-dark:hover,
.bootstrap-select > .dropdown-toggle.bs-placeholder.btn-primary:focus,
.bootstrap-select > .dropdown-toggle.bs-placeholder.btn-secondary:focus,
.bootstrap-select > .dropdown-toggle.bs-placeholder.btn-success:focus,
.bootstrap-select > .dropdown-toggle.bs-placeholder.btn-danger:focus,
.bootstrap-select > .dropdown-toggle.bs-placeholder.btn-info:focus,
.bootstrap-select > .dropdown-toggle.bs-placeholder.btn-dark:focus,
.bootstrap-select > .dropdown-toggle.bs-placeholder.btn-primary:active,
.bootstrap-select > .dropdown-toggle.bs-placeholder.btn-secondary:active,
.bootstrap-select > .dropdown-toggle.bs-placeholder.btn-success:active,
.bootstrap-select > .dropdown-toggle.bs-placeholder.btn-danger:active,
.bootstrap-select > .dropdown-toggle.bs-placeholder.btn-info:active,
.bootstrap-select > .dropdown-toggle.bs-placeholder.btn-dark:active {
  color: rgba(255, 255, 255, 0.5); }

.bootstrap-select > select {
  position: absolute !important;
  bottom: 0;
  left: 50%;
  display: block !important;
  width: 0.5px !important;
  height: 100% !important;
  padding: 0 !important;
  opacity: 0 !important;
  border: none; }

.bootstrap-select > select.mobile-device {
  top: 0;
  left: 0;
  display: block !important;
  width: 100% !important;
  z-index: 2; }

.has-error .bootstrap-select .dropdown-toggle,
.error .bootstrap-select .dropdown-toggle,
.bootstrap-select.is-invalid .dropdown-toggle,
.was-validated .bootstrap-select .selectpicker:invalid + .dropdown-toggle {
  border-color: #b94a48; }

.bootstrap-select.is-valid .dropdown-toggle,
.was-validated .bootstrap-select .selectpicker:valid + .dropdown-toggle {
  border-color: #28a745; }

.bootstrap-select.fit-width {
  width: auto !important; }

.bootstrap-select:not([class*="col-"]):not([class*="form-control"]):not(.input-group-btn) {
  max-width: 220px;
  width: 100%; }
  @media (max-width: 767px) {
    .bootstrap-select:not([class*="col-"]):not([class*="form-control"]):not(.input-group-btn) {
      max-width: none; } }

.bootstrap-select > select.mobile-device:focus + .dropdown-toggle,
.bootstrap-select .dropdown-toggle:focus {
  outline: none !important;
  outline-offset: -2px; }

.bootstrap-select.form-control {
  margin-bottom: 0;
  padding: 0;
  border: none; }

:not(.input-group) > .bootstrap-select.form-control:not([class*="col-"]) {
  width: 100%; }

.bootstrap-select.form-control.input-group-btn {
  z-index: auto; }

.bootstrap-select.form-control.input-group-btn:not(:first-child):not(:last-child) > .btn {
  border-radius: 0; }

.bootstrap-select:not(.input-group-btn),
.bootstrap-select[class*="col-"] {
  float: none;
  display: inline-block;
  margin-left: 0; }

.bootstrap-select.dropdown-menu-right,
.bootstrap-select[class*="col-"].dropdown-menu-right,
.row .bootstrap-select[class*="col-"].dropdown-menu-right {
  float: right; }

.form-inline .bootstrap-select,
.form-horizontal .bootstrap-select,
.form-group .bootstrap-select {
  margin-bottom: 0; }

.form-group-lg .bootstrap-select.form-control,
.form-group-sm .bootstrap-select.form-control {
  padding: 0; }

.form-group-lg .bootstrap-select.form-control .dropdown-toggle,
.form-group-sm .bootstrap-select.form-control .dropdown-toggle {
  height: 100%;
  font-size: inherit;
  line-height: inherit;
  border-radius: inherit; }

.bootstrap-select.form-control-sm .dropdown-toggle,
.bootstrap-select.form-control-lg .dropdown-toggle {
  font-size: inherit;
  line-height: inherit;
  border-radius: inherit; }

.bootstrap-select.form-control-sm .dropdown-toggle {
  padding: 0.25rem 0.5rem; }

.bootstrap-select.form-control-lg .dropdown-toggle {
  padding: 0.5rem 1rem; }

.form-inline .bootstrap-select .form-control {
  width: 100%; }

.bootstrap-select.disabled,
.bootstrap-select > .disabled {
  cursor: not-allowed; }

.bootstrap-select.disabled:focus,
.bootstrap-select > .disabled:focus {
  outline: none !important; }

.bootstrap-select.bs-container {
  position: absolute;
  top: 0;
  left: 0;
  height: 0 !important;
  padding: 0 !important; }

.bootstrap-select.bs-container .dropdown-menu {
  z-index: 1060; }

.bootstrap-select .dropdown-toggle:before {
  content: '';
  display: inline-block; }

.bootstrap-select .dropdown-toggle .filter-option {
  position: absolute;
  top: 0;
  left: 0;
  padding-top: inherit;
  padding-right: inherit;
  padding-bottom: inherit;
  padding-left: inherit;
  height: 100%;
  width: 100%;
  text-align: left; }

.bootstrap-select .dropdown-toggle .filter-option-inner {
  padding-right: inherit; }

.bootstrap-select .dropdown-toggle .filter-option-inner-inner {
  overflow: hidden; }

.bootstrap-select .dropdown-toggle .caret {
  position: absolute;
  top: 50%;
  right: 12px;
  margin-top: -2px;
  vertical-align: middle; }

.input-group .bootstrap-select.form-control .dropdown-toggle {
  border-radius: inherit; }

.bootstrap-select[class*="col-"] .dropdown-toggle {
  width: 100%; }

.bootstrap-select .dropdown-menu {
  min-width: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

.bootstrap-select .dropdown-menu > .inner:focus {
  outline: none !important; }

.bootstrap-select .dropdown-menu.inner {
  position: static;
  float: none;
  border: 0;
  padding: 0;
  margin: 0;
  border-radius: 0;
  -webkit-box-shadow: none;
  box-shadow: none; }

.bootstrap-select .dropdown-menu li {
  position: relative; }

.bootstrap-select .dropdown-menu li.active small {
  color: rgba(255, 255, 255, 0.5) !important; }

.bootstrap-select .dropdown-menu li.disabled a {
  cursor: not-allowed; }

.bootstrap-select .dropdown-menu li a {
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.bootstrap-select .dropdown-menu li a.opt {
  position: relative;
  padding-left: 2.25em; }

.bootstrap-select .dropdown-menu li a span.check-mark {
  display: none; }

.bootstrap-select .dropdown-menu li a span.text {
  display: inline-block; }

.bootstrap-select .dropdown-menu li small {
  padding-left: 0.5em; }

.bootstrap-select .dropdown-menu .notify {
  position: absolute;
  bottom: 5px;
  width: 96%;
  margin: 0 2%;
  min-height: 26px;
  padding: 3px 5px;
  background: #f5f5f5;
  border: 1px solid #e3e3e3;
  -webkit-box-shadow: none;
  box-shadow: none;
  pointer-events: none;
  opacity: 0.9;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

.bootstrap-select .no-results {
  padding: 3px;
  background: #f5f5f5;
  margin: 0 5px;
  white-space: nowrap; }

.bootstrap-select.fit-width .dropdown-toggle .filter-option {
  position: static;
  display: inline;
  padding: 0; }

.bootstrap-select.fit-width .dropdown-toggle .filter-option-inner,
.bootstrap-select.fit-width .dropdown-toggle .filter-option-inner-inner {
  display: inline; }

.bootstrap-select.fit-width .dropdown-toggle .caret {
  position: static;
  top: auto;
  margin-top: -1px; }

.bootstrap-select.show-tick .dropdown-menu .selected span.check-mark {
  position: absolute;
  display: inline-block;
  right: 15px;
  top: 5px; }

.bootstrap-select.show-tick .dropdown-menu li a span.text {
  margin-right: 34px; }

.bootstrap-select .bs-ok-default:after {
  content: '';
  display: block;
  width: 0.5em;
  height: 1em;
  border-style: solid;
  border-width: 0 0.26em 0.26em 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg); }

.bootstrap-select.show-menu-arrow.open > .dropdown-toggle,
.bootstrap-select.show-menu-arrow.show > .dropdown-toggle {
  z-index: 1061; }

.bootstrap-select.show-menu-arrow .dropdown-toggle .filter-option:before {
  content: '';
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 7px solid rgba(204, 204, 204, 0.2);
  position: absolute;
  bottom: -4px;
  left: 9px;
  display: none; }

.bootstrap-select.show-menu-arrow .dropdown-toggle .filter-option:after {
  content: '';
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 6px solid white;
  position: absolute;
  bottom: -4px;
  left: 10px;
  display: none; }

.bootstrap-select.show-menu-arrow.dropup .dropdown-toggle .filter-option:before {
  bottom: auto;
  top: -4px;
  border-top: 7px solid rgba(204, 204, 204, 0.2);
  border-bottom: 0; }

.bootstrap-select.show-menu-arrow.dropup .dropdown-toggle .filter-option:after {
  bottom: auto;
  top: -4px;
  border-top: 6px solid white;
  border-bottom: 0; }

.bootstrap-select.show-menu-arrow.pull-right .dropdown-toggle .filter-option:before {
  right: 12px;
  left: auto; }

.bootstrap-select.show-menu-arrow.pull-right .dropdown-toggle .filter-option:after {
  right: 13px;
  left: auto; }

.bootstrap-select.show-menu-arrow.open > .dropdown-toggle .filter-option:before,
.bootstrap-select.show-menu-arrow.show > .dropdown-toggle .filter-option:before,
.bootstrap-select.show-menu-arrow.open > .dropdown-toggle .filter-option:after,
.bootstrap-select.show-menu-arrow.show > .dropdown-toggle .filter-option:after {
  display: block; }

.bs-searchbox,
.bs-actionsbox,
.bs-donebutton {
  padding: 4px 8px; }

.bs-actionsbox {
  width: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

.bs-actionsbox .btn-group button {
  width: 50%; }

.bs-donebutton {
  float: left;
  width: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

.bs-donebutton .btn-group button {
  width: 100%; }

.bs-searchbox + .bs-actionsbox {
  padding: 0 8px 4px; }

.bs-searchbox .form-control {
  margin-bottom: 0;
  width: 100%;
  float: none; }

.bootstrap-select.status:not([class*="col-"]):not([class*="form-control"]):not(.input-group-btn) {
  max-width: 170px;
  width: 100%; }

.bootstrap-select.empty {
  cursor: default; }
  .bootstrap-select.empty .dropdown-toggle {
    cursor: default; }
    .bootstrap-select.empty .dropdown-toggle::after {
      display: none; }
  .bootstrap-select.empty .dropdown-menu,
  .bootstrap-select.empty .dropdown-menu.show {
    display: none !important; }

.btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active,
.show > .btn-light.dropdown-toggle {
  background-color: #aa1b17;
  color: #FFF; }

.bootstrap-select .dropdown-menu li.disabled {
  display: none !important; }

.bootstrap-select.filter .dropdown-menu {
  min-width: 100% !important; }

@media (min-width: 576px) {
  .progress-update {
    margin-top: 50px; } }

.progress-update .progress-box {
  text-align: center; }

.progress-update .title {
  text-align: center;
  font-size: 1rem;
  padding-bottom: 1rem;
  height: 4rem; }

.progress-update .date {
  text-align: center;
  font-size: 1.8rem;
  color: #313131; }
  .progress-update .date.empty {
    color: #9b9b9b; }

.progress-update .mark {
  display: inline-block;
  background-color: #f9f8f5;
  width: 90px;
  height: 90px;
  border: 7px solid #cccccc;
  border-radius: 45px; }
  @media (max-width: 991px) {
    .progress-update .mark {
      width: 50px;
      height: 50px; } }

.progress-update .connection {
  padding: 0; }
  .progress-update .connection .line {
    position: relative;
    top: 50%;
    width: 100%;
    border-bottom: 3px dashed #9b9b9b; }
  @media (max-width: 991px) {
    .progress-update .connection {
      max-width: 10%;
      border-bottom-width: 2px; } }
  @media (max-width: 575px) {
    .progress-update .connection {
      display: none; } }

.progress-update.draft .mark.instruction {
  border-color: #80b04c; }

.progress-update.small {
  /**  for client it's same if instruction or document has been sent    **/ }
  .progress-update.small.collected .mark.instruction, .progress-update.small.collected .mark.document, .progress-update.small.collected .mark.policy, .progress-update.small.collected .mark.premium {
    background-image: url("../images/check-ok.svg");
    background-size: 100%;
    border: 0 #80b04c; }
  .progress-update.small.collected .connection .line.first,
  .progress-update.small.collected .connection .line.second,
  .progress-update.small.collected .connection .line.third {
    border-bottom-style: solid; }
  .progress-update.small.issued .mark.instruction, .progress-update.small.issued .mark.document, .progress-update.small.issued .mark.policy {
    background-image: url("../images/check-ok.svg");
    background-size: 100%;
    border: 0 #80b04c; }
  .progress-update.small.issued .mark.premium {
    border-color: #80b04c; }
  .progress-update.small.issued .connection .line.first,
  .progress-update.small.issued .connection .line.second,
  .progress-update.small.issued .connection .line.third {
    border-bottom-style: solid; }
  .progress-update.small.ins-sent .mark.instruction, .progress-update.small.ins-sent .mark.document, .progress-update.small.doc-sent .mark.instruction, .progress-update.small.doc-sent .mark.document {
    background-image: url("../images/check-ok.svg");
    background-size: 100%;
    border: 0 #80b04c; }
  .progress-update.small.ins-sent .mark.policy, .progress-update.small.doc-sent .mark.policy {
    border-color: #80b04c; }
  .progress-update.small.ins-sent .connection .line.first,
  .progress-update.small.ins-sent .connection .line.second, .progress-update.small.doc-sent .connection .line.first,
  .progress-update.small.doc-sent .connection .line.second {
    border-bottom-style: solid; }

.progress-update.full.collected .mark.instruction, .progress-update.full.collected .mark.document, .progress-update.full.collected .mark.policy, .progress-update.full.collected .mark.premium {
  background-image: url("../images/check-ok.svg");
  background-size: 100%;
  border: 0 #80b04c; }

.progress-update.full.collected .connection .line.first,
.progress-update.full.collected .connection .line.second,
.progress-update.full.collected .connection .line.third {
  border-bottom-style: solid; }

.progress-update.full.issued .mark.instruction, .progress-update.full.issued .mark.document, .progress-update.full.issued .mark.policy {
  background-image: url("../images/check-ok.svg");
  background-size: 100%;
  border: 0 #80b04c; }

.progress-update.full.issued .mark.premium {
  border-color: #80b04c; }

.progress-update.full.issued .connection .line.first,
.progress-update.full.issued .connection .line.second,
.progress-update.full.issued .connection .line.third {
  border-bottom-style: solid; }

.progress-update.full.doc-sent .mark.instruction, .progress-update.full.doc-sent .mark.document {
  background-image: url("../images/check-ok.svg");
  background-size: 100%;
  border: 0 #80b04c; }

.progress-update.full.doc-sent .mark.policy {
  border-color: #80b04c; }

.progress-update.full.doc-sent .connection .line.first,
.progress-update.full.doc-sent .connection .line.second {
  border-bottom-style: solid; }

.progress-update.full.ins-sent .mark.instruction {
  background-image: url("../images/check-ok.svg");
  background-size: 100%;
  border: 0 #80b04c; }

.progress-update.full.ins-sent .mark.document {
  border-color: #80b04c; }

.progress-update.full.ins-sent .connection .line.first {
  border-bottom-style: solid; }

@media (max-width: 767px) {
  .dropdown.alerts .nav-link {
    padding: 0.5rem 0.5rem; } }

.dropdown.alerts .drop-content {
  min-height: auto;
  max-height: 290px;
  overflow-y: scroll;
  min-width: 330px;
  padding-bottom: 0; }
  @media (max-width: 575px) {
    .dropdown.alerts .drop-content {
      right: 0 !important;
      min-width: 330px;
      overflow-x: hidden; } }
  .dropdown.alerts .drop-content .dropdown-item {
    cursor: pointer;
    background-color: #FFF; }
  .dropdown.alerts .drop-content a.dropdown-item, .dropdown.alerts .drop-content a.premium-alert {
    font-size: 0.77rem !important;
    border-bottom: 1px solid #e2e2e2;
    padding: 15px 10px !important;
    cursor: pointer;
    background-color: #FFF;
    white-space: normal;
    text-transform: unset;
    width: 100%;
    display: block; }
    .dropdown.alerts .drop-content a.dropdown-item p, .dropdown.alerts .drop-content a.premium-alert p {
      margin-bottom: 0 !important;
      text-transform: none !important;
      font-size: 0.77rem;
      padding-left: 5px;
      border-left: 2px solid #c21b17;
      color: #c21b17; }
    .dropdown.alerts .drop-content a.dropdown-item:active, .dropdown.alerts .drop-content a.premium-alert:active {
      background-color: #FFF; }
    .dropdown.alerts .drop-content a.dropdown-item:hover p, .dropdown.alerts .drop-content a.premium-alert:hover p {
      color: #E45E43; }
    .dropdown.alerts .drop-content a.dropdown-item.premium-alert.seen p, .dropdown.alerts .drop-content a.premium-alert.premium-alert.seen p {
      border-left: 2px solid #6f7072;
      color: #6f7072; }
    .dropdown.alerts .drop-content a.dropdown-item.contact.seen p, .dropdown.alerts .drop-content a.dropdown-item.expire-date.seen p, .dropdown.alerts .drop-content a.premium-alert.contact.seen p, .dropdown.alerts .drop-content a.premium-alert.expire-date.seen p {
      border-left: 2px solid #6f7072;
      color: #6f7072; }

.dropdown.alerts .badge-color {
  background-color: #c21b17;
  position: relative;
  top: -10px;
  color: #FFF;
  border-radius: 10px;
  padding: 4px 0;
  min-width: 17px;
  height: 17px;
  right: 15px;
  width: auto; }

.dropdown.alerts .dropdown-toggle::after {
  display: none; }

.dropdown.alerts .alerts .dropdown-menu {
  padding-bottom: 0;
  padding-top: 0; }

.dropdown.alerts .arrow {
  border: solid black;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px; }

.dropdown.alerts .right {
  -webkit-transform: rotate(-45deg); }

.dropdown.alerts .mark-all label {
  cursor: pointer; }

.tutorial-title {
  font-size: 3.125rem; }

.tutorial-subtitle {
  font-size: 1.28rem; }

.video-tutorial-title {
  font-weight: bold; }

.video-tutorial-description {
  font-style: italic;
  font-size: 1.14rem;
  height: 10rem; }

.js-lazyYT {
  padding-bottom: 0px !important;
  position: relative;
  overflow: hidden;
  height: 68.5%; }
  .js-lazyYT iframe {
    top: 0;
    left: 0;
    width: 100%;
    height: 303px;
    border: 0; }
    @media screen and (max-width: 990px) {
      .js-lazyYT iframe {
        width: 100%;
        height: 220px; } }
  .js-lazyYT .lazyYT-image-loaded {
    height: 303px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center; }
    @media screen and (max-width: 990px) {
      .js-lazyYT .lazyYT-image-loaded {
        width: 100%;
        height: 185px; } }
    @media (min-width: 989px) and (max-width: 1024px) {
      .js-lazyYT .lazyYT-image-loaded {
        width: 100%;
        height: 254px; } }
    @media screen and (max-width: 767px) {
      .js-lazyYT .lazyYT-image-loaded {
        width: 100%;
        height: 225px; } }
  .js-lazyYT .html5-title-text-wrapper {
    display: none; }
  .js-lazyYT .ytp-large-play-button {
    position: absolute;
    cursor: pointer;
    top: 33%;
    left: 30%; }
    @media screen and (max-width: 990px) {
      .js-lazyYT .ytp-large-play-button {
        position: absolute;
        top: 25%;
        left: 25%; } }
    @media screen and (max-width: 768px) {
      .js-lazyYT .ytp-large-play-button {
        position: absolute;
        top: 25%;
        left: 15%; } }
    @media screen and (max-width: 600px) {
      .js-lazyYT .ytp-large-play-button {
        position: absolute;
        top: 38%;
        left: 42%; } }
    @media screen and (max-width: 600px) {
      .js-lazyYT .ytp-large-play-button svg {
        position: absolute;
        top: 20%;
        left: 20%; } }

.jplist-panel {
  margin-top: 1.5rem; }
  @media screen and (max-width: 600px) {
    .jplist-panel {
      display: block !important;
      text-align: center; } }
  .jplist-panel .jplist-pagination {
    display: inline-flex; }
    .jplist-panel .jplist-pagination .jplist-pagingnext.jplist-hidden button {
      font-weight: normal; }
    .jplist-panel .jplist-pagination .jplist-pagingnext button {
      font-weight: 700; }
    .jplist-panel .jplist-pagination .jplist-pagingnext .jplist-last {
      display: none !important; }
    .jplist-panel .jplist-pagination .jplist-pagingnext .jplist-next {
      font-size: 2.5rem; }
      .jplist-panel .jplist-pagination .jplist-pagingnext .jplist-next:focus {
        outline-color: white !important; }
    .jplist-panel .jplist-pagination .jplist-pagingprev.jplist-hidden button {
      font-weight: normal; }
    .jplist-panel .jplist-pagination .jplist-pagingprev button {
      font-weight: 700; }
    .jplist-panel .jplist-pagination .jplist-pagingprev .jplist-first {
      display: none !important; }
    .jplist-panel .jplist-pagination .jplist-pagingmid {
      margin-top: 15px; }
      .jplist-panel .jplist-pagination .jplist-pagingmid button {
        font-size: inherit !important; }
  .jplist-panel button {
    border: none !important;
    background: none !important;
    box-shadow: none !important;
    text-shadow: none !important;
    border-radius: unset !important;
    color: #6E6F71 !important;
    font-size: 2.5rem;
    cursor: pointer; }
    .jplist-panel button:focus {
      border: none !important;
      outline-color: white !important; }
  .jplist-panel .jplist-pagesbox .jplist-current {
    color: #892034 !important;
    font-weight: bold; }
  .jplist-panel .jplist-hidden {
    display: block !important; }
    .jplist-panel .jplist-hidden .jplist-prev {
      font-size: 2.5rem; }
      .jplist-panel .jplist-hidden .jplist-prev:focus {
        outline-color: white !important; }

.thumbnailImage {
  max-width: 100%;
  width: 100%;
  height: auto;
  max-height: 303px; }

.pdf-mt-50 {
  margin-top: 50px; }

.pdf-image-container {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: end;
  align-items: end;
  background: #F3F3F3; }

.pdf-highlight {
  position: absolute;
  top: 35%;
  left: 44%;
  z-index: 1; }

.pdf-button-highlight {
  position: absolute;
  top: 62%;
  left: 38%;
  z-index: 1;
  background: white;
  border-radius: 2px;
  padding: 8.5px 28.5px;
  border: solid 1px #6e6f71;
  text-decoration: none;
  color: #737476; }

.pdf-button-highlight-employee-client {
  position: absolute;
  top: 62%;
  left: 32%;
  z-index: 1;
  background: white;
  border-radius: 2px;
  padding: 8.5px 28.5px;
  border: solid 1px #6e6f71;
  text-decoration: none;
  color: #737476; }

.pdf-button-highlight-employee-broker {
  position: absolute;
  top: 80%;
  left: 32%;
  z-index: 1;
  background: white;
  border-radius: 2px;
  padding: 8.5px 28.5px;
  border: solid 1px #6e6f71;
  text-decoration: none;
  color: #737476; }

.user-dropdown {
  width: 325px; }
  .user-dropdown .dropdown-divider {
    margin: 0;
    width: 240px;
    border-top: 1px solid #6f7072;
    margin-left: 23px; }
  .user-dropdown .switch {
    font-size: 1rem;
    position: relative; }
  .user-dropdown .switch input {
    position: absolute;
    height: 1px;
    width: 1px;
    background: none;
    border: 0;
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    overflow: hidden;
    padding: 0; }
  .user-dropdown .switch input + label {
    position: relative;
    min-width: calc((2.375rem * .8) * 2);
    min-width: calc(calc(2.375rem * .8) * 2);
    border-radius: calc(2.375rem * .8);
    height: calc(2.375rem * .8);
    line-height: calc(2.375rem * .8);
    display: inline-block;
    cursor: pointer;
    outline: none;
    user-select: none;
    vertical-align: middle;
    text-indent: calc(((2.375rem * .8) * 2) + .5rem);
    text-indent: calc(calc(calc(2.375rem * .8) * 2) + .5rem); }
  .user-dropdown .switch input + label::before,
  .user-dropdown .switch input + label::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: calc((2.375rem * .8) * 2);
    width: calc(calc(2.375rem * .8) * 2);
    bottom: 0;
    display: block; }
  .user-dropdown .switch input + label::before {
    right: 0;
    background-color: #707070;
    border-radius: calc(2.375rem * .8);
    transition: 0.2s all; }
  .user-dropdown .switch input + label::after {
    top: 3px;
    left: 4px;
    width: calc((2.175rem * .8) - (2px * 2));
    width: calc(calc(2.175rem * .8) - calc(2px * 2));
    height: calc((2.175rem * .8) - (2px * 2));
    height: calc(calc(2.175rem * .8) - calc(2px * 2));
    border-radius: 50%;
    background-color: white;
    transition: 0.2s all; }
  .user-dropdown .switch input:checked + label::before {
    background-color: #aa1b17; }
  .user-dropdown .switch input:checked + label::after {
    margin-left: calc(2.175rem * .8); }
  .user-dropdown .switch input:focus + label::before {
    outline: none; }
  .user-dropdown .switch input:disabled + label {
    color: #707070;
    cursor: not-allowed; }
  .user-dropdown .switch input:disabled + label::before {
    background-color: #e9ecef; }
  .user-dropdown .switch.notificationsSwitch {
    font-size: 0.875rem; }
  .user-dropdown .switch.notificationsSwitch input + label {
    min-width: calc((1.9375rem * .8) * 2);
    min-width: calc(calc(1.9375rem * .8) * 2);
    height: calc(1.9375rem * .8);
    line-height: calc(1.9375rem * .8);
    text-indent: calc(((1.9375rem * .8) * 2) + .5rem);
    text-indent: calc(calc(calc(1.9375rem * .8) * 2) + .5rem); }
  .user-dropdown .switch.notificationsSwitch input + label::before {
    width: calc((1.9375rem * .8) * 2);
    width: calc(calc(1.9375rem * .8) * 2); }
  .user-dropdown .switch.notificationsSwitch input + label::after {
    width: calc((1.9375rem * .8) - (2px * 2));
    width: calc(calc(1.9375rem * .8) - calc(2px * 2));
    height: calc((1.9375rem * .8) - (2px * 2));
    height: calc(calc(1.9375rem * .8) - calc(2px * 2)); }
  .user-dropdown .switch.notificationsSwitch input:checked + label::after {
    margin-left: calc(1.9375rem * .8); }
  .user-dropdown .switch.switch-lg {
    font-size: 1.25rem; }
  .user-dropdown .switch.switch-lg input + label {
    min-width: calc((3rem * .8) * 2);
    min-width: calc(calc(3rem * .8) * 2);
    height: calc(3rem * .8);
    line-height: calc(3rem * .8);
    text-indent: calc(((3rem * .8) * 2) + .5rem);
    text-indent: calc(calc(calc(3rem * .8) * 2) + .5rem); }
  .user-dropdown .switch.switch-lg input + label::before {
    width: calc((3rem * .8) * 2);
    width: calc(calc(3rem * .8) * 2); }
  .user-dropdown .switch.switch-lg input + label::after {
    width: calc((3rem * .8) - (2px * 2));
    width: calc(calc(3rem * .8) - calc(2px * 2));
    height: calc((3rem * .8) - (2px * 2));
    height: calc(calc(3rem * .8) - calc(2px * 2)); }
  .user-dropdown .switch.switch-lg input:checked + label::after {
    margin-left: calc(3rem * .8); }
  .user-dropdown .switch + .switch {
    margin-left: 1rem; }
  .user-dropdown .dropdown-menu {
    margin-top: .75rem; }
  .user-dropdown .notifications {
    padding-left: 20px;
    color: #6f7072;
    font-size: 0.85rem;
    padding-bottom: 0;
    font-weight: 700; }
    .user-dropdown .notifications .notification-button {
      margin-bottom: 7px; }
  .user-dropdown .frequency {
    padding-left: 5px;
    font-size: 0.6875rem;
    display: none;
    padding-bottom: 35px;
    color: #6f7072; }
    .user-dropdown .frequency .dropdown-frequency .dropdown-menu-notifications {
      width: 98px;
      border: 1px solid #6E6F71;
      height: 22px;
      border-radius: 5px; }
      .user-dropdown .frequency .dropdown-frequency .dropdown-menu-notifications:focus {
        outline-color: #f6f4f0; }
      .user-dropdown .frequency .dropdown-frequency .dropdown-menu-notifications .notification-item {
        font-size: 0.6875rem;
        border-bottom: 1px solid #6E6F71;
        text-transform: capitalize; }
        .user-dropdown .frequency .dropdown-frequency .dropdown-menu-notifications .notification-item:last-child {
          border-bottom: none; }
    .user-dropdown .frequency .frequency-btn {
      width: 98px; }
  .user-dropdown .log-out {
    color: #c21b17;
    background-image: none;
    background-color: #FFF;
    padding: 0.4rem 2rem;
    border: 1px solid #c21b17;
    border-radius: 4px; }
    .user-dropdown .log-out:hover {
      color: #FFF;
      background-color: #c21b17; }

.search-results .bootstrap-select {
  max-width: none !important; }

@media (max-width: 575px) {
  .search-results .navbar-form .search-results-input {
    width: calc(100% - 104px); }
  .search-results .global-search-button .search-button-mps {
    width: 100px; } }

.search-results .search-results-input {
  width: calc(100% - 194px);
  margin: 0;
  display: inline-block;
  height: 40px;
  border: 2px solid #9b9b9b;
  outline-style: none;
  padding-left: 15px;
  border-radius: 5px 0 0 5px; }
  @media (max-width: 767px) {
    .search-results .search-results-input {
      margin-left: 0; } }

.search-results .show-hide-button {
  padding: 10px 35px;
  background-color: #aa1b17;
  color: #FFF !important;
  font-weight: bold; }

.search-results .show-hide-filters {
  background-color: #aa1b17;
  padding: 100px;
  display: none; }

@media (max-width: 767px) {
  .search-results .search-input-title {
    margin-left: 0; } }

.search-results .search-button-mps {
  height: 40px;
  width: 186px;
  display: inline-block;
  margin: 0 0 2px -2px; }

@media (max-width: 767px) {
  .search-results .global-filters {
    margin-left: 0; } }

@media (max-width: 767px) {
  .search-results .global-filters label {
    font-size: 0.625rem;
    width: 100%; } }

@media (max-width: 991px) {
  .search-results .global-filters .glob-filter {
    margin-top: 0 !important; } }

@media (max-width: 767px) {
  .search-results .global-filters .glob-filter {
    padding-left: 0;
    margin-top: 0 !important; } }

.search-results .mps-table {
  background-color: #f6f4f0; }
  .search-results .mps-table table {
    width: 100%; }
    .search-results .mps-table table.mpsSearchTable {
      padding: 1rem; }
      @media (max-width: 767px) {
        .search-results .mps-table table.mpsSearchTable {
          margin-bottom: 0 !important; } }
    .search-results .mps-table table thead {
      background-color: #dadada; }
    @media (max-width: 767px) {
      .search-results .mps-table table tbody td {
        padding-left: 35% !important; } }
    .search-results .mps-table table tbody .odd, .search-results .mps-table table tbody .even {
      border-bottom: 1px solid #6E6F71; }
    .search-results .mps-table table tbody td:first-child {
      padding-left: 3rem; }
  .search-results .mps-table #searchMps_filter,
  .search-results .mps-table .dataTables_info {
    display: none !important; }
  @media (max-width: 767px) {
    .search-results .mps-table {
      margin: 0;
      padding: 0; } }

@media (max-width: 767px) {
  .search-results .global-show-hide {
    margin-right: 5px; } }

@media (max-width: 767px) {
  .search-results .global-as-filters {
    margin-top: 2rem !important; } }

.search-results .global-as-filters .reset-filter {
  border: solid 2px #4a4a4a;
  height: 40px;
  color: #4a4a4a;
  padding: 0 35px;
  background-color: #FFF; }
  .search-results .global-as-filters .reset-filter:hover {
    color: #FFF;
    background-color: #4a4a4a; }
  .search-results .global-as-filters .reset-filter:focus {
    box-shadow: none;
    border-color: #4a4a4a; }
  .search-results .global-as-filters .reset-filter:active {
    box-shadow: none; }
  @media (max-width: 767px) {
    .search-results .global-as-filters .reset-filter {
      padding: 0 20px; } }

.search-link {
  background-image: url("../images/lente-2.svg");
  background-repeat: no-repeat;
  width: 32px;
  height: 32px;
  background-position: center;
  margin-top: 3px; }
  .search-link.selected {
    background-color: #e9573d;
    background-image: url("../images/lente-white.svg"); }

.dataTables_empty {
  padding-top: 2rem; }

.searchInputDisplay {
  font-size: 1.3rem;
  font-family: "LatoWeb", sans-serif;
  color: #4a4a4a; }

#searchInputDisplay {
  font-size: 1.3rem;
  color: #4a4a4a;
  font-family: "LatoWeb", sans-serif;
  font-style: italic; }

.font-controls .font-size-12 {
  font-size: 0.75rem; }

.font-controls .font-size-14 {
  font-size: 0.875rem; }

.font-controls .font-size-16 {
  font-size: 1rem; }

.font-controls button {
  border: none !important;
  background: none !important;
  box-shadow: none !important;
  text-shadow: none !important;
  border-radius: unset !important;
  font-weight: bold;
  color: #6f7072;
  cursor: pointer; }
  .font-controls button:focus {
    border: none !important;
    outline-color: white !important; }
  .font-controls button img {
    width: 1rem;
    margin-top: -5px; }

.high-contrast .navbar.service-menu .nav-item a.dropdown-item {
  border-bottom: 4px solid black; }

.high-contrast .navbar.service-menu .nav-item a.dropdown-item:hover {
  color: black; }

.high-contrast a {
  color: black; }

#unpublishModal,
#publishModal {
  display: none;
  position: fixed;
  padding-top: 4rem;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: black;
  background-color: rgba(0, 0, 0, 0.4); }
  #unpublishModal .radio-buttons label,
  #publishModal .radio-buttons label {
    margin-right: 1rem;
    display: inline-flex; }
  #unpublishModal .modal-dialog,
  #publishModal .modal-dialog {
    background-color: #fefefe;
    margin: auto;
    width: 100%;
    border-radius: 5px; }
    @media (min-width: 768px) {
      #unpublishModal .modal-dialog,
      #publishModal .modal-dialog {
        max-width: none;
        width: 40%; } }
    @media (min-width: 576px) and (max-width: 991px) {
      #unpublishModal .modal-dialog,
      #publishModal .modal-dialog {
        max-width: none;
        width: 60%; } }
    #unpublishModal .modal-dialog .help-title,
    #publishModal .modal-dialog .help-title {
      text-transform: uppercase;
      font-size: 1.5rem; }
    #unpublishModal .modal-dialog p,
    #publishModal .modal-dialog p {
      margin-top: 0;
      font-size: 1rem !important;
      width: 100%; }
    #unpublishModal .modal-dialog .closeHelp,
    #publishModal .modal-dialog .closeHelp {
      cursor: pointer; }
    #unpublishModal .modal-dialog .message-label,
    #publishModal .modal-dialog .message-label {
      width: 30%;
      float: left;
      display: inline-block; }
      @media (max-width: 767px) {
        #unpublishModal .modal-dialog .message-label,
        #publishModal .modal-dialog .message-label {
          width: 100%; } }
    #unpublishModal .modal-dialog .text-area-width,
    #publishModal .modal-dialog .text-area-width {
      float: left;
      width: 100%; }
    #unpublishModal .modal-dialog .help-tip,
    #publishModal .modal-dialog .help-tip {
      opacity: 1;
      position: relative; }
  #unpublishModal textarea,
  #publishModal textarea {
    width: 50%;
    border-radius: 4px;
    height: 150px;
    padding: 15px;
    border: solid 1px #b0b2b3; }
    @media (max-width: 767px) {
      #unpublishModal textarea,
      #publishModal textarea {
        width: 95%; } }
    #unpublishModal textarea:focus,
    #publishModal textarea:focus {
      outline-color: #b0b2b3; }
  #unpublishModal .contact-send-button,
  #publishModal .contact-send-button {
    background-color: #AC2009;
    color: #FFF;
    padding: 7px 75px; }
  #unpublishModal .radio-buttons,
  #publishModal .radio-buttons {
    display: flex; }
    @media (max-width: 767px) {
      #unpublishModal .radio-buttons,
      #publishModal .radio-buttons {
        display: inline-block; } }
  #unpublishModal .radio-item,
  #publishModal .radio-item {
    display: none !important; }
    #unpublishModal .radio-item:checked + label .rcorner,
    #publishModal .radio-item:checked + label .rcorner {
      border: transparent !important; }
      #unpublishModal .radio-item:checked + label .rcorner img,
      #publishModal .radio-item:checked + label .rcorner img {
        opacity: 1;
        width: 22px;
        height: 22px; }
  #unpublishModal label,
  #publishModal label {
    cursor: pointer; }
  #unpublishModal .label-item .rcorner,
  #publishModal .label-item .rcorner {
    border-radius: 50%;
    width: 22px;
    height: 22px;
    border: solid 2px #979797;
    margin-right: 0.5rem; }
  #unpublishModal .label-item img,
  #publishModal .label-item img {
    opacity: 0; }
  #unpublishModal .mandatory-field.error,
  #unpublishModal .mandatory-field-object.error,
  #publishModal .mandatory-field.error,
  #publishModal .mandatory-field-object.error {
    color: #c21b17; }
  #unpublishModal .mandatory-info,
  #publishModal .mandatory-info {
    font-style: italic; }
  #unpublishModal .error-msg,
  #publishModal .error-msg {
    margin-bottom: 0 !important;
    color: #c21b17;
    font-weight: bold; }
  #unpublishModal .input-border,
  #publishModal .input-border {
    border: solid 1px #b0b2b3;
    border-radius: 4px; }

.emailSent {
  color: #80b04c;
  font-weight: 700; }

#contactHelp p {
  margin-top: 0;
  font-size: 1rem !important;
  width: 100%; }

#contactHelp .help-title {
  text-transform: uppercase;
  font-size: 1.5rem; }

#contactHelp .message-label {
  width: 30%;
  float: left;
  display: inline-block; }
  @media (max-width: 767px) {
    #contactHelp .message-label {
      width: 100%; } }

#contactHelp .help-tip {
  opacity: 1 !important;
  position: relative; }

#contactHelp .closeHelp {
  cursor: pointer; }

#contactHelp #welcome-text-area {
  width: 100%;
  border-radius: 4px;
  height: 150px;
  border: solid 1px #b0b2b3; }
  @media (max-width: 991px) {
    #contactHelp #welcome-text-area {
      margin-left: 0; } }

#contactHelp .mandatory-field.error,
#contactHelp .mandatory-field-object.error {
  color: #c21b17; }

#contactHelp .radio-buttons {
  display: flex; }
  @media (max-width: 767px) {
    #contactHelp .radio-buttons {
      display: inline-block; } }

#contactHelp .radio-item {
  display: none !important; }
  #contactHelp .radio-item:checked + label .rcorner {
    border: transparent !important; }
    #contactHelp .radio-item:checked + label .rcorner img {
      opacity: 1;
      width: 22px;
      height: 22px; }

#contactHelp label {
  cursor: pointer; }

#contactHelp .label-item .rcorner {
  border-radius: 50%;
  width: 22px;
  height: 22px;
  border: solid 2px #979797;
  margin-right: 0.5rem; }

#contactHelp .label-item img {
  opacity: 0; }

#contactHelp textarea {
  width: 50%;
  border-radius: 4px;
  height: 150px;
  padding: 15px;
  border: solid 1px #b0b2b3; }
  @media (max-width: 767px) {
    #contactHelp textarea {
      width: 95%; } }
  #contactHelp textarea:focus {
    outline-color: #b0b2b3; }

#contactHelp .contact-send-button {
  background-color: #AC2009;
  color: #FFF;
  padding: 7px 75px; }

#contactHelp .radio-buttons label {
  margin-right: 1rem;
  display: inline-flex; }

#contactHelp .modal-content {
  padding: 15px; }

#contactHelp .error-msg {
  color: #c21b17;
  font-weight: 700; }

.doc-table {
  background-color: #f6f4f0;
  padding-left: 30px;
  padding-right: 30px; }
  .doc-table .docTable {
    margin-top: 30px !important; }
    .doc-table .docTable thead {
      background-color: #dadada; }
    .doc-table .docTable tbody tr td:first-child {
      padding-left: 0.5rem; }
  .doc-table .dataTables_filter,
  .doc-table .dataTables_info {
    display: none !important; }

.doc-label {
  min-height: 25px; }
  @media (max-width: 575px) {
    .doc-label {
      min-height: 0; } }

.checkbox-item {
  opacity: 0;
  margin-right: -1.8rem;
  margin-top: 0.6rem;
  margin-left: 1.3rem;
  width: 24px;
  height: 16px; }
  .checkbox-item:checked + label.label-checkbox {
    background: url("../images/check1-red.svg");
    background-repeat: no-repeat;
    background-size: 1.6rem;
    background-position: center;
    border-color: transparent;
    margin-top: 0.3rem; }

label.label-checkbox {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: solid 2px #979797;
  margin-top: 0.3rem; }

.checkbox span {
  margin-left: 0.4rem;
  margin-top: 0.5rem; }

@media (max-width: 767px) {
  #docTable {
    font-size: 10px; }
    #docTable td:nth-of-type(1):before {
      content: "CLIENT"; }
    #docTable td:nth-of-type(2):before {
      content: "COUNTRY"; }
    #docTable td:nth-of-type(3):before {
      content: "DOCUMENT TYPE"; }
    #docTable td:nth-of-type(4):before {
      content: "YEAR"; }
    #docTable td:nth-of-type(5):before {
      content: "PROGRAM"; }
    #docTable td:nth-of-type(6):before {
      content: "DOCUMENT"; }
    #docTable td:nth-of-type(7):before {
      content: "DOWNLOAD"; } }

.claims-table {
  background-color: #f6f4f0;
  padding-left: 30px;
  padding-right: 30px; }
  .claims-table .claimsTable {
    margin-top: 30px !important; }
    .claims-table .claimsTable thead {
      background-color: #dadada !important; }
    .claims-table .claimsTable tbody tr td:first-child {
      padding-left: 0.5rem; }
  .claims-table .dataTables_filter,
  .claims-table .dataTables_info {
    display: none !important; }

.claims-label {
  min-height: 25px; }
  @media (max-width: 575px) {
    .claims-label {
      min-height: 0; } }

@media (max-width: 767px) {
  #claimsTable, #bubbleTable, #barTable {
    font-size: 10px; }
    #claimsTable td:nth-of-type(1):before, #bubbleTable td:nth-of-type(1):before, #barTable td:nth-of-type(1):before {
      content: "COUNTRY"; }
    #claimsTable td:nth-of-type(2):before, #bubbleTable td:nth-of-type(2):before, #barTable td:nth-of-type(2):before {
      content: "TYPE OF POLICY"; }
    #claimsTable td:nth-of-type(3):before, #bubbleTable td:nth-of-type(3):before, #barTable td:nth-of-type(3):before {
      content: "YEAR"; }
    #claimsTable td:nth-of-type(4):before, #bubbleTable td:nth-of-type(4):before, #barTable td:nth-of-type(4):before {
      content: "PROGRAM"; }
    #claimsTable td:nth-of-type(5):before, #bubbleTable td:nth-of-type(5):before, #barTable td:nth-of-type(5):before {
      content: "PRODUCING OFFICE"; }
    #claimsTable td:nth-of-type(6):before, #bubbleTable td:nth-of-type(6):before, #barTable td:nth-of-type(6):before {
      content: "LOCAL INSURER"; }
    #claimsTable td:nth-of-type(7):before, #bubbleTable td:nth-of-type(7):before, #barTable td:nth-of-type(7):before {
      content: "CLAIM STATUS"; }
    #claimsTable td:nth-of-type(8):before, #bubbleTable td:nth-of-type(8):before, #barTable td:nth-of-type(8):before {
      content: "CAUSE OF LOSS"; }
    #claimsTable td:nth-of-type(9):before, #bubbleTable td:nth-of-type(9):before, #barTable td:nth-of-type(9):before {
      content: "CLAIM NUMBER"; }
  #allClaimsTable {
    font-size: 10px; }
    #allClaimsTable td:nth-of-type(1):before {
      content: "CLIENT"; }
    #allClaimsTable td:nth-of-type(2):before {
      content: "COUNTRY"; }
    #allClaimsTable td:nth-of-type(3):before {
      content: "TYPE OF POLICY"; }
    #allClaimsTable td:nth-of-type(4):before {
      content: "YEAR"; }
    #allClaimsTable td:nth-of-type(5):before {
      content: "PROGRAM"; }
    #allClaimsTable td:nth-of-type(6):before {
      content: "PRODUCING OFFICE"; }
    #allClaimsTable td:nth-of-type(7):before {
      content: "LOCAL INSURER"; }
    #allClaimsTable td:nth-of-type(8):before {
      content: "CLAIM STATUS"; }
    #allClaimsTable td:nth-of-type(9):before {
      content: "CAUSE OF LOSS"; } }

@media (max-width: 767px) {
  #allLocationsTable {
    font-size: 10px; }
    #allLocationsTable td:nth-of-type(1):before {
      content: "CLIENT NAME"; }
    #allLocationsTable td:nth-of-type(2):before {
      content: "LOB"; }
    #allLocationsTable td:nth-of-type(3):before {
      content: "COUNTRY"; }
    #allLocationsTable td:nth-of-type(4):before {
      content: "PRODUCING OFFICE"; }
  #locationsTable {
    font-size: 10px; }
    #locationsTable td:nth-of-type(1):before {
      content: "CLIENT NAME"; }
    #locationsTable td:nth-of-type(2):before {
      content: "LOB"; }
    #locationsTable td:nth-of-type(3):before {
      content: "COUNTRY"; }
    #locationsTable td:nth-of-type(4):before {
      content: "ADDRESS"; }
    #locationsTable td:nth-of-type(5):before {
      content: "CITY"; }
    #locationsTable td:nth-of-type(6):before {
      content: "PRODUCING OFFICE"; }
    #locationsTable td:nth-of-type(7):before {
      content: "LOCATION ID"; }
    #locationsTable td:nth-of-type(8):before {
      content: "LOCAL POLICY"; }
    #locationsTable td:nth-of-type(9):before {
      content: "TECH MARK"; } }

@media (max-width: 767px) {
  #recommendationsTable {
    font-size: 10px; }
    #recommendationsTable td:nth-of-type(1):before {
      content: "COUNTRY"; }
    #recommendationsTable td:nth-of-type(2):before {
      content: "CITY"; }
    #recommendationsTable td:nth-of-type(3):before {
      content: "ISSUE DATE"; }
    #recommendationsTable td:nth-of-type(4):before {
      content: "RECOMMENDATION NAME"; }
    #recommendationsTable td:nth-of-type(5):before {
      content: "PRIORITY"; }
    #recommendationsTable td:nth-of-type(6):before {
      content: "TYPE"; }
    #recommendationsTable td:nth-of-type(7):before {
      content: "STATUS"; }
    #recommendationsTable td:nth-of-type(8):before {
      content: "LOCAL POLICY"; } }

@media (max-width: 767px) {
  #uploadedDocumentsTable {
    font-size: 10px; }
    #uploadedDocumentsTable td:nth-of-type(1):before {
      content: "CLIENT NAME"; }
    #uploadedDocumentsTable td:nth-of-type(2):before {
      content: "COUNTRY"; }
    #uploadedDocumentsTable td:nth-of-type(3):before {
      content: "CITY"; }
    #uploadedDocumentsTable td:nth-of-type(4):before {
      content: "ADDRESS"; }
    #uploadedDocumentsTable td:nth-of-type(5):before {
      content: "UPLOAD DATE"; }
    #uploadedDocumentsTable td:nth-of-type(6):before {
      content: "DOCUMENT NAME"; }
    #uploadedDocumentsTable td:nth-of-type(7):before {
      content: "DOCUMENT TYPE"; }
    #uploadedDocumentsTable td:nth-of-type(8):before {
      content: "ACTIONS"; } }

.pagination .page-item .page-link {
  border: 0;
  background: transparent; }
  .pagination .page-item .page-link:focus {
    box-shadow: none; }

.pagination .page-item.active .page-link {
  color: #892034; }

.pagination .page-item.disabled .page-link {
  color: #AAA; }

div.dataTables_wrapper div.dataTables_paginate ul.pagination {
  justify-content: center;
  padding: 1rem 1rem 0; }

.paginate_button.next.disabled {
  opacity: 0.5; }

.paginate_button.previous.disabled {
  opacity: 0.5; }

.table.dataTable thead > tr > th.sorting_asc,
.table.dataTable thead > tr > th.sorting {
  padding-left: 12px !important; }

div.dataTables_wrapper {
  padding-bottom: 1rem; }

@media (max-device-width: 320px) {
  .programWidget {
    left: -5rem; }
    .programWidget canvas {
      left: -3px !important; }
  #country-widget canvas {
    width: 570px !important;
    height: 380px !important; } }

@media (max-width: 576px) {
  #program-widget-broker-employee {
    height: 300px !important; }
    #program-widget-broker-employee canvas {
      width: 260px !important;
      height: auto !important;
      top: 35px !important; } }

@media (min-width: 576px) {
  .contactSearch.col-sm-6 {
    flex: 0 0 50%;
    max-width: 56%; }
  p.h6 u {
    font-size: 0.8rem; } }

@media (max-width: 767px) {
  .tab-container {
    height: auto !important;
    margin-bottom: 1rem !important; }
  .scrollbar-polices {
    height: auto; }
  .topMargin {
    margin-top: 0; }
  .tab-exposure {
    margin-left: 1rem;
    margin-right: 1rem; }
  .exposureTable thead th {
    display: block;
    margin-bottom: 0 !important;
    width: 100% !important;
    font-size: 1.2rem !important;
    font-weight: bold !important;
    text-transform: capitalize !important;
    border-bottom: 1px solid #AEAFB0 !important; }
  .exposureTable tbody td {
    display: block;
    width: 100%; }
    .exposureTable tbody td b {
      font-weight: bold !important;
      font-size: 1.2rem;
      color: #6f7072; }
  .countryWidget {
    display: grid; }
  #country-widget {
    height: 300px;
    top: -135px; }
  #program-widget-client {
    height: 300px; }
    #program-widget-client canvas {
      top: 20px !important;
      width: 270px !important;
      height: 280px !important; }
  .help-link {
    padding: 0.4rem !important; }
  button.help-text-button a {
    padding: 0.4rem !important;
    margin-right: 1rem; }
  .help-button {
    padding: 0.4rem !important; }
    .help-button ul {
      margin-left: -2rem; }
  .help-img {
    margin-right: 1.5rem;
    margin-left: 0.5rem;
    padding: 0rem !important; } }

@media (max-width: 768px) {
  h2 {
    font-size: 2rem;
    margin-top: 1rem; }
  p.status {
    margin-left: 1.5rem; }
  .jumbotron h1 {
    margin-top: 2rem !important; }
  .btn-sm, .btn-group-sm > .btn {
    padding: 0.25rem; }
  .programWidget {
    height: 200px !important; }
    .programWidget canvas {
      width: 400px !important;
      height: 250px !important; }
  .info-view-more {
    display: none; } }

@media (min-width: 769px) {
  .pending-content {
    width: 60% !important; } }

@media (min-width: 768px) {
  table.mpsSearchTable tr {
    height: 2.5rem; }
  table.docTable tr,
  .premiumAllocations tr {
    height: 2.5rem; } }

@media (max-width: 1023px) {
  .bootstrap-select.notMaster .dropdown-toggle .filter-option-inner-inner,
  .bootstrap-select .dropdown-menu li a span.text {
    font-size: 0.63rem;
    margin-top: 0.3rem; } }

@media (min-width: 1024px) {
  .bootstrap-select.notMaster .dropdown-toggle .filter-option-inner-inner,
  .bootstrap-select .dropdown-menu li a span.text {
    font-size: 0.9rem;
    margin-top: 0.2rem; }
  .pdValues {
    top: 90px; }
  #country-widget {
    position: relative;
    height: 300px; }
    #country-widget canvas {
      top: -90px !important;
      left: -15px !important; }
  .info-view-more {
    margin-top: -8rem !important; }
  .programWidget {
    top: -120px !important; } }

@media (max-width: 1024px) {
  .tab-content {
    height: 86%; } }

@media (min-width: 1025px) {
  .bootstrap-select.notMaster .dropdown-toggle .filter-option-inner-inner,
  .bootstrap-select .dropdown-menu li a span.text {
    font-size: 1.1rem;
    margin-top: 0.2rem; }
  .doc-filter {
    margin-right: -3rem; } }

@media (min-width: 321px) and (max-width: 374px) {
  .programWidget {
    left: -4rem !important; } }

@media (min-width: 400px) and (max-width: 767px) {
  .programWidget {
    left: -2rem !important; } }

@media (min-width: 576px) and (max-width: 767px) {
  .programWidget {
    height: 150px !important; }
    .programWidget canvas {
      width: 309px !important;
      height: 200px !important; }
  #program-widget-broker-employee {
    height: 300px !important; }
    #program-widget-broker-employee canvas {
      width: 260px !important;
      height: auto !important;
      top: 35px !important; } }

@media (min-width: 576px) and (max-device-width: 1023px) {
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 5rem !important; } }

@media (min-width: 768px) and (max-width: 1023px) {
  .doc-filter {
    margin-right: 0rem; }
  table.dataTable td, table.dataTable th {
    font-size: 0.7rem; }
  .navbar-collapse {
    padding-left: 9rem; }
  .tab-content {
    height: 84%; }
  .programWidget {
    height: 200px !important; }
    .programWidget canvas {
      width: 400px !important;
      height: 250px !important; }
  #program-widget-broker-employee canvas {
    width: 430px !important;
    height: auto !important;
    top: 35px !important; }
  #program-widget-client canvas {
    width: 420px !important; }
  .pdValues {
    top: -20px; }
  .searchText {
    width: 86%;
    padding-left: 0rem !important; } }

@media (min-width: 1024px) and (max-width: 1200px) {
  .programWidget {
    left: -6rem; } }

.content-wrapper-welcome,
.content-wrapper-terms-of-use {
  background-color: #f6f4f0; }
  .content-wrapper-welcome .welcome-page-wrapper,
  .content-wrapper-welcome .terms-of-use-page-wrapper,
  .content-wrapper-terms-of-use .welcome-page-wrapper,
  .content-wrapper-terms-of-use .terms-of-use-page-wrapper {
    width: 85%;
    margin: 13% auto;
    box-shadow: 4px 4px 11px 0 rgba(0, 0, 0, 0.35);
    border-radius: 5px; }
    @media (max-width: 767px) {
      .content-wrapper-welcome .welcome-page-wrapper,
      .content-wrapper-welcome .terms-of-use-page-wrapper,
      .content-wrapper-terms-of-use .welcome-page-wrapper,
      .content-wrapper-terms-of-use .terms-of-use-page-wrapper {
        width: 95%; } }
    .content-wrapper-welcome .welcome-page-wrapper .welcome-page-header,
    .content-wrapper-welcome .welcome-page-wrapper .terms-of-use-page-header,
    .content-wrapper-welcome .terms-of-use-page-wrapper .welcome-page-header,
    .content-wrapper-welcome .terms-of-use-page-wrapper .terms-of-use-page-header,
    .content-wrapper-terms-of-use .welcome-page-wrapper .welcome-page-header,
    .content-wrapper-terms-of-use .welcome-page-wrapper .terms-of-use-page-header,
    .content-wrapper-terms-of-use .terms-of-use-page-wrapper .welcome-page-header,
    .content-wrapper-terms-of-use .terms-of-use-page-wrapper .terms-of-use-page-header {
      background-color: #ffffff;
      padding: 4rem 8rem 6rem 6rem;
      border-bottom: 5px solid #c12016;
      border-radius: 5px 5px 0 0; }
      @media (max-width: 767px) {
        .content-wrapper-welcome .welcome-page-wrapper .welcome-page-header,
        .content-wrapper-welcome .welcome-page-wrapper .terms-of-use-page-header,
        .content-wrapper-welcome .terms-of-use-page-wrapper .welcome-page-header,
        .content-wrapper-welcome .terms-of-use-page-wrapper .terms-of-use-page-header,
        .content-wrapper-terms-of-use .welcome-page-wrapper .welcome-page-header,
        .content-wrapper-terms-of-use .welcome-page-wrapper .terms-of-use-page-header,
        .content-wrapper-terms-of-use .terms-of-use-page-wrapper .welcome-page-header,
        .content-wrapper-terms-of-use .terms-of-use-page-wrapper .terms-of-use-page-header {
          padding: 4rem 0 0 0;
          height: 18rem; } }
      .content-wrapper-welcome .welcome-page-wrapper .welcome-page-header .welcome-page-logo,
      .content-wrapper-welcome .welcome-page-wrapper .welcome-page-header .terms-of-use-page-logo,
      .content-wrapper-welcome .welcome-page-wrapper .terms-of-use-page-header .welcome-page-logo,
      .content-wrapper-welcome .welcome-page-wrapper .terms-of-use-page-header .terms-of-use-page-logo,
      .content-wrapper-welcome .terms-of-use-page-wrapper .welcome-page-header .welcome-page-logo,
      .content-wrapper-welcome .terms-of-use-page-wrapper .welcome-page-header .terms-of-use-page-logo,
      .content-wrapper-welcome .terms-of-use-page-wrapper .terms-of-use-page-header .welcome-page-logo,
      .content-wrapper-welcome .terms-of-use-page-wrapper .terms-of-use-page-header .terms-of-use-page-logo,
      .content-wrapper-terms-of-use .welcome-page-wrapper .welcome-page-header .welcome-page-logo,
      .content-wrapper-terms-of-use .welcome-page-wrapper .welcome-page-header .terms-of-use-page-logo,
      .content-wrapper-terms-of-use .welcome-page-wrapper .terms-of-use-page-header .welcome-page-logo,
      .content-wrapper-terms-of-use .welcome-page-wrapper .terms-of-use-page-header .terms-of-use-page-logo,
      .content-wrapper-terms-of-use .terms-of-use-page-wrapper .welcome-page-header .welcome-page-logo,
      .content-wrapper-terms-of-use .terms-of-use-page-wrapper .welcome-page-header .terms-of-use-page-logo,
      .content-wrapper-terms-of-use .terms-of-use-page-wrapper .terms-of-use-page-header .welcome-page-logo,
      .content-wrapper-terms-of-use .terms-of-use-page-wrapper .terms-of-use-page-header .terms-of-use-page-logo {
        width: 10rem; }
        @media (max-width: 767px) {
          .content-wrapper-welcome .welcome-page-wrapper .welcome-page-header .welcome-page-logo,
          .content-wrapper-welcome .welcome-page-wrapper .welcome-page-header .terms-of-use-page-logo,
          .content-wrapper-welcome .welcome-page-wrapper .terms-of-use-page-header .welcome-page-logo,
          .content-wrapper-welcome .welcome-page-wrapper .terms-of-use-page-header .terms-of-use-page-logo,
          .content-wrapper-welcome .terms-of-use-page-wrapper .welcome-page-header .welcome-page-logo,
          .content-wrapper-welcome .terms-of-use-page-wrapper .welcome-page-header .terms-of-use-page-logo,
          .content-wrapper-welcome .terms-of-use-page-wrapper .terms-of-use-page-header .welcome-page-logo,
          .content-wrapper-welcome .terms-of-use-page-wrapper .terms-of-use-page-header .terms-of-use-page-logo,
          .content-wrapper-terms-of-use .welcome-page-wrapper .welcome-page-header .welcome-page-logo,
          .content-wrapper-terms-of-use .welcome-page-wrapper .welcome-page-header .terms-of-use-page-logo,
          .content-wrapper-terms-of-use .welcome-page-wrapper .terms-of-use-page-header .welcome-page-logo,
          .content-wrapper-terms-of-use .welcome-page-wrapper .terms-of-use-page-header .terms-of-use-page-logo,
          .content-wrapper-terms-of-use .terms-of-use-page-wrapper .welcome-page-header .welcome-page-logo,
          .content-wrapper-terms-of-use .terms-of-use-page-wrapper .welcome-page-header .terms-of-use-page-logo,
          .content-wrapper-terms-of-use .terms-of-use-page-wrapper .terms-of-use-page-header .welcome-page-logo,
          .content-wrapper-terms-of-use .terms-of-use-page-wrapper .terms-of-use-page-header .terms-of-use-page-logo {
            display: block;
            margin: auto;
            width: 9rem; } }
    .content-wrapper-welcome .welcome-page-wrapper .welcome-page-image-section,
    .content-wrapper-welcome .welcome-page-wrapper .terms-of-use-image-section,
    .content-wrapper-welcome .terms-of-use-page-wrapper .welcome-page-image-section,
    .content-wrapper-welcome .terms-of-use-page-wrapper .terms-of-use-image-section,
    .content-wrapper-terms-of-use .welcome-page-wrapper .welcome-page-image-section,
    .content-wrapper-terms-of-use .welcome-page-wrapper .terms-of-use-image-section,
    .content-wrapper-terms-of-use .terms-of-use-page-wrapper .welcome-page-image-section,
    .content-wrapper-terms-of-use .terms-of-use-page-wrapper .terms-of-use-image-section {
      background-color: #ffffff; }
      .content-wrapper-welcome .welcome-page-wrapper .welcome-page-image-section .welcome-page-image img,
      .content-wrapper-welcome .welcome-page-wrapper .terms-of-use-image-section .welcome-page-image img,
      .content-wrapper-welcome .terms-of-use-page-wrapper .welcome-page-image-section .welcome-page-image img,
      .content-wrapper-welcome .terms-of-use-page-wrapper .terms-of-use-image-section .welcome-page-image img,
      .content-wrapper-terms-of-use .welcome-page-wrapper .welcome-page-image-section .welcome-page-image img,
      .content-wrapper-terms-of-use .welcome-page-wrapper .terms-of-use-image-section .welcome-page-image img,
      .content-wrapper-terms-of-use .terms-of-use-page-wrapper .welcome-page-image-section .welcome-page-image img,
      .content-wrapper-terms-of-use .terms-of-use-page-wrapper .terms-of-use-image-section .welcome-page-image img {
        max-width: 100%;
        height: auto; }
      .content-wrapper-welcome .welcome-page-wrapper .welcome-page-image-section .terms-of-use-page-image,
      .content-wrapper-welcome .welcome-page-wrapper .terms-of-use-image-section .terms-of-use-page-image,
      .content-wrapper-welcome .terms-of-use-page-wrapper .welcome-page-image-section .terms-of-use-page-image,
      .content-wrapper-welcome .terms-of-use-page-wrapper .terms-of-use-image-section .terms-of-use-page-image,
      .content-wrapper-terms-of-use .welcome-page-wrapper .welcome-page-image-section .terms-of-use-page-image,
      .content-wrapper-terms-of-use .welcome-page-wrapper .terms-of-use-image-section .terms-of-use-page-image,
      .content-wrapper-terms-of-use .terms-of-use-page-wrapper .welcome-page-image-section .terms-of-use-page-image,
      .content-wrapper-terms-of-use .terms-of-use-page-wrapper .terms-of-use-image-section .terms-of-use-page-image {
        max-width: 100%;
        height: auto; }
    .content-wrapper-welcome .welcome-page-wrapper .welcome-page-content,
    .content-wrapper-welcome .terms-of-use-page-wrapper .welcome-page-content,
    .content-wrapper-terms-of-use .welcome-page-wrapper .welcome-page-content,
    .content-wrapper-terms-of-use .terms-of-use-page-wrapper .welcome-page-content {
      background-color: #ffffff;
      padding: 5rem 8rem 6rem 8rem;
      border-radius: 0 0 5px 5px; }
      @media (max-width: 767px) {
        .content-wrapper-welcome .welcome-page-wrapper .welcome-page-content,
        .content-wrapper-welcome .terms-of-use-page-wrapper .welcome-page-content,
        .content-wrapper-terms-of-use .welcome-page-wrapper .welcome-page-content,
        .content-wrapper-terms-of-use .terms-of-use-page-wrapper .welcome-page-content {
          padding: 3rem 0 3rem 0; } }
      .content-wrapper-welcome .welcome-page-wrapper .welcome-page-content .welcome-page-title,
      .content-wrapper-welcome .terms-of-use-page-wrapper .welcome-page-content .welcome-page-title,
      .content-wrapper-terms-of-use .welcome-page-wrapper .welcome-page-content .welcome-page-title,
      .content-wrapper-terms-of-use .terms-of-use-page-wrapper .welcome-page-content .welcome-page-title {
        font-size: 3.5rem;
        color: #000000;
        font-family: "LatoWeb", sans-serif;
        font-weight: 100; }
        @media (max-width: 767px) {
          .content-wrapper-welcome .welcome-page-wrapper .welcome-page-content .welcome-page-title,
          .content-wrapper-welcome .terms-of-use-page-wrapper .welcome-page-content .welcome-page-title,
          .content-wrapper-terms-of-use .welcome-page-wrapper .welcome-page-content .welcome-page-title,
          .content-wrapper-terms-of-use .terms-of-use-page-wrapper .welcome-page-content .welcome-page-title {
            display: table;
            margin: auto; } }
      .content-wrapper-welcome .welcome-page-wrapper .welcome-page-content .welcome-page-description,
      .content-wrapper-welcome .terms-of-use-page-wrapper .welcome-page-content .welcome-page-description,
      .content-wrapper-terms-of-use .welcome-page-wrapper .welcome-page-content .welcome-page-description,
      .content-wrapper-terms-of-use .terms-of-use-page-wrapper .welcome-page-content .welcome-page-description {
        color: #000000;
        font-size: 1rem;
        font-family: "LatoWeb", sans-serif; }
        @media (max-width: 767px) {
          .content-wrapper-welcome .welcome-page-wrapper .welcome-page-content .welcome-page-description,
          .content-wrapper-welcome .terms-of-use-page-wrapper .welcome-page-content .welcome-page-description,
          .content-wrapper-terms-of-use .welcome-page-wrapper .welcome-page-content .welcome-page-description,
          .content-wrapper-terms-of-use .terms-of-use-page-wrapper .welcome-page-content .welcome-page-description {
            margin: 15px;
            display: table; } }
      .content-wrapper-welcome .welcome-page-wrapper .welcome-page-content .buttons-container,
      .content-wrapper-welcome .terms-of-use-page-wrapper .welcome-page-content .buttons-container,
      .content-wrapper-terms-of-use .welcome-page-wrapper .welcome-page-content .buttons-container,
      .content-wrapper-terms-of-use .terms-of-use-page-wrapper .welcome-page-content .buttons-container {
        padding-left: 0 !important; }
        @media (max-width: 767px) {
          .content-wrapper-welcome .welcome-page-wrapper .welcome-page-content .buttons-container,
          .content-wrapper-welcome .terms-of-use-page-wrapper .welcome-page-content .buttons-container,
          .content-wrapper-terms-of-use .welcome-page-wrapper .welcome-page-content .buttons-container,
          .content-wrapper-terms-of-use .terms-of-use-page-wrapper .welcome-page-content .buttons-container {
            padding-left: 15px !important; } }
      .content-wrapper-welcome .welcome-page-wrapper .welcome-page-content .welcome-page-text-decoration,
      .content-wrapper-welcome .terms-of-use-page-wrapper .welcome-page-content .welcome-page-text-decoration,
      .content-wrapper-terms-of-use .welcome-page-wrapper .welcome-page-content .welcome-page-text-decoration,
      .content-wrapper-terms-of-use .terms-of-use-page-wrapper .welcome-page-content .welcome-page-text-decoration {
        text-decoration: none;
        display: inline; }
        .content-wrapper-welcome .welcome-page-wrapper .welcome-page-content .welcome-page-text-decoration .welcome-page-button,
        .content-wrapper-welcome .terms-of-use-page-wrapper .welcome-page-content .welcome-page-text-decoration .welcome-page-button,
        .content-wrapper-terms-of-use .welcome-page-wrapper .welcome-page-content .welcome-page-text-decoration .welcome-page-button,
        .content-wrapper-terms-of-use .terms-of-use-page-wrapper .welcome-page-content .welcome-page-text-decoration .welcome-page-button {
          width: 135px;
          background-color: #c12016;
          color: #ffffff;
          font-size: 1rem;
          font-weight: bold;
          font-family: "LatoWeb", sans-serif;
          padding: 1rem;
          border: none;
          border-radius: 5px;
          cursor: pointer;
          margin-top: 1.8rem; }
          @media (max-width: 767px) {
            .content-wrapper-welcome .welcome-page-wrapper .welcome-page-content .welcome-page-text-decoration .welcome-page-button,
            .content-wrapper-welcome .terms-of-use-page-wrapper .welcome-page-content .welcome-page-text-decoration .welcome-page-button,
            .content-wrapper-terms-of-use .welcome-page-wrapper .welcome-page-content .welcome-page-text-decoration .welcome-page-button,
            .content-wrapper-terms-of-use .terms-of-use-page-wrapper .welcome-page-content .welcome-page-text-decoration .welcome-page-button {
              width: 49%;
              display: inline;
              margin: 2rem auto auto auto; } }
      .content-wrapper-welcome .welcome-page-wrapper .welcome-page-content .welcome-contact-button,
      .content-wrapper-welcome .terms-of-use-page-wrapper .welcome-page-content .welcome-contact-button,
      .content-wrapper-terms-of-use .welcome-page-wrapper .welcome-page-content .welcome-contact-button,
      .content-wrapper-terms-of-use .terms-of-use-page-wrapper .welcome-page-content .welcome-contact-button {
        color: #000000;
        display: inline-block;
        font-family: "Lato", sans-serif;
        margin-left: 20px; }
        @media (max-width: 767px) {
          .content-wrapper-welcome .welcome-page-wrapper .welcome-page-content .welcome-contact-button,
          .content-wrapper-welcome .terms-of-use-page-wrapper .welcome-page-content .welcome-contact-button,
          .content-wrapper-terms-of-use .welcome-page-wrapper .welcome-page-content .welcome-contact-button,
          .content-wrapper-terms-of-use .terms-of-use-page-wrapper .welcome-page-content .welcome-contact-button {
            display: inline;
            margin: 2rem auto; } }
    .content-wrapper-welcome .welcome-page-wrapper .terms-of-use-page-content,
    .content-wrapper-welcome .terms-of-use-page-wrapper .terms-of-use-page-content,
    .content-wrapper-terms-of-use .welcome-page-wrapper .terms-of-use-page-content,
    .content-wrapper-terms-of-use .terms-of-use-page-wrapper .terms-of-use-page-content {
      background-color: #ffffff;
      padding: 4rem 8rem 6rem 6rem;
      border-radius: 0 0 5px 5px; }
      @media (max-width: 767px) {
        .content-wrapper-welcome .welcome-page-wrapper .terms-of-use-page-content,
        .content-wrapper-welcome .terms-of-use-page-wrapper .terms-of-use-page-content,
        .content-wrapper-terms-of-use .welcome-page-wrapper .terms-of-use-page-content,
        .content-wrapper-terms-of-use .terms-of-use-page-wrapper .terms-of-use-page-content {
          padding: 3rem 2rem 4rem 2rem; } }
      .content-wrapper-welcome .welcome-page-wrapper .terms-of-use-page-content .terms-of-use-page-title,
      .content-wrapper-welcome .terms-of-use-page-wrapper .terms-of-use-page-content .terms-of-use-page-title,
      .content-wrapper-terms-of-use .welcome-page-wrapper .terms-of-use-page-content .terms-of-use-page-title,
      .content-wrapper-terms-of-use .terms-of-use-page-wrapper .terms-of-use-page-content .terms-of-use-page-title {
        font-size: 3.5rem;
        color: #000000;
        font-family: "LatoWeb", sans-serif;
        font-weight: 100;
        padding-bottom: 0.2rem; }
        @media (max-width: 767px) {
          .content-wrapper-welcome .welcome-page-wrapper .terms-of-use-page-content .terms-of-use-page-title,
          .content-wrapper-welcome .terms-of-use-page-wrapper .terms-of-use-page-content .terms-of-use-page-title,
          .content-wrapper-terms-of-use .welcome-page-wrapper .terms-of-use-page-content .terms-of-use-page-title,
          .content-wrapper-terms-of-use .terms-of-use-page-wrapper .terms-of-use-page-content .terms-of-use-page-title {
            display: table;
            margin: auto;
            font-size: 2.5rem; } }
      .content-wrapper-welcome .welcome-page-wrapper .terms-of-use-page-content .terms-of-use,
      .content-wrapper-welcome .terms-of-use-page-wrapper .terms-of-use-page-content .terms-of-use,
      .content-wrapper-terms-of-use .welcome-page-wrapper .terms-of-use-page-content .terms-of-use,
      .content-wrapper-terms-of-use .terms-of-use-page-wrapper .terms-of-use-page-content .terms-of-use {
        background-color: #FFF;
        padding: 20px;
        max-height: 250px;
        overflow-x: auto;
        color: #000000;
        border: 1px solid rgba(0, 0, 0, 0.2); }
        .content-wrapper-welcome .welcome-page-wrapper .terms-of-use-page-content .terms-of-use p,
        .content-wrapper-welcome .terms-of-use-page-wrapper .terms-of-use-page-content .terms-of-use p,
        .content-wrapper-terms-of-use .welcome-page-wrapper .terms-of-use-page-content .terms-of-use p,
        .content-wrapper-terms-of-use .terms-of-use-page-wrapper .terms-of-use-page-content .terms-of-use p {
          margin-bottom: 0.3rem;
          color: #000000; }
      .content-wrapper-welcome .welcome-page-wrapper .terms-of-use-page-content .terms-of-use-accept,
      .content-wrapper-welcome .terms-of-use-page-wrapper .terms-of-use-page-content .terms-of-use-accept,
      .content-wrapper-terms-of-use .welcome-page-wrapper .terms-of-use-page-content .terms-of-use-accept,
      .content-wrapper-terms-of-use .terms-of-use-page-wrapper .terms-of-use-page-content .terms-of-use-accept {
        margin-top: 20px;
        display: flex;
        margin-left: 2px; }
        @media (max-width: 767px) {
          .content-wrapper-welcome .welcome-page-wrapper .terms-of-use-page-content .terms-of-use-accept,
          .content-wrapper-welcome .terms-of-use-page-wrapper .terms-of-use-page-content .terms-of-use-accept,
          .content-wrapper-terms-of-use .welcome-page-wrapper .terms-of-use-page-content .terms-of-use-accept,
          .content-wrapper-terms-of-use .terms-of-use-page-wrapper .terms-of-use-page-content .terms-of-use-accept {
            padding: 1rem 0 0 1.5rem; } }
        .content-wrapper-welcome .welcome-page-wrapper .terms-of-use-page-content .terms-of-use-accept .check-image,
        .content-wrapper-welcome .terms-of-use-page-wrapper .terms-of-use-page-content .terms-of-use-accept .check-image,
        .content-wrapper-terms-of-use .welcome-page-wrapper .terms-of-use-page-content .terms-of-use-accept .check-image,
        .content-wrapper-terms-of-use .terms-of-use-page-wrapper .terms-of-use-page-content .terms-of-use-accept .check-image {
          width: 27px;
          height: 27px; }
        .content-wrapper-welcome .welcome-page-wrapper .terms-of-use-page-content .terms-of-use-accept .terms-item,
        .content-wrapper-welcome .terms-of-use-page-wrapper .terms-of-use-page-content .terms-of-use-accept .terms-item,
        .content-wrapper-terms-of-use .welcome-page-wrapper .terms-of-use-page-content .terms-of-use-accept .terms-item,
        .content-wrapper-terms-of-use .terms-of-use-page-wrapper .terms-of-use-page-content .terms-of-use-accept .terms-item {
          opacity: 0;
          margin-right: -1.6rem;
          margin-top: 0.2rem;
          height: 19px;
          width: 19px;
          margin-left: 3px; }
        .content-wrapper-welcome .welcome-page-wrapper .terms-of-use-page-content .terms-of-use-accept .terms-checkbox,
        .content-wrapper-welcome .terms-of-use-page-wrapper .terms-of-use-page-content .terms-of-use-accept .terms-checkbox,
        .content-wrapper-terms-of-use .welcome-page-wrapper .terms-of-use-page-content .terms-of-use-accept .terms-checkbox,
        .content-wrapper-terms-of-use .terms-of-use-page-wrapper .terms-of-use-page-content .terms-of-use-accept .terms-checkbox {
          border-radius: 50%;
          width: 24px;
          height: 24px;
          border: solid 2px #979797; }
        .content-wrapper-welcome .welcome-page-wrapper .terms-of-use-page-content .terms-of-use-accept span,
        .content-wrapper-welcome .terms-of-use-page-wrapper .terms-of-use-page-content .terms-of-use-accept span,
        .content-wrapper-terms-of-use .welcome-page-wrapper .terms-of-use-page-content .terms-of-use-accept span,
        .content-wrapper-terms-of-use .terms-of-use-page-wrapper .terms-of-use-page-content .terms-of-use-accept span {
          color: #000000;
          margin-left: 10px;
          font-size: 1.125rem;
          margin-top: 3px;
          cursor: pointer; }
      .content-wrapper-welcome .welcome-page-wrapper .terms-of-use-page-content .terms-of-use-page-text-decoration,
      .content-wrapper-welcome .terms-of-use-page-wrapper .terms-of-use-page-content .terms-of-use-page-text-decoration,
      .content-wrapper-terms-of-use .welcome-page-wrapper .terms-of-use-page-content .terms-of-use-page-text-decoration,
      .content-wrapper-terms-of-use .terms-of-use-page-wrapper .terms-of-use-page-content .terms-of-use-page-text-decoration {
        text-decoration: none;
        margin-left: 2px; }
        @media (max-width: 767px) {
          .content-wrapper-welcome .welcome-page-wrapper .terms-of-use-page-content .terms-of-use-page-text-decoration,
          .content-wrapper-welcome .terms-of-use-page-wrapper .terms-of-use-page-content .terms-of-use-page-text-decoration,
          .content-wrapper-terms-of-use .welcome-page-wrapper .terms-of-use-page-content .terms-of-use-page-text-decoration,
          .content-wrapper-terms-of-use .terms-of-use-page-wrapper .terms-of-use-page-content .terms-of-use-page-text-decoration {
            padding: 1rem 0 0 1.5rem; } }
        .content-wrapper-welcome .welcome-page-wrapper .terms-of-use-page-content .terms-of-use-page-text-decoration .terms-of-use-enter-button,
        .content-wrapper-welcome .terms-of-use-page-wrapper .terms-of-use-page-content .terms-of-use-page-text-decoration .terms-of-use-enter-button,
        .content-wrapper-terms-of-use .welcome-page-wrapper .terms-of-use-page-content .terms-of-use-page-text-decoration .terms-of-use-enter-button,
        .content-wrapper-terms-of-use .terms-of-use-page-wrapper .terms-of-use-page-content .terms-of-use-page-text-decoration .terms-of-use-enter-button {
          width: 30%;
          background-color: #c12016;
          color: #ffffff;
          font-size: 1rem;
          font-weight: bold;
          font-family: "LatoWeb", sans-serif;
          padding: 1rem;
          border: 1px solid #ffffff;
          border-radius: 5px;
          cursor: pointer;
          margin-top: 1.8rem; }
          @media (max-width: 767px) {
            .content-wrapper-welcome .welcome-page-wrapper .terms-of-use-page-content .terms-of-use-page-text-decoration .terms-of-use-enter-button,
            .content-wrapper-welcome .terms-of-use-page-wrapper .terms-of-use-page-content .terms-of-use-page-text-decoration .terms-of-use-enter-button,
            .content-wrapper-terms-of-use .welcome-page-wrapper .terms-of-use-page-content .terms-of-use-page-text-decoration .terms-of-use-enter-button,
            .content-wrapper-terms-of-use .terms-of-use-page-wrapper .terms-of-use-page-content .terms-of-use-page-text-decoration .terms-of-use-enter-button {
              width: 45%;
              margin: 2rem auto auto auto; } }
          .content-wrapper-welcome .welcome-page-wrapper .terms-of-use-page-content .terms-of-use-page-text-decoration .terms-of-use-enter-button:disabled,
          .content-wrapper-welcome .terms-of-use-page-wrapper .terms-of-use-page-content .terms-of-use-page-text-decoration .terms-of-use-enter-button:disabled,
          .content-wrapper-terms-of-use .welcome-page-wrapper .terms-of-use-page-content .terms-of-use-page-text-decoration .terms-of-use-enter-button:disabled,
          .content-wrapper-terms-of-use .terms-of-use-page-wrapper .terms-of-use-page-content .terms-of-use-page-text-decoration .terms-of-use-enter-button:disabled {
            cursor: default; }
        .content-wrapper-welcome .welcome-page-wrapper .terms-of-use-page-content .terms-of-use-page-text-decoration .terms-of-use-cancel-button,
        .content-wrapper-welcome .terms-of-use-page-wrapper .terms-of-use-page-content .terms-of-use-page-text-decoration .terms-of-use-cancel-button,
        .content-wrapper-terms-of-use .welcome-page-wrapper .terms-of-use-page-content .terms-of-use-page-text-decoration .terms-of-use-cancel-button,
        .content-wrapper-terms-of-use .terms-of-use-page-wrapper .terms-of-use-page-content .terms-of-use-page-text-decoration .terms-of-use-cancel-button {
          width: 30%;
          background-color: #8e1230;
          color: #ffffff;
          font-size: 1rem;
          font-weight: bold;
          font-family: "LatoWeb", sans-serif;
          padding: 1rem;
          border-radius: 5px;
          cursor: pointer;
          margin-top: 1.8rem;
          border: 1px solid #ffffff;
          margin-left: 10px; }
          @media (max-width: 767px) {
            .content-wrapper-welcome .welcome-page-wrapper .terms-of-use-page-content .terms-of-use-page-text-decoration .terms-of-use-cancel-button,
            .content-wrapper-welcome .terms-of-use-page-wrapper .terms-of-use-page-content .terms-of-use-page-text-decoration .terms-of-use-cancel-button,
            .content-wrapper-terms-of-use .welcome-page-wrapper .terms-of-use-page-content .terms-of-use-page-text-decoration .terms-of-use-cancel-button,
            .content-wrapper-terms-of-use .terms-of-use-page-wrapper .terms-of-use-page-content .terms-of-use-page-text-decoration .terms-of-use-cancel-button {
              width: 45%; } }

.welcome-message-label {
  width: 30%;
  float: left;
  display: inline-block; }

.terms-checked {
  background: url("../images/check2.svg");
  background-repeat: no-repeat;
  background-size: 1.6rem;
  background-position: center;
  border-color: transparent !important; }

#invalid-email-welcome,
#firstName,
#lastName,
#companyError,
#countryError,
#textareaError {
  color: #c12016;
  font-weight: 700; }

body.print {
  /*  .panel {
    background-color: #FFF !important;
    border: 1px solid #F4F2EE !important;
  }*/
  /*  .tab-content {
    background-color: #FFF;
  }*/
  /*  .print-full-width {
    max-width: none;
    flex: 0 0 100%;
  }*/ }
  body.print .print-none {
    display: none !important; }
  body.print .print-empty {
    visibility: hidden !important; }
  body.print .print-all {
    max-height: none !important;
    height: auto !important;
    overflow-y: auto !important; }
  body.print #myTab {
    display: none !important; }
  body.print #myTabContent .tab-pane {
    display: block !important;
    border-bottom: solid 2px #9b9b9b;
    margin-bottom: 2rem;
    transition: none;
    opacity: 1; }
  body.print .tab-exposure .tab-pane {
    display: block !important;
    margin-bottom: 2rem;
    transition: none;
    opacity: 1; }
  body.print .show-for-print {
    display: block; }
  body.print * {
    color: #000 !important; }
  body.print .dropdown .btn {
    background-color: #990000 !important;
    color: #FFF !important; }
    body.print .dropdown .btn * {
      color: #FFF !important; }

.cb-button {
  color: black;
  font-family: RobotoBold, sans-serif;
  padding: 15px;
  width: auto;
  white-space: nowrap; }
  @media (max-width: 575px) {
    .cb-button {
      padding: 2px; } }
  .cb-button a {
    color: black; }
    .cb-button a:hover, .cb-button a:active, .cb-button a:focus {
      color: darkred;
      text-decoration: underline;
      cursor: pointer; }

.forgotusername-page-body-bg {
  background-color: #f6f4f0; }
  .forgotusername-page-body-bg .forgotusername-page-wrapper {
    width: 65%;
    margin: 4% auto;
    box-shadow: 4px 4px 11px 0 rgba(0, 0, 0, 0.35);
    border-radius: 5px; }
    @media (max-width: 991px) {
      .forgotusername-page-body-bg .forgotusername-page-wrapper {
        width: 95%; } }
    .forgotusername-page-body-bg .forgotusername-page-wrapper .forgotusername-page-header {
      background-color: #ffffff;
      padding: 2rem 4rem 2rem 4rem;
      border-bottom: 2px solid #C21B17;
      border-radius: 5px 5px 0 0; }
      @media (max-width: 991px) {
        .forgotusername-page-body-bg .forgotusername-page-wrapper .forgotusername-page-header {
          padding: 4rem 6rem 4rem 6rem; } }
      @media (max-width: 767px) {
        .forgotusername-page-body-bg .forgotusername-page-wrapper .forgotusername-page-header {
          padding: 4rem 0 0 0;
          height: 20rem; } }
      .forgotusername-page-body-bg .forgotusername-page-wrapper .forgotusername-page-header .forgotusername-page-logo {
        width: 10rem;
        margin-left: auto;
        margin-right: auto;
        display: block; }
        @media (max-width: 767px) {
          .forgotusername-page-body-bg .forgotusername-page-wrapper .forgotusername-page-header .forgotusername-page-logo {
            display: block;
            margin: auto;
            width: 12rem; } }
    .forgotusername-page-body-bg .forgotusername-page-wrapper .forgotusername-page-content {
      background-color: #ffffff;
      padding: 6rem 8rem 6rem 8rem;
      border-radius: 0 0 5px 5px;
      margin-top: 2px; }
      @media (max-width: 991px) {
        .forgotusername-page-body-bg .forgotusername-page-wrapper .forgotusername-page-content {
          padding: 4rem 6rem 4rem 6rem; } }
      @media (max-width: 767px) {
        .forgotusername-page-body-bg .forgotusername-page-wrapper .forgotusername-page-content {
          padding: 3rem 0 3rem 0; } }
      .forgotusername-page-body-bg .forgotusername-page-wrapper .forgotusername-page-content .reset-page-title {
        font-size: 3.5rem;
        color: #ffffff;
        font-family: "LatoWeb", sans-serif;
        text-align: left; }
        @media (max-width: 767px) {
          .forgotusername-page-body-bg .forgotusername-page-wrapper .forgotusername-page-content .reset-page-title {
            margin-left: 1rem; } }
      .forgotusername-page-body-bg .forgotusername-page-wrapper .forgotusername-page-content .reset-page-text {
        font-size: 1.2rem;
        color: #ffffff;
        font-family: "LatoWeb", sans-serif;
        text-align: left;
        padding: 1rem 0; }
        @media (max-width: 767px) {
          .forgotusername-page-body-bg .forgotusername-page-wrapper .forgotusername-page-content .reset-page-text {
            margin-left: 1rem; } }
      @media (max-width: 767px) {
        .forgotusername-page-body-bg .forgotusername-page-wrapper .forgotusername-page-content .forgot-page-form {
          padding: 1rem;
          height: 21rem; } }
      .forgotusername-page-body-bg .forgotusername-page-wrapper .forgotusername-page-content .forgot-page-form .login-page-input {
        border: 0;
        outline: 0;
        background: transparent;
        border-bottom: 2px solid #ffffff;
        width: 100%;
        padding-top: 1rem;
        color: #ffffff; }
        @media (max-width: 991px) {
          .forgotusername-page-body-bg .forgotusername-page-wrapper .forgotusername-page-content .forgot-page-form .login-page-input {
            width: 100%; } }
        @media (max-width: 767px) {
          .forgotusername-page-body-bg .forgotusername-page-wrapper .forgotusername-page-content .forgot-page-form .login-page-input {
            width: 100%; } }
        .forgotusername-page-body-bg .forgotusername-page-wrapper .forgotusername-page-content .forgot-page-form .login-page-input::-webkit-input-placeholder {
          color: white; }
        .forgotusername-page-body-bg .forgotusername-page-wrapper .forgotusername-page-content .forgot-page-form .login-page-input:-ms-input-placeholder {
          color: white; }
        .forgotusername-page-body-bg .forgotusername-page-wrapper .forgotusername-page-content .forgot-page-form .login-page-input::-moz-placeholder {
          color: white; }
        .forgotusername-page-body-bg .forgotusername-page-wrapper .forgotusername-page-content .forgot-page-form .login-page-input:focus {
          background-color: #B83628; }
      .forgotusername-page-body-bg .forgotusername-page-wrapper .forgotusername-page-content .forgot-page-form .login-page-button {
        width: 38%;
        background-color: #ffffff;
        color: #B83628;
        font-size: 1rem;
        font-weight: bold;
        font-family: "LatoWeb", sans-serif;
        padding: 1rem;
        border: none;
        border-radius: 5px;
        margin-top: 2.8rem; }
        @media (max-width: 767px) {
          .forgotusername-page-body-bg .forgotusername-page-wrapper .forgotusername-page-content .forgot-page-form .login-page-button {
            width: 100%;
            margin-top: 2rem; } }
      .forgotusername-page-body-bg .forgotusername-page-wrapper .forgotusername-page-content .forgot-page-form #invalid-email {
        color: #C21B17;
        margin-bottom: 5px;
        font-weight: bold; }
      .forgotusername-page-body-bg .forgotusername-page-wrapper .forgotusername-page-content .reset-comeback-link {
        margin-top: 2rem; }
        @media (max-width: 767px) {
          .forgotusername-page-body-bg .forgotusername-page-wrapper .forgotusername-page-content .reset-comeback-link {
            padding: 1rem;
            margin-top: 0; } }
        .forgotusername-page-body-bg .forgotusername-page-wrapper .forgotusername-page-content .reset-comeback-link .reset-page-link-c {
          color: #ffffff;
          font-size: 1rem;
          font-family: "LatoWeb", sans-serif; }
          .forgotusername-page-body-bg .forgotusername-page-wrapper .forgotusername-page-content .reset-comeback-link .reset-page-link-c span {
            opacity: 0.5;
            text-decoration: underline; }
      .forgotusername-page-body-bg .forgotusername-page-wrapper .forgotusername-page-content .forgot-username-mandatory {
        color: #ffffff;
        font-weight: 700; }
      .forgotusername-page-body-bg .forgotusername-page-wrapper .forgotusername-page-content .error-msg-forgot-username {
        color: #ffffff; }
      .forgotusername-page-body-bg .forgotusername-page-wrapper .forgotusername-page-content #okta-sign-in {
        font-size: 13px;
        font-weight: 400;
        line-height: 1.4;
        background-color: #f9f9f9;
        color: #777;
        position: relative;
        overflow: auto;
        border-radius: 3px;
        border-style: solid;
        border-width: 1px;
        height: auto;
        margin: 8px auto 8px;
        width: 400px;
        min-width: 300px; }
        .forgotusername-page-body-bg .forgotusername-page-wrapper .forgotusername-page-content #okta-sign-in.main-container {
          background-color: #fff;
          border-color: #ddd #ddd #d8d8d8;
          box-shadow: 0 2px 0 rgba(175, 175, 175, 0.12);
          color: #777; }
        .forgotusername-page-body-bg .forgotusername-page-wrapper .forgotusername-page-content #okta-sign-in .o-form-theme {
          background: transparent;
          box-shadow: none;
          padding: 0;
          border-radius: 0;
          border: none; }
        .forgotusername-page-body-bg .forgotusername-page-wrapper .forgotusername-page-content #okta-sign-in .okta-title {
          font-size: 2.9rem;
          color: #C21B17;
          font-family: "LatoWeb", sans-serif;
          font-weight: 100;
          line-height: 1.5;
          margin-top: 10px;
          text-transform: none; }
        .forgotusername-page-body-bg .forgotusername-page-wrapper .forgotusername-page-content #okta-sign-in .o-form-input {
          width: 100%; }
        .forgotusername-page-body-bg .forgotusername-page-wrapper .forgotusername-page-content #okta-sign-in .input-fix {
          border-radius: 3px;
          width: 100%;
          box-sizing: border-box;
          height: 40px;
          border: 1px solid #bbb;
          display: inline-block;
          float: left; }
        .forgotusername-page-body-bg .forgotusername-page-wrapper .forgotusername-page-content #okta-sign-in .okta-form-input-field {
          background-color: #fff;
          border-color: #bbb; }
        .forgotusername-page-body-bg .forgotusername-page-wrapper .forgotusername-page-content #okta-sign-in .o-form-control {
          position: relative;
          margin-bottom: 15px; }
          .forgotusername-page-body-bg .forgotusername-page-wrapper .forgotusername-page-content #okta-sign-in .o-form-control input {
            font-size: 13px;
            color: #5e5e5e; }
            .forgotusername-page-body-bg .forgotusername-page-wrapper .forgotusername-page-content #okta-sign-in .o-form-control input::-webkit-input-placeholder {
              font-weight: 700;
              color: #5e5e5e; }
            .forgotusername-page-body-bg .forgotusername-page-wrapper .forgotusername-page-content #okta-sign-in .o-form-control input:-ms-input-placeholder {
              font-weight: 700;
              color: #5e5e5e; }
            .forgotusername-page-body-bg .forgotusername-page-wrapper .forgotusername-page-content #okta-sign-in .o-form-control input::-moz-placeholder {
              font-weight: 700;
              color: #5e5e5e; }
          .forgotusername-page-body-bg .forgotusername-page-wrapper .forgotusername-page-content #okta-sign-in .o-form-control input[type="text"],
          .forgotusername-page-body-bg .forgotusername-page-wrapper .forgotusername-page-content #okta-sign-in .o-form-control input[type="email"] {
            height: 100%;
            width: 80%;
            margin: 0;
            padding: 20px 8px;
            line-height: 16px;
            border: none;
            background: transparent;
            box-sizing: border-box; }
        .forgotusername-page-body-bg .forgotusername-page-wrapper .forgotusername-page-content #okta-sign-in .o-form-button-bar {
          background: transparent;
          border: none;
          padding: 0 0 25px;
          border-radius: 0;
          box-shadow: none; }
          .forgotusername-page-body-bg .forgotusername-page-wrapper .forgotusername-page-content #okta-sign-in .o-form-button-bar button {
            box-sizing: border-box;
            -webkit-appearance: none;
            line-height: 36px;
            padding: 0 15px;
            border: 1px solid;
            border-radius: 3px;
            font-size: 13px;
            display: block;
            width: 100%;
            height: 50px;
            background: linear-gradient(#C21B17, #C21B17);
            border-color: #C21B17;
            border-bottom-color: #C21B17;
            box-shadow: rgba(0, 0, 0, 0.15) 0 1px 0, rgba(255, 255, 255, 0.1) 0 1px 0 0 inset;
            color: #ffffff;
            background-color: #ffffff; }
        .forgotusername-page-body-bg .forgotusername-page-wrapper .forgotusername-page-content #okta-sign-in .person-16-gray {
          width: 16px;
          height: 16px;
          position: absolute;
          top: 50%;
          margin-top: -11px;
          left: 10px;
          opacity: .25; }
          .forgotusername-page-body-bg .forgotusername-page-wrapper .forgotusername-page-content #okta-sign-in .person-16-gray:before {
            content: "\e00c";
            color: #a7a7a7; }
        .forgotusername-page-body-bg .forgotusername-page-wrapper .forgotusername-page-content #okta-sign-in .forgotusername-tip {
          text-align: right;
          width: 20px;
          height: 20px;
          line-height: 18px;
          cursor: default;
          margin-top: 10px; }
      .forgotusername-page-body-bg .forgotusername-page-wrapper .forgotusername-page-content .auth-content {
        padding: 20px 42px;
        transition: padding-top .4s; }
        .forgotusername-page-body-bg .forgotusername-page-wrapper .forgotusername-page-content .auth-content .auth-content-inner {
          position: relative; }

#error-msg {
  color: #C21B17;
  margin-bottom: 0;
  font-weight: bold; }

.cookie-container {
  background: #c12017 none repeat scroll 0 0;
  display: none;
  left: 0;
  margin: 30px auto;
  position: absolute;
  top: 50px;
  width: 100%;
  z-index: 99; }
  .cookie-container .wrapper-content {
    padding-right: 0;
    width: 95%;
    padding-left: 0 !important;
    padding-top: 0 !important;
    margin-left: auto;
    margin-right: auto; }
    .cookie-container .wrapper-content .margin-lat-0 {
      margin-left: 0 !important;
      margin-right: 0 !important; }
      .cookie-container .wrapper-content .margin-lat-0 .cookies {
        color: #fff;
        padding: 30px 60px 30px 20px;
        position: relative; }
        .cookie-container .wrapper-content .margin-lat-0 .cookies .accetto-cookie-uk {
          background: #fff none repeat scroll 0 0;
          color: #3b3b3b;
          font-size: 18px;
          height: 30px;
          line-height: 18px;
          padding: 5px 8px;
          position: absolute;
          right: 10px;
          text-align: center;
          text-decoration: none;
          text-transform: uppercase;
          top: 10px;
          width: 30px;
          font-weight: bold; }
        .cookie-container .wrapper-content .margin-lat-0 .cookies .nuova-finestra {
          font-weight: bold;
          color: #fff; }

.cookie-policy-title {
  text-transform: uppercase;
  color: #c12017;
  font-weight: 700; }

.cookie-policy-text {
  font-size: 18px !important;
  color: #212529 !important; }
  .cookie-policy-text ul {
    padding-left: 15px; }
    .cookie-policy-text ul li a {
      color: #c12017;
      font-weight: 700;
      word-break: break-all; }
  .cookie-policy-text p {
    margin-bottom: 2rem; }
    .cookie-policy-text p:last-child {
      margin-bottom: 0; }
    .cookie-policy-text p a {
      color: #c12017;
      font-weight: 700;
      word-break: break-all; }
  .cookie-policy-text table thead th {
    padding: 10px;
    color: #c12017; }
  .cookie-policy-text table tbody {
    font-weight: 700; }
    .cookie-policy-text table tbody tr td {
      padding: 10px; }
      .cookie-policy-text table tbody tr td p {
        margin-bottom: 0; }

.feedback-history-table-wrapper .feedback-history-table {
  margin-top: 30px !important; }
  .feedback-history-table-wrapper .feedback-history-table thead {
    background-color: #dadada !important; }
  .feedback-history-table-wrapper .feedback-history-table tbody tr td:first-child {
    padding-left: 0.5rem; }

.feedback-history-table-wrapper .dataTables_filter,
.feedback-history-table-wrapper .dataTables_info {
  display: none !important; }

@media (max-width: 767px) {
  .feedback-history-table {
    font-size: 10px; }
    .feedback-history-table td:nth-of-type(1):before {
      content: "FEEDBACK DATE"; }
    .feedback-history-table td:nth-of-type(2):before {
      content: "USER TYPE"; }
    .feedback-history-table td:nth-of-type(3):before {
      content: "PROVIDED COMMENT"; }
    .feedback-history-table td:nth-of-type(4):before {
      content: "ATTACHED DOCUMENT"; }
    .feedback-history-table td:nth-of-type(5):before {
      content: "FEEDBACK STATUS"; } }

.loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  transition: opacity 0.75s, visibility 0.75s;
  opacity: 0;
  visibility: hidden; }

.loader--hidden {
  opacity: 1;
  visibility: visible; }

.loader::after {
  content: "";
  width: 75px;
  height: 75px;
  border: 15px solid #dddddd;
  border-top-color: #892034;
  border-radius: 50%;
  animation: loading 0.75s ease infinite;
  /*opacity: 0;*/
  /*visibility: hidden;*/ }

@keyframes loading {
  from {
    transform: rotate(0turn); }
  to {
    transform: rotate(1turn); } }

.recommendationDetailScss .white-panel {
  background-color: #FFFFFF;
  margin-top: 15px;
  padding: 30px 20px; }

.recommendationDetailScss #filepicker {
  position: absolute;
  left: 0;
  opacity: 0;
  top: 0;
  bottom: 0;
  width: 100%; }

.recommendationDetailScss #dragdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f3f3f3;
  border: 3px dotted #6e6f71;
  border-radius: 10px; }

.recommendationDetailScss #filepicker-container {
  display: inline-block;
  position: relative;
  height: 100px;
  width: 100%;
  cursor: pointer; }

@media (max-width: 767px) {
  #filepicker-container {
    min-height: 12.429rem !important; }
  #send-feedback {
    margin-top: 1rem !important; } }

@media (min-width: 576px) {
  #filepicker-column {
    margin-top: 0 !important; } }

.recommendationDetailScss #dragdrop.dragover {
  background-color: #aaa; }

.recommendationDetailScss #filename {
  overflow: hidden;
  text-overflow: ellipsis; }

#clear-document, #send-feedback {
  white-space: normal;
  word-break: break-word; }

textarea {
  border-radius: 4px;
  border: solid 1px #b0b2b3; }

.recommendationDetailScss textarea::-webkit-scrollbar {
  width: 10px; }

.recommendationDetailScss textarea::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 2px #b0b2b3;
  -webkit-border-radius: 4px;
  border-radius: 4px; }

.recommendationDetailScss textarea::-webkit-scrollbar-thumb {
  -webkit-border-radius: 4px;
  border-radius: 4px;
  background: #b0b2b3;
  -webkit-box-shadow: inset 0 0 2px #b0b2b3; }

.table-align {
  padding-left: 18px !important; }

#paperclip {
  bottom: 9px;
  position: relative; }

.selection-panel {
  background-color: #f6f4f0;
  margin-top: 60px;
  border-radius: 8px; }

.docType {
  display: inline-flex;
  padding: 0;
  align-items: center;
  cursor: pointer; }

.default-grey {
  display: inline-block;
  border-radius: 50%;
  width: 15px;
  height: 15px;
  border: solid 1px #b8b8b8;
  margin-right: 10px;
  padding-right: 5px; }

.unchecked {
  display: none; }

[type=radio] {
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0; }
  [type=radio] + img {
    cursor: pointer; }

#confirmDelete {
  cursor: pointer;
  color: #fff;
  font-weight: bold;
  background: #c21b17;
  border: #c21b17;
  border-radius: 2px;
  margin-left: 36px; }

#cancel {
  cursor: pointer;
  margin-right: 14px; }

.deletePopup, .legendPopup {
  display: none;
  position: absolute;
  text-align: center;
  background: #FFFFFF;
  max-width: 500px;
  height: 250px;
  left: 50%;
  top: 30%;
  transform: translate(-50%, 5%);
  border: 1px solid #999999;
  border-radius: 3px;
  box-shadow: 0 0 3px 0;
  z-index: 1000;
  opacity: 1; }

img#paperclip {
  display: inline-block;
  float: inherit;
  margin-bottom: 5px;
  bottom: 5px; }

img#bigPaperClip {
  margin-bottom: 10px; }

img#downloadFile {
  margin-left: 15px;
  margin-right: 15px;
  text-align: center; }

img#deleteFile {
  text-align: center; }
  img#deleteFile:hover {
    cursor: pointer;
    text-align: center;
    opacity: 0.5; }

img#disabletForDelete {
  opacity: 0.5; }

.paper-clip {
  display: inline;
  float: inherit;
  top: 70px;
  size: auto; }

#filepicker {
  background-color: #ebeff4;
  position: absolute;
  left: 0;
  opacity: 0;
  top: 0;
  bottom: 0;
  width: 100%; }

#accounts-container_doc ul {
  text-overflow: ellipsis !important;
  overflow: hidden !important;
  white-space: nowrap !important;
  width: 198px; }

.docName {
  text-overflow: ellipsis !important;
  overflow: hidden !important;
  white-space: nowrap !important;
  width: 198px;
  max-width: 150px; }

#thumbNail {
  text-overflow: ellipsis !important;
  overflow: hidden !important;
  white-space: nowrap !important; }

.drop-zone__input {
  display: none; }

input#close_button {
  margin: 30px 30px 0 0; }

input#closeDeletion {
  margin: 30px 30px 0 0; }

.input#close_button:hover {
  opacity: 0.5; }

.input#closeDeletion:hover {
  opacity: 0.5; }

.absoluteShadow {
  position: fixed;
  left: 0;
  display: none;
  height: 100vh;
  width: 100%;
  z-index: 999;
  top: 0;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.4); }

.formPopup {
  display: none;
  position: absolute;
  text-align: center;
  background: #FFFFFF;
  left: 50%;
  top: 15%;
  transform: translate(-50%, 5%);
  border: 1px solid #999999;
  border-radius: 3px;
  box-shadow: 0 0 3px 0;
  z-index: 1000;
  opacity: 1; }

.selectFile {
  position: fixed;
  text-align: center;
  left: 27%;
  top: 23%; }

#buttonUpload {
  color: #fff;
  font-weight: bold;
  background: #c21b17;
  border: #c21b17;
  border-radius: 2px; }

.formContainer {
  display: none;
  width: 1100px;
  padding: 20px; }
  .formContainer .btn:hover {
    opacity: 1; }

.deleteContainer, .legendContainer {
  font-size: medium;
  display: none;
  width: 100%;
  height: 250px;
  padding: 20px;
  position: absolute;
  top: 25%;
  text-align: left; }

.actionBlock {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-bottom: 15px;
  margin-left: 50px; }
  .actionBlock input {
    text-align: center;
    margin-right: 20px;
    margin-bottom: 30px;
    padding: 10px 0 10px 0;
    width: 220px; }

.deleteBlock {
  display: inline;
  align-items: flex-end;
  top: 30%;
  margin-bottom: 15px;
  margin-left: 0;
  position: relative; }
  .deleteBlock input {
    display: inline;
    text-align: center;
    margin-right: 0;
    margin-bottom: 20px;
    padding: 10px 0 10px 0;
    width: 200px; }

hover {
  opacity: 1; }

.formHeader {
  margin: 30px 0 30px 20px;
  text-align: left;
  float: left; }

.documentTypeBlock {
  margin-left: 20px;
  text-align: left;
  float: left; }
  .documentTypeBlock label {
    margin: 0;
    padding: 0; }

#required_field {
  margin-left: 20px;
  font-size: small;
  float: left; }

#required_out {
  font-size: small;
  float: left; }

#close_button {
  font-size: larger;
  border: none;
  text-align: right;
  float: right;
  margin: 15px 15px 0 0; }

#closeDeletion {
  font-size: larger;
  border: none;
  text-align: right;
  float: right;
  margin: 15px 15px 0 0; }

.drop-zone {
  border: 1px dashed #999999;
  background-color: #ebeff4 !important;
  border-radius: 5px;
  max-width: 500px;
  height: 255px;
  left: 27%;
  top: 28%;
  position: fixed;
  text-align: center;
  padding: 20px;
  z-index: 1;
  width: 500px; }
  .drop-zone #filepicker {
    cursor: pointer; }

p {
  margin-top: 0; }

.buttonLable {
  display: inline-block;
  text-align: center;
  font-weight: bolder;
  font-size: large;
  padding-top: 95px;
  background: #ebeff4;
  cursor: pointer;
  border: none;
  max-width: 430px;
  height: available; }
  .buttonLable::after {
    content: attr(data-lable); }

.imagelable {
  display: inline;
  text-align: left;
  font-weight: bolder;
  padding-top: 70px;
  background: #FFFFFF;
  cursor: pointer;
  border: none;
  width: 20px;
  height: available; }

div#uploadedDocumentsTable_processing {
  margin-top: 50px; }

#fileElement {
  display: none;
  width: 500px;
  height: 200px; }
